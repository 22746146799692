import React, {Component} from 'react';

export class SampleDemo extends Component {

    constructor() {
        super();
        this.state = {
         
        };
    }

   
    render() {
        return (
            <div style={{ minHeight: 600 }} >
                
            </div>
        );

    }
}