import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class Form_site extends Component {
    constructor() {
        super();
        this.state = {
            idSite: '',
            nomSite: '',
            codeSite: '',
            crdSite: '',
            prefectureSite: '',
            regionSite: '',
            nomStructSite: '',
            adrSite: '',
            codeStructSite: '',
            longitudeSite: '',
            latitudeSite: '',
            liste_site: [],
            liste_ou_vih: [], 
            liste_ou_global: [],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            liste_level_2_region: [], //Région
            liste_level_3_pref: [], //Préfecture
            liste_level_4_crd: [], //Sous prefecture
            visible_dlg_site: false,
            selectedSite: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            liste_all_pref: [],
            liste_all_crd: [],
            liste_crd: [],
            liste_prefecture: [],
            liste_region: [
                { label: 'Boke', value: 'Boké' },
                { label: 'Conakry', value: 'Conakry' },
                { label: 'Faranah', value: 'Faranah' },
                { label: 'Kankan', value: 'Kankan' },
                { label: 'Kindia', value: 'Kindia' },
                { label: 'Labe', value: 'Labé' },
                { label: 'Mamou', value: 'Mamou' },
                { label: 'Nzérkoré', value: 'Nzérkoré' }
            ],
        };
        this.myservice = new Dhosservice();
        this.edit_site = this.edit_site.bind(this);
        this.conf_delete_site = this.conf_delete_site.bind(this);
        this.refresh_site = this.refresh_site.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
    }

    componentDidMount() {
        this.get_liste_site();
        this.myservice.get_liste_prefecture().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_pref: data });
            }
        });
        this.myservice.get_liste_crd().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
            }
        });
    }

    
    get_liste_site() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_sites().then(data => this.setState({ liste_site: data,showindicator: false }));
    }

    get_VIH_organisationUnit() {
        this.setState({ showindicator: true });
        this.myservice.get_VIH_organisationUnit().then(data => {
            this.setState({ liste_ou_vih: data.organisationUnits }, () => {
                this.myservice.get_Global_organisationUnit().then(data2 => {
                    this.setState({ liste_ou_global: data2.organisationUnits }, () => {
                        let lregion = this.state.liste_ou_global.filter(function (el2) {
                            return el2.level == 2;
                        })
                        this.setState({ liste_level_2_region: lregion }, () => {
                            let lpref = this.state.liste_ou_global.filter(function (el) {
                                return el.level == 3;
                            })
                            this.setState({ liste_level_3_pref: lpref }, () => {
                                let lcrd = this.state.liste_ou_global.filter(function (el) {
                                    return el.level == 4;
                                })
                                this.setState({ liste_level_4_crd: lcrd }, () => {
                                    for (var i = this.state.liste_ou_vih.length; i--;) {
                                        this.state.liste_ou_vih[i].idSite = this.state.liste_ou_vih[i].id;
                                        let id = this.state.liste_ou_vih[i].idSite + '';
                                        let ou = this.state.liste_ou_global.filter(function (el3) {
                                            return el3.id + '' === id;
                                        })[0];
                                        if (ou != undefined && ou != null) {
                                            if (ou.level == 5) {
                                                let region = this.state.liste_level_2_region.filter(function (el) {
                                                    return el.id == ou.ancestors[1].id;
                                                })[0];
                                                this.state.liste_ou_vih[i].regionSite = region.name;

                                                let pref = this.state.liste_level_3_pref.filter(function (el) {
                                                    return el.id == ou.ancestors[2].id;
                                                })[0];
                                                this.state.liste_ou_vih[i].prefectureSite = pref.name;

                                                let crd = this.state.liste_level_4_crd.filter(function (el) {
                                                    return el.id == ou.ancestors[3].id;
                                                })[0];

                                                if (ou.coordinates != null && ou.coordinates != undefined) {
                                                    let coordinates = ou.coordinates.replace("[", "");
                                                    coordinates = coordinates.replace("]", "");
                                                    let lcord = coordinates.split(',');
                                                    if (lcord != null && lcord != undefined) {
                                                        if (lcord.length > 0) {
                                                            this.state.liste_ou_vih[i].longitudeSite = lcord[0];
                                                            this.state.liste_ou_vih[i].latitudeSite = lcord[1];
                                                        }
                                                    }
                                                }
                                                this.state.liste_ou_vih[i].crdSite = crd.name;
                                                this.state.liste_ou_vih[i].nomSite = ou.name;
                                                this.state.liste_ou_vih[i].codeSite = ou.code;
                                                this.state.liste_ou_vih[i].iduser = this.state.userconnected.idUser;
                                            } else {
                                                this.state.liste_ou_vih.splice(i, 1);
                                            }
                                        }
                                    }
                                    if (this.state.liste_ou_vih != undefined && this.state.liste_ou_vih != null) {
                                        if (this.state.liste_ou_vih.length > 0) {
                                            this.myservice.enreg_site_batch(this.state.liste_ou_vih).then(data => {
                                                if (data != null && data != undefined) {
                                                    this.resultat(data.code, data.contenu);
                                                }
                                            });
                                        }
                                    }
                                });
                            });
                        });

                        this.setState({ showindicator: false });
                    });

                });
            })
        });
    }
 
    get_Global_organisationUnit() {
        this.myservice.get_Global_organisationUnit().then(data => this.setState({ liste_ou_global: data }));
    }

    select_pref(region, valPref) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        if (valPref !== undefined) {
            this.setState({ liste_prefecture: liste }, () => {
                this.setState({ prefectureSite: valPref });
            });
        } else {
            this.setState({ liste_prefecture: liste });
        }
    }

    select_crd(pref, valCrd) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        if (valCrd !== undefined) {
            this.setState({ liste_crd: liste }, () => {
                this.setState({ crdSite: valCrd });
            });
        } else {
            this.setState({ liste_crd: liste });
        }
    }

    edit_site(data) {
        if (data != null && data != undefined) {
            this.setState({ idSite: data.idSite });
            this.setState({ nomSite: data.nomSite });
            this.setState({ codeSite: data.codeSite });
            this.setState({ crdSite: data.crdSite });
            this.setState({ regionSite: data.regionSite });
            this.select_pref(data.regionSite, data.prefectureSite);
            this.select_crd(data.prefectureSite, data.crdSite);
            this.setState({ longitudeSite: data.longitudeSite });
            this.setState({ latitudeSite: data.latitudeSite });
            this.setState({ modif: true });
            this.setState({ selectedSite: data });
            this.setState({ visible_dlg_site: true });
        }
    }

    annule_site() {
        this.setState({ nomSite: '' });
        this.setState({ codeSite: '' });
        this.setState({ crdSite: '' });
        this.setState({ prefectureSite: '' });
        this.setState({ regionSite: '' });
        this.setState({ nomStructSite: '' });
        this.setState({ codeStructSite: '' });
        this.setState({ longitudeSite: '' });
        this.setState({ latitudeSite: '' });
        this.setState({ idSite: 0 });
        this.setState({ modif: false });
        this.setState({ visible_dlg_site: false });
        this.forceUpdate();
    }

    conf_delete_site(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSite: data });
            this.setState({ msg: data.nomSite });
            this.setState({ visible: true });
        }
    }

    enreg_site() {
        if (this.state.nomSite == "" || this.state.crdSite == "" || this.state.regionSite == "" || this.state.prefectureSite == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let site = {
                    idSite: 0,
                    nomSite: this.state.nomSite,
                    codeSite: this.state.codeSite,
                    crdSite: this.state.crdSite,
                    prefectureSite: this.state.prefectureSite,
                    regionSite: this.state.regionSite,
                    nomStructSite: this.state.nomStructSite,
                    codeStructSite: this.state.codeStructSite,
                    longitudeSite: this.state.longitudeSite,
                    latitudeSite: this.state.latitudeSite,
                }
                this.myservice.enreg_site(site)
                    .then(data => {
                        if (data != undefined && data != null) {
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {
                let site=this.state.selectedSite;
                site.nomSite= this.state.nomSite;
                site.codeSite= this.state.codeSite;
                site.crdSite= this.state.crdSite;
                site.codeprefectureSite= this.state.prefectureSite;
                site.regionSite= this.state.regionSite;
                site.longitudeSite= this.state.longitudeSite;
                site.latitudeSite= this.state.latitudeSite;
                this.myservice.enreg_site(site)
                    .then(data => {
                        if (data != undefined && data != null) {
                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }


    annule_site() {
        this.setState({ nomSite: '' });
        this.setState({ codeSite: '' });
        this.setState({ crdSite: '' });
        this.setState({ prefectureSite: '' });
        this.setState({ regionSite: '' });
        this.setState({ latitudeSite: '' });
        this.setState({ longitudeSite: '' });
        this.setState({ adrSite: '' });
        if(this.state.visible_dlg_site==true && this.state.modif==true){
            this.setState({ visible_dlg_site: false });
        }
        this.setState({ modif: false });
        this.forceUpdate();
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_site();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    refresh_site() {
        this.setState({ modif: false });
        this.get_VIH_organisationUnit();
    }


    toogleDlg() {
        this.setState({ visible_dlg_site: !this.state.visible_dlg_site });
    }

    actionTemplate(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button title='Modifier' onClick={() => this.edit_site(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button title='Supprimer' onClick={() => this.conf_delete_site(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -5 }} icon="pi pi-times" /></div>
        </div>
    }

    render() {
        var header = <div className="p-grid" style={{ width: 450 }}>
            <div className="p-col"><Button type="button" icon="pi pi-refresh" iconPos="left" label="Refresh" style={{ width: 100 }} onClick={this.refresh_site} /></div>
            <div className="p-col"><Button label="Nouveau" icon="pi pi-plus" className="p-button-warning" style={{ width: 100 }} onClick={this.toogleDlg} /></div>
            <div className="p-col"><Button type="button" icon="pi pi-print" iconPos="left" label="Exporter" className="p-button-success" style={{ width: 100, marginLeft: -10 }} onClick={(e) => this.dt.exportCSV()} /></div>
            <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="6" fill="#EEEEEE" animationDuration=".5s" /> : null}
            </div>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog visible={this.state.visible_dlg_site} style={{ width: '60%' }} header={this.state.modif == true ? "Modification" : "Nouveau site"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_site: false })}>
                    <div className="card">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">Nom site *</label>
                                <InputText id="nomSite" value={this.state.nomSite} onChange={(e) => this.setState({ nomSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">Code site *</label>
                                <InputText id="codeSite" value={this.state.codeSite} onChange={(e) => this.setState({ codeSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">Longitude</label>
                                <InputText id="longitudeSite" value={this.state.longitudeSite} onChange={(e) => this.setState({ longitudeSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">latitude </label>
                                <InputText id="codeSite" value={this.state.latitudeSite} onChange={(e) => this.setState({ latitudeSite: e.target.value })} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">Region *</label>
                                <Dropdown placeholder="Region" autoWidth={false} value={this.state.regionSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_region} onChange={(e) => {
                                    this.setState({ regionSite: e.value }, () => {
                                        this.select_pref(e.value);
                                        this.setState({ liste_crd: [] });
                                    });
                                }} appendTo={document.body} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">Préfecture *</label>
                                <Dropdown placeholder="Préfecture" editable={true} autoWidth={false} value={this.state.prefectureSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_prefecture} onChange={(e) => {
                                    this.setState({ prefectureSite: e.value }, () => {
                                        this.select_crd(e.value);
                                    });
                                }} appendTo={document.body} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firstname2">CR *</label>
                                <Dropdown placeholder="CR" editable={true} autoWidth={false} value={this.state.crdSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_crd} onChange={(e) => {
                                    this.setState({ crdSite: e.value });
                                }} appendTo={document.body} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastname2">Adresse </label>
                                <InputText value={this.state.adrSite} onChange={(e) => this.setState({ adrSite: e.target.value })} />
                            </div>
                        </div>
                    </div>
                    <center>
                        <>
                            <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_site: false })} />
                            <Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_site()} style={{ width: 120 }} />
                        </>
                        <br />
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>
                            <DataTable value={this.state.liste_site} selectionMode="single" header={header} selection={this.state.selectedSite} 
                                onRowClick={e => this.setState({selectedSite: e.data })} onRowSelect={e => this.setState({selectedSite: e.data })}
                                responsive={true} scrollable={true} scrollHeight="650px" csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="codeSite" header="Code" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="nomSite" header="Nom site" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '24%' }} />
                                <Column field="crdSite" header="District" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '18%' }} />
                                <Column field="prefectureSite" header="Préfecture" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '14%' }} />
                                <Column field="regionSite" header="Région" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="idSite" header="ID" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="longitudeSite" header="Longitude" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="latitudeSite" header="Latitude" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}