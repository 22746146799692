import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import { Menu } from 'primereact/menu';

export class Form_edition_rapproch_inventaire extends Component {
    constructor() {
        super();
        this.state = {
            liste_inv: [],
            liste_depot: [],
            ridDepot: null,
            showindicator: false,
            items: [
                { label: 'Format PDF', icon: 'pi pi-fw pi-print', command: () => { this.print_data('PDF') } },
                { label: 'Format XLS', icon: 'pi pi-fw pi-print', command: () => { this.print_data('XLS') } },
                { label: 'Format CSV', icon: 'pi pi-fw pi-print', command: () => { this.dt.exportCSV() } }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Dhosservice();
    }


    get_liste_depot() {
        this.myservice.get_liste_depot().then(data => this.setState({ liste_depot: data }));
    }

    get_liste_inv() {
        let rid_depot = 'Tous';
        if (this.state.ridDepot != undefined || this.state.ridDepot != null) {
            rid_depot = this.state.ridDepot.value;
        }
        this.setState({showindicator: true});
        this.myservice.get_edition_rapprochement_inventaire(rid_depot).then(data => this.setState({ liste_inv: data, showindicator: false }));
    }

    componentDidMount() {
        this.get_liste_depot();
    }


    print_data(format) {
        let rid_depot = 'Tous';
        if (this.state.ridDepot != undefined && this.state.ridDepot != null) {
            rid_depot = this.state.ridDepot.value;
        }
        this.setState({showindicator: true});
        this.myservice.generer_edition_rapprochement_inventaire(rid_depot,format);
        this.setState({showindicator: false});
    }

    render() {
        let ldepot = null;
        let ldepot_rech = [{ value: 'Tous', label: 'Dépot' }];
        if (this.state.liste_depot != null && this.state.liste_depot != undefined) {
            ldepot = this.state.liste_depot.map(x => {
                return { value: x.idDepot, label: x.nomDepot }
            });
        }
        ldepot_rech = ldepot_rech.concat(ldepot);

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

 
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={ldepot_rech} placeholder="Dépot" value={this.state.ridDepot} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridDepot: e.value },()=>{
                                                 this.get_liste_inv();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_inv()} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Menu model={this.state.items} popup={true} ref={el => this.menu = el} />
                                            <Button label="Edition.." icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            {this.state.showindicator ? <ProgressSpinner  style={{ width: '30px', height: '30px' }} strokeWidth="6" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_inv} scrollable={true} scrollHeight="650px" csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                <Column field="ordre" header="#" style={{ width: '3%' }} />
                                <Column field="lib_article" header="Désignation" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '35%' }} />
                                <Column field="famille_article" header="Groupe" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="unite_article" header="Unite" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="qte_inv" header="Q inv." filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="qte_entree" header="Q entrée" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="qte_approuve_lcmde" header="Q cmdée" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="qte_stock" header="Stock(SDU)" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />                                
                                {/* <Column field="qte_ecart" header="Ecart" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} /> */}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}