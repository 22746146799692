import React, { Component } from 'react';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppBreadcrumb } from './AppBreadcrumb';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import { Form_inventaire } from './components/Form_inventaire';
import { Form_users } from './components/Form_users';
import { SampleDemo } from './components/SampleDemo';
import { Form_article } from './components/Form_article';
import { Form_regime } from './components/Form_regime';
import { Form_site } from './components/Form_site';
import { Form_regime_patient } from './components/Form_regime_patient';
import { Form_depot } from './components/Form_depot';
import { Form_edition_cmde_prod } from './components/Form_edition_cmde_prod';
import { Form_commande } from './components/Form_commande';
import { Form_edition_situation_cmde } from './components/Form_edition_situation_cmde';
import { Form_edition_cmde } from './components/Form_edition_cmde';
import { Form_edition_st_cmde } from './components/Form_edition_st_cmde';
import { Form_edition_inventaire } from './components/Form_edition_inventaire';
import { Form_arrivage } from './components/Form_arrivage';
import { Form_transfert } from './components/Form_transfert';
import { Form_sortie } from './components/Form_sortie';
import { Form_edition_rapproch_inventaire } from './components/Form_edition_rapproch_inventaire';
import { Form_chpwd } from './components/Form_chpwd';

/*
import { Form_chpwd } from './components/Form_chpwd';
*/
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { ProgressBar } from 'primereact/components/progressbar/ProgressBar';
import 'primereact/resources/primereact.min.css';
import 'fullcalendar/dist/fullcalendar.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'horizontal',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkTheme: false,
            menuActive: false,
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onThemeChange = this.onThemeChange.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.createMenu();
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay' && !this.isMobile()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        this.topbarItemClick = false;
        this.menuClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeTheme(theme) {
        this.changeStyleSheetUrl('layout-css', theme, 'layout');
        this.changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);

        if (value.indexOf('dark') !== -1) {
            this.setState({ darkTheme: true });
        } else {
            this.setState({ darkTheme: false });
        }
    }

    onThemeChange() {
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        const themeMode = themeTokens[2];
        const newThemeMode = (themeMode === 'dark') ? 'light' : 'dark';

        this.changeTheme(themeName + '-' + newThemeMode);

    }

    createMenu() {
        var user = JSON.parse(localStorage.getItem('userconnected'));

        if (user != null && user != undefined) {
            if (user.profilUser == 'Administrateur') {
                this.menu = [
                    { label: 'Tableau de bord', icon: 'fa fa-fw fa-dashboard', to: '/dashboard' },
                    { label: 'Gestion commandes', icon: 'fa fa-fw fa-shopping-cart', to: '/commandes/liste' },
                    { label: 'Gestion arrivages', icon: 'fa fa-fw fa-arrow-down', to: '/arrivage' },
                    { label: 'Inventaire', icon: 'fa fa-fw fa-check-square-o', to: '/Form_inventaire' },
                    {
                        label: 'Parametrage', icon: 'fa fa-fw fa-cog',
                        items: [
                            { label: 'Utilisateurs', icon: 'fa fa-fw fa-users', to: '/users' },
                            { label: 'Régimes', icon: 'fa fa-fw fa-list', to: '/regimes' },
                            { label: 'Produits', icon: 'fa fa-fw fa-cube', to: '/produits' },
                            { label: 'Site', icon: 'fa fa-fw fa-sitemap', to: '/sites' },
                            { label: 'File active', icon: 'fa fa-fw fa-users', to: '/file_active' },
                            { label: 'Dépots', icon: 'fa fa-fw fa-home', to: '/depots' }
                        ]
                    },
                    {
                        label: 'Reporting', icon: 'fa fa-fw fa-area-chart',
                        items: [
                            { label: 'Situation commandes', icon: 'fa fa-fw fa-table', to: '/situation_commandes' },
                            { label: 'Journal des commandes', icon: 'fa fa-fw fa-table', to: '/edition_commandes' },
                            { label: 'Commandes par produit', icon: 'fa fa-fw fa-area-chart', to: '/commandes_produits' },
                            { label: 'Inventaire', icon: 'fa fa-fw fa-table', to: '/inventaire' },
                            { label: 'Commandes par mois', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/mois' },
                            { label: 'Commandes par trim.', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/trimestre' },
                            { label: 'Rapprochement stock', icon: 'fa fa-fw fa-table', to: '/rapprochement_stock' }
                        ]
                    }
                ];
            } else if (user.profilUser == 'Approbateur') {
                this.menu = [
                    { label: 'Tableau de bord', icon: 'fa fa-fw fa-dashboard', to: '/dashboard' },
                    { label: 'Gestion commandes', icon: 'fa fa-fw fa-shopping-cart', to: '/commandes/liste' },
                    { label: 'Gestion arrivages', icon: 'fa fa-fw fa-arrow-down', to: '/arrivage' },
                    { label: 'Inventaire', icon: 'fa fa-fw fa-check-square-o', to: '/Form_inventaire' },

                    {
                        label: 'Reporting', icon: 'fa fa-fw fa-area-chart',
                        items: [
                            { label: 'Situation commandes', icon: 'fa fa-fw fa-table', to: '/situation_commandes' },
                            { label: 'Journal des commandes', icon: 'fa fa-fw fa-table', to: '/edition_commandes' },
                            { label: 'Commandes par produit', icon: 'fa fa-fw fa-area-chart', to: '/commandes_produits' },
                            { label: 'Inventaire', icon: 'fa fa-fw fa-table', to: '/inventaire' },
                            { label: 'Commandes par mois', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/mois' },
                            { label: 'Commandes par trim.', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/trimestre' },
                            { label: 'Rapprochement stock', icon: 'fa fa-fw fa-table', to: '/rapprochement_stock' }
                        ]
                    }
                ];
            } else if (user.profilUser == 'Consultation') {
                this.menu = [
                    { label: 'Tableau de bord', icon: 'fa fa-fw fa-dashboard', to: '/dashboard' },
                    {
                        label: 'Reporting', icon: 'fa fa-fw fa-area-chart',
                        items: [
                            { label: 'Situation commandes', icon: 'fa fa-fw fa-table', to: '/situation_commandes' },
                            { label: 'Journal des commandes', icon: 'fa fa-fw fa-table', to: '/edition_commandes' },
                            { label: 'Commandes par produit', icon: 'fa fa-fw fa-area-chart', to: '/commandes_produits' },
                            { label: 'Inventaire', icon: 'fa fa-fw fa-table', to: '/inventaire' },
                            { label: 'Commandes par mois', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/mois' },
                            { label: 'Commandes par trim.', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/trimestre' },
                            { label: 'Rapprochement stock', icon: 'fa fa-fw fa-table', to: '/rapprochement_stock' }
                        ]
                    }
                ];
            } else if (user.profilUser == 'Responsabe site/Agent de saisie') {
                this.menu = [
                    { label: 'Tableau de bord', icon: 'fa fa-fw fa-dashboard', to: '/dashboard' },
                    { label: 'Gestion commandes', icon: 'fa fa-fw fa-shopping-cart', to: '/commandes/liste' },
                    { label: 'Gestion arrivages', icon: 'fa fa-fw fa-arrow-down', to: '/arrivage' },
                    { label: 'Inventaire', icon: 'fa fa-fw fa-check-square-o', to: '/Form_inventaire' },
                    {
                        label: 'Reporting', icon: 'fa fa-fw fa-area-chart',
                        items: [
                            { label: 'Situation commandes', icon: 'fa fa-fw fa-table', to: '/situation_commandes' },
                            { label: 'Journal des commandes', icon: 'fa fa-fw fa-table', to: '/edition_commandes' },
                            { label: 'Commandes par produit', icon: 'fa fa-fw fa-area-chart', to: '/commandes_produits' },
                            { label: 'Inventaire', icon: 'fa fa-fw fa-table', to: '/inventaire' },
                            { label: 'Commandes par mois', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/mois' },
                            { label: 'Commandes par trim.', icon: 'fa fa-fw fa-area-chart', to: '/stat_commandes/trimestre' },
                        ]
                    }
                ];
            }
        }
    }

    render() {
        const layoutClassName = classNames('layout-wrapper', {
            'layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-slim': this.state.layoutMode === 'slim',
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-overlay-active': this.state.overlayMenuActive
        });
        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar darkTheme={this.state.darkTheme} onThemeChange={this.onThemeChange}
                        topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                        onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                        onTopbarItemClick={this.onTopbarItemClick} />

                    <div className='layout-menu-container' onClick={this.onMenuClick}>
                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                            <div className="layout-menu-content">
                                <div className="layout-menu-title">MENU</div>
                                <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                    layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                                <div className="layout-menu-footer">
                                    <div className="layout-menu-footer-title">TASKS</div>

                                    <div className="layout-menu-footer-content">
                                        <ProgressBar value={50} showValue={false}></ProgressBar>
                                        Today
                                        <ProgressBar value={80} showValue={false}></ProgressBar>
                                        Overall
                                    </div>
                                </div>
                            </div>
                        </ScrollPanel>
                    </div>

                    <div className="layout-content">
                        <AppBreadCrumbWithRouter />

                        <div className="layout-content-container">
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/commandes/:typeOp" component={Form_commande} />
                            <Route path="/Form_inventaire" component={Form_inventaire} />
                            <Route path="/sample" component={SampleDemo} />
                            <Route path="/produits" component={Form_article} />
                            <Route path="/users" component={Form_users} />
                            <Route path="/regimes" component={Form_regime} />
                            <Route path="/sites" component={Form_site} />
                            <Route path="/file_active" component={Form_regime_patient} />
                            <Route path="/depots" component={Form_depot} />
                            <Route path="/edition_commandes" component={Form_edition_cmde} />
                            <Route path="/situation_commandes" component={Form_edition_situation_cmde} />
                            <Route path="/commandes_produits" component={Form_edition_cmde_prod} />
                            <Route path="/stat_commandes/:typeOp" component={Form_edition_st_cmde} />
                            <Route path="/inventaire" component={Form_edition_inventaire} />
                            <Route path="/arrivage" component={Form_arrivage} />
                            <Route path="/transfert" component={Form_transfert} />
                            <Route path="/sortie" component={Form_sortie} />
                            <Route path="/rapprochement_stock" component={Form_edition_rapproch_inventaire} />
                            <Route path="/changer_mot_de_passe" component={Form_chpwd} />


                        </div>

                        <AppFooter />

                        {this.state.staticMenuMobileActive && <div className="layout-mask"></div>}
                    </div>
                </div>
            </div>
        );
    }
}
export default App;