import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class Form_article extends Component {
    constructor() {
        super();
        this.state = {
            idArticle: '',
            libArticle: '',
            puRefAchatArticle: 0,
            statutArticle: { label: 'Utilisé', value: 'Utilisé' },
            cbArticle: '',
            codeArticle: '',
            refArticle: '',

            file: null,
            urlfile: null,
            photoArticle: 'nophoto.png',


            cibleArticle: { label: 'Adulte', value: 'Adulte' },
            familleArticle: { label: 'Antirétroviraux', value: 'Antirétroviraux' },
            uniteArticle: '',
            liste_article: [],
            liste_cible: [
                { label: 'Adulte', value: 'Adulte' },
                { label: 'Enfant', value: 'Enfant' },
                { label: 'Tous', value: 'Tous' }
            ],
            liste_famille: [
                { label: 'Antirétroviraux', value: 'Antirétroviraux' },
                { label: 'Parapharmaceutique', value: 'Parapharmaceutique' },
                { label: 'Antibiotique', value: 'Antibiotique' },
                { label: 'Généric', value: 'Généric' },
                { label: 'Outil', value: 'Outil' },
                { label: 'Autre', value: 'Autre' }
            ],
            liste_statut: [
                { label: 'Utilisé', value: 'Utilisé' },
                { label: 'Non utilisé', value: 'Non utilisé' }
            ],
            selectedArticle: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_article = this.edit_article.bind(this);
        this.conf_delete_article = this.conf_delete_article.bind(this);
        this.myservice = new Dhosservice();
    }

    componentDidMount() {
        this.get_liste_article();
    }

    get_liste_article() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_article().then(data => this.setState({ liste_article: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_article();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_article(data) {
        if (data != null && data != undefined) {
            this.setState({ idArticle: data.idArticle });
            this.setState({ puRefAchatArticle: data.puRefAchatArticle });
            this.setState({ libArticle: data.libArticle });
            this.setState({ photoArticle: data.photoArticle });
            this.setState({ cbArticle: data.cbArticle });
            this.setState({ refArticle: data.refArticle }); 
            this.setState({ codeArticle: data.codeArticle });
            this.setState({ uniteArticle: data.uniteArticle });
            this.setState({ statutArticle: { value: data.statutArticle, label: data.statutArticle } });
            this.setState({ cibleArticle: { value: data.cibleArticle, label: data.cibleArticle } });
            this.setState({ familleArticle: { value: data.familleArticle, label: data.familleArticle } });
            this.setState({ modif: true });
            this.setState({ selectedArticle: data });
            if (data.photoArticle !== undefined && data.photoArticle !== null && data.photoArticle !== '' && data.photoArticle !== 'nophoto.png') {
                this.setState({ urlfile: this.myservice.download_fichier(data.photoArticle) })
            } else {
                this.setState({ urlfile: null })
            }

            window.scrollTo(0, 0);
        }
    }

    annule_article() {
        this.setState({ idArticle: '' });
        this.setState({ photoArticle: '' });
        this.setState({ puRefAchatArticle: 0 });
        this.setState({ libArticle: '' });
        this.setState({ cbArticle: '' });
        this.setState({ codeArticle: '' });
        this.setState({ uniteArticle: '' });
        this.setState({ refArticle: '' });
        this.setState({ photoArticle: '' });
        this.setState({ urlfile: null,file: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_article() {
        if (this.state.libArticle == "" || this.state.codeArticle == "" || this.state.refArticle == "") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoArticle;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PH_' + this.myservice.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }

            this.setState({ showindicator: true });
            let data = { libArticle: this.state.libArticle, photoArticle: nomfic,codeArticle: this.state.codeArticle, puRefAchatArticle: this.state.puRefAchatArticle, cbArticle: this.state.cbArticle, codeArticle: this.state.codeArticle, uniteArticle: this.state.uniteArticle, statutArticle: this.state.statutArticle.value, cibleArticle: this.state.cibleArticle.value, familleArticle: this.state.familleArticle.value, refArticle: this.state.refArticle };
            if (this.state.modif == false) {
                data.idArticle = '0';
                this.myservice.enreg_article(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            } else {
                data.idArticle = this.state.idArticle;
                this.myservice.enreg_article(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_article(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_article(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_article() {
        if (this.state.selectedArticle == null || this.state.selectedArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne ‡ supprimer' });
        } else {
            this.myservice.delete_article(this.state.selectedArticle).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_article(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedArticle: data });
            this.setState({ msg: data.libArticle });
            this.setState({ visible: true });
        }
    }


    /* Colonne d'affichage de la photo */
    photoTemplate(rowData, column) {
        let fileName = rowData.photoArticle;
        var src = 'assets/layout/images/nophoto.png'
        if (fileName !== '' && fileName !== null && fileName !== undefined) {
            src = this.myservice.download_fichier(fileName);
        }
        return <img src={src} alt={rowData.label} style={{ width: '65px', height: '60px', borderRadius: 10, backgroundColor: '#f7f7f7' }} />;
    };

    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    /* Uploader sur le serveur les fichiers */
    fileUpload(file, namefile) {
        this.myservice.upload_file(file, namefile).then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.growl.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_article();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        let header_print = <Button icon='pi pi-print' className="p-button-success" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dt.exportCSV()} />

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label >Désignation *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.libArticle} onChange={(e) => this.setState({ libArticle: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label >Référence *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.refArticle} onChange={(e) => this.setState({ refArticle: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label >Code *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.codeArticle} onChange={(e) => this.setState({ codeArticle: e.target.value })} />
                                </div>

                                {/* <div className="p-col-12 p-md-2">
                                    <label >Prix achat *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.puRefAchatArticle} onChange={(e) => this.setState({ puRefAchatArticle: e.target.value })} />
                                </div> */}

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Groupe *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_famille} value={this.state.familleArticle} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ familleArticle: e.value })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Catégorie / classe d'age *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_cible} value={this.state.cibleArticle} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ cibleArticle: e.value })} autoWidth={false} />
                                </div>

                                
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Statut *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_statut} value={this.state.statutArticle} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ statutArticle: e.value })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Unité </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.uniteArticle} onChange={(e) => this.setState({ uniteArticle: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Photo produit</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                        <div style={{ height: 100, width: 155, borderRadius: 10 }} >
                                            <center>
                                                {this.state.urlfile == null ?
                                                    <div style={{ width: '155px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PHOTO</label></div> :
                                                    <img src={this.state.urlfile} style={{ width: '155px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />}
                                            </center>
                                        </div>
                                    </a>
                                    <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            this.setState({ file: e.target.files[0] }, () => {
                                                let url = URL.createObjectURL(this.state.file);
                                                this.setState({ urlfile: url });
                                            })
                                        }
                                    }} />
                                </div>

                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_article()} /></div>
                                    <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_article()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_article} paginatorPosition="top" selectionMode="single" scrollable={true} scrollHeight="350px"
                                onRowClick={e => this.setState({ selectedArticle: e.data })} onRowSelect={e => this.setState({ selectedArticle: e.data })}
                                responsive={true} selection={this.state.selectedArticle} csvSeparator=";" ref={(el) => { this.dt = el; }} header={header_print}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '7%' }} exportable={false} />
                                <Column field="codeArticle" filter={true} filterMatchMode='contains' sortable={true} header="Code" style={{ width: '10%' }} />
                                <Column field="libArticle" filter={true} filterMatchMode='contains' header="Désignation" sortable={true} />
                                <Column field="familleArticle" header="Catégorie / classe" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                <Column field="statutArticle" header="Statut" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="uniteArticle" header="Unité" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="refArticle" filter={true} filterMatchMode='contains' header="Référence" sortable={true} style={{ width: '10%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}