import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Menu } from 'primereact/menu';


export class Form_edition_cmde_prod extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            idSite: null,
            ridDepot: null,
            liste_cmde: [],
            liste_site: [],
            liste_depot: [],
            liste_all_site: [],
            liste_prefecture: [],
            liste_crd: [],
            region: { label: 'Region', value: 'Tous' },
            pref: null,
            crd: null,
            msg: '',

            items: [
                { label: 'Format PDF', icon: 'pi pi-fw pi-print', command: () => { this.print_data('PDF') } },
                // { label: 'Format XLS', icon: 'pi pi-fw pi-print', command: () => { this.print_data('XLS') } },
                { label: 'Format CSV', icon: 'pi pi-fw pi-print', command: () => { this.dt.exportCSV() } },
                { label: 'Plan par site', icon: 'pi pi-fw pi-print', command: () => { this.print_plan_site() } },
                { label: 'Plan par dépot', icon: 'pi pi-fw pi-print', command: () => { this.print_plan_depot() } },
                { label: 'Plan global', icon: 'pi pi-fw pi-print', command: () => { this.print_plan_global() } },
            ],
            liste_region: [
                { label: 'Region', value: 'Tous' },
                { label: 'Boke', value: 'Boke' },
                { label: 'Conakry', value: 'Conakry' },
                { label: 'Faranah', value: 'Faranah' },
                { label: 'Kankan', value: 'Kankan' },
                { label: 'Kindia', value: 'Kindia' },
                { label: 'Labe', value: 'Labe' },
                { label: 'Mamou', value: 'Mamou' },
                { label: 'Nzérkoré', value: 'Nzérkoré' }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            liste_all_pref: JSON.parse(localStorage.getItem('liste_pref')),
            liste_all_crd: JSON.parse(localStorage.getItem('liste_crd'))
        };
        this.myservice = new Dhosservice();
        this.state.pd1.setDate(this.state.pd1.getDate() - 120);
    }

    componentDidMount() {
        this.get_liste_cmde();
        this.get_liste_site();
        this.get_liste_depot();
    }

     /* Récupération de la liste générale des dépots  */
     get_liste_depot() {
        this.myservice.get_liste_depot().then(data => { 
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idDepot, label: x.nomDepot }
                });
                this.setState({ liste_depot: liste });
            }
        });
    } 

    get_liste_site() {
        this.myservice.get_liste_sites().then(data => {
            this.setState({ liste_all_site: data }, () => {
                if (this.state.liste_all_site != null && this.state.liste_all_site != undefined) {
                    if (this.state.liste_all_site.length > 0) {
                        let lsite_rech = [{ value: 'Tous', label: 'Sites' }];
                        let lsite = this.state.liste_all_site.map((x,index) => {
                            return { value: x.idSite, label: index+' - '+x.nomSite + '/' + x.regionSite }
                        });
                        lsite_rech = lsite_rech.concat(lsite);
                        this.setState({ liste_site: lsite_rech });
                    }
                }
            });
        });
    }

    print_plan_depot() {
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');
        this.myservice.generer_plan_appro_depot(spd1, spd2);
    }

    print_plan_global() {
        let rid_depot = 'Tous';
        if (this.state.ridDepot != undefined && this.state.ridDepot != null) {
            rid_depot = this.state.ridDepot.value;
        }
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');
        this.myservice.generer_plan_appro_global(spd1, spd2,rid_depot);
    }

    print_plan_site() {
        let rsite = 'Tous';
        let rregion = 'Tous';
        let rpref = 'Tous';
        let rcrd = 'Tous';
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');

        if (this.state.idSite !== undefined && this.state.idSite !== null) {
            rsite = this.state.idSite.value;
        }
        if (this.state.region != undefined && this.state.region != null) {
            rregion = this.state.region.value;
            if (rregion == undefined) {
                rregion = this.state.region;
            }
        }
        if (this.state.pref !== undefined && this.state.pref !== null) {
            rpref = this.state.pref;
        }
        if (this.state.crd !== undefined && this.state.crd !== null) {
            rcrd = this.state.crd;
        }
        this.myservice.generer_plan_appro_site(spd1, spd2, rregion, rpref, rcrd, rsite);
    }

    
    print_data(format) {
        let rsite = 'Tous';
        let rregion = 'Tous';
        let rpref = 'Tous';
        let rcrd = 'Tous';
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');

        if (this.state.idSite !== undefined && this.state.idSite !== null) {
            rsite = this.state.idSite.value;
        }
        if (this.state.region != undefined && this.state.region != null) {
            rregion = this.state.region.value;
            if (rregion == undefined) {
                rregion = this.state.region;
            }
        }
        if (this.state.pref !== undefined && this.state.pref !== null) {
            rpref = this.state.pref;
        }
        if (this.state.crd !== undefined && this.state.crd !== null) {
            rcrd = this.state.crd;
        }

        let rid_depot = 'Tous';
        if (this.state.ridDepot != undefined && this.state.ridDepot != null) {
            rid_depot = this.state.ridDepot.value;
        }
        this.myservice.generer_edition_liste_cmde_prod(spd1, spd2, rregion, rpref, rcrd, rsite,rid_depot, format);
    }

    get_liste_cmde() {
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');
        let rsite = 'Tous';
        let rregion = 'Tous';
        let rpref = 'Tous';
        let rcrd = 'Tous';

        let rid_depot = 'Tous';
        if (this.state.ridDepot != undefined && this.state.ridDepot != null) {
            rid_depot = this.state.ridDepot;
        }

        if (this.state.region != undefined && this.state.region != null) {
            rregion = this.state.region.value;
            if (rregion == undefined) {
                rregion = this.state.region;
            } 
        }
        if (this.state.pref !== undefined && this.state.pref !== null) {
            rpref = this.state.pref;
        }
        if (this.state.crd !== undefined && this.state.crd !== null) {
            rcrd = this.state.crd;
        }

        if (this.state.idSite !== undefined && this.state.idSite !== null) {
            rsite = this.state.idSite.value;
        }

        this.setState({ showindicator: true });
        this.myservice.get_edition_cmde_prod(spd1, spd2, rregion, rpref, rcrd, rsite,rid_depot).then(data => this.setState({ liste_cmde: data,showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

   /*  
     exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 
                'NO': x.ordre, 
                'REGION': x.region_site, 
                'PREFECTURE': x.prefecture_site, 
                'FOSA': x.nom_site, 
                'REFERENCE': x.ref_article,
                'PRODUIT': x.lib_article ,
                'QTE_CMDE': x.qte_dmde_lcmde, 
                'QTE_VALIDEE': x.qte_approuve_lcmde, 
                'QTE_STOCK': x.qte_stock, 
                'CMM': x.qte_cmm, 
                'QTE_LIVRE': x.qte_livr_lcmde, 
            }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'LISTE_APPRO_');
        });
    } 

   
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    } */



    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        let liste_rech = [{ value: 'Tous', label: 'Préfecture' }];
        liste_rech = liste_rech.concat(liste);
        this.setState({ liste_prefecture: liste_rech });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        let liste_rech = [{ value: 'Tous', label: 'CR' }];
        liste_rech = liste_rech.concat(liste);
        this.setState({ liste_crd: liste_rech });
    }

    select_site(pref, crd) {
        let liste = [];
        if (this.state.liste_all_site != null && this.state.liste_all_site != undefined) {
            if (this.state.liste_all_site.length > 0) {

                let liste_filtre = this.state.liste_all_site.filter(function (el) {
                    return el.prefectureSite == pref && el.crdSite == crd;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.idSite, label: x.nomSite + '/' + x.regionSite }
                        });
                    }
                }
            }
        }
        let lsite_rech = [{ value: 'Tous', label: 'Sites' }];
        lsite_rech = lsite_rech.concat(liste);
        this.setState({ liste_site: lsite_rech });
    }

    render() {



        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="card card-w-title" style={{ height: 800 }}>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown placeholder="Dépot" autoWidth={false} value={this.state.ridDepot} filter={true} filterBy='label' filterMatchMode='contains' options={[{value: 'Tous',label: 'Tous'}].concat(this.state.liste_depot)} onChange={(e) => {
                                        this.setState({ ridDepot: e.value }, () => {
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown placeholder="Region" autoWidth={false} value={this.state.region} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_region} onChange={(e) => {
                                        this.setState({ region: e.value }, () => {
                                            this.select_pref(e.value);
                                            this.setState({ liste_crd: [] });
                                            this.setState({ liste_site: [] });
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown placeholder="Préfecture" autoWidth={false} value={this.state.pref} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_prefecture} onChange={(e) => {
                                        this.setState({ pref: e.value }, () => {
                                            this.select_crd(e.value);
                                            this.setState({ liste_site: [] });
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } />
                                </div>
                                
                                <div className="p-col-12 p-md-2">
                                    <Dropdown options={this.state.liste_site} placeholder="Site" value={this.state.idSite} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idSite: e.value })} autoWidth={false} />
                                </div>

                            </div>
                        </div>

                        <center>
                            <div className="p-grid" style={{ width: 300 }}>
                                <div className="p-col">
                                    <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_cmde()} />
                                </div>
                                <div className="p-col">
                                    <Menu model={this.state.items} popup={true} ref={el => this.menu = el} />
                                    <Button label="Edition.." icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} />
                                </div>
                            </div>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>

                        <DataTable value={this.state.liste_cmde} selectionMode="single" scrollable={true} scrollHeight="600px"
                            responsive={true} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                            <Column field="ordre" header="#" style={{ width: '4%' }} />
                            <Column field="region_site" header="REGION" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="prefecture_site" header="PREFECTURE" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nom_site" header="FOSA" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="ref_article" header="REF" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="lib_article" header="DESIGNATION"  filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="qte_dmde_lcmde" header="QAC" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="qte_approuve_lcmde" header="QVAL" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="qte_stock" header="STOCK" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="qte_cmm" header="CMM" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="qte_livr_lcmde" header="LIVRE" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />

                        </DataTable>

                    </div>
                </div>
            </div>

        );
    }
}