import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {InputText} from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';

export class AppTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        topbarMenuActive: false,
        activeTopbarItem: null,
        darkTheme: null,
        onThemeChange: null
    }

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        darkTheme: PropTypes.bool,
        onThemeChange: PropTypes.func
    }

    constructor() {
        super();
        this.state = {
            nom_user: JSON.parse(localStorage.getItem('userconnected')).nomUser,
        };
    }

    onTopbarItemClick(event, item) {
        if(this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    render() {
        let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.topbarMenuActive});

        return <div className="topbar clearfix">

            <img className="logo" alt="apollo-layout" src="assets/layout/images/logo.png" />

            <button className="p-link" id="menu-button" onClick={this.props.onMenuButtonClick}>
                <i className="fa fa-align-left"></i>
            </button>

            <button className="p-link profile" onClick={this.props.onTopbarMenuButtonClick}>
                <span className="username">{this.state.nom_user}</span>
                <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" />
                <i className="fa fa-angle-down"></i>
            </button>

            
            <ul className={topbarItemsClassName}>
                <li className={classNames({'menuitem-active': this.props.activeTopbarItem === 'profile'})}
                    onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                    <button className="p-link" onClick={(e) => {
                        window.location = "#/changer_mot_de_passe";
                    }}>
                        <i className="topbar-icon fa fa-fw fa-user"></i>
                        <span className="topbar-item-name">Changer mon mot de passe</span>
                    </button>
                </li>
                
                <li className={classNames({'menuitem-active': this.props.activeTopbarItem === 'settings'})}
                    onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
                    <button className="p-link" onClick={(e) => {
                        localStorage.removeItem('userconnected');
                        window.location = "#/login";
                    }}>
                        <i className="topbar-icon fa fa-fw fa-sign-out"></i>
                        <span className="topbar-item-name">Déconnexion</span>
                    </button>
                </li>
               
            </ul>
        </div>;
    }
}