import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Menu } from 'primereact/menu';


export class Form_edition_situation_cmde extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_cmde: [],

            liste_region: [
                { label: 'Region', value: 'Tous' },
                { label: 'Boke', value: 'Boke' },
                { label: 'Conakry', value: 'Conakry' },
                { label: 'Faranah', value: 'Faranah' },
                { label: 'Kankan', value: 'Kankan' },
                { label: 'Kindia', value: 'Kindia' },
                { label: 'Labe', value: 'Labe' },
                { label: 'Mamou', value: 'Mamou' },
                { label: 'Nzérkoré', value: 'Nzérkoré' }
            ],
            liste_prefecture: [],
            liste_crd: [],
            region: { label: 'Region', value: 'Tous' },
            pref: null,
            crd: null,

            items: [
                { label: 'Format PDF', icon: 'pi pi-fw pi-print', command: () => { this.print_data('PDF') } },
                { label: 'Format XLS', icon: 'pi pi-fw pi-print', command: () => { this.print_data('XLS') } },
                { label: 'Format CSV', icon: 'pi pi-fw pi-print', command: () => { this.dt.exportCSV() } }
            ],
            liste_all_pref: JSON.parse(localStorage.getItem('liste_pref')),
            liste_all_crd: JSON.parse(localStorage.getItem('liste_crd')),
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Dhosservice();
        this.state.pd1.setDate(this.state.pd1.getDate() - 120);
    }


    componentDidMount() {
        this.get_liste_cmde();
    }



    print_data(format) {
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');

        let rregion = 'Tous';
        let rpref = 'Tous';
        let rcrd = 'Tous';

        if (this.state.region != undefined && this.state.region != null) {
            rregion = this.state.region.value;
            if (rregion == undefined) {
                rregion = this.state.region;
            }
        }
        if (this.state.pref != undefined && this.state.pref != null) {
            rpref = this.state.pref;
        }
        if (this.state.crd != undefined && this.state.crd != null) {
            rcrd = this.state.crd;
        }


        this.myservice.generer_edition_situation_cmde(spd1, spd2, rregion, rpref, rcrd,format);
    }

    get_liste_cmde() {
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');

        let rregion = 'Tous';
        let rpref = 'Tous';
        let rcrd = 'Tous';

        if (this.state.region != undefined && this.state.region != null) {
            rregion = this.state.region.value;
            if (rregion == undefined) {
                rregion = this.state.region;
            }
        }
        if (this.state.pref != undefined && this.state.pref != null) {
            rpref = this.state.pref;
        }
        if (this.state.crd != undefined && this.state.crd != null) {
            rcrd = this.state.crd;
        }

        this.myservice.get_edition_situation_cmde(spd1, spd2, rregion, rpref, rcrd).then(data => this.setState({ liste_cmde: data }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        let liste_rech = [{ value: 'Tous', label: 'Préfecture' }];
        liste_rech = liste_rech.concat(liste);
        this.setState({ liste_prefecture: liste_rech });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        let liste_rech = [{ value: 'Tous', label: 'CR' }];
        liste_rech = liste_rech.concat(liste);
        this.setState({ liste_crd: liste_rech });
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_print(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-print" /></div>
        </div>
    }


    render() {
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="card card-w-title" style={{ height: 800 }}>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown placeholder="Region" autoWidth={false} value={this.state.region} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_region} onChange={(e) => {
                                        this.setState({ region: e.value }, () => {
                                            this.select_pref(e.value);
                                            this.setState({ liste_crd: [] });
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } /> 
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown placeholder="Préfecture" autoWidth={false} value={this.state.pref} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_prefecture} onChange={(e) => {
                                        this.setState({ pref: e.value }, () => {
                                            this.select_crd(e.value);
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown placeholder="CR" autoWidth={false} value={this.state.crd} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_crd} onChange={(e) => {
                                        this.setState({ crd: e.value });
                                        this.get_liste_cmde();
                                    }
                                    } />
                                </div>
                                
                            </div>
                        </div>

                        <center>
                            <div className="p-grid" style={{ width: 300 }}>
                                <div className="p-col">
                                    <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_cmde()} />
                                </div>
                                <div className="p-col">
                                    <Menu model={this.state.items} popup={true} ref={el => this.menu = el} />
                                    <Button label="Edition.." icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} />
                                </div>
                            </div>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>


                        <DataTable value={this.state.liste_cmde} selectionMode="single" scrollable={true} scrollHeight="600px"
                            responsive={true} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                            <Column field="ordre" header="#" style={{ width: '4%' }} />
                            <Column field="nom_site" header="Site" style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="prefecture_site" header="Préfecture" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="region_site" header="Région" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nb_cmde" header="Total" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nb_valide" header="Validé" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nb_attente" header="En attente" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nb_livr" header="Livré" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nb_non_livr" header="Non livré" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nbj_livr" header="Moy. livr." style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />

                        </DataTable>

                    </div>
                </div>
            </div>

        );
    }
}