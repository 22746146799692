import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { SplitButton } from 'primereact/splitbutton';


export class Form_commande extends Component {
    constructor() {
        super();
        this.state = {
            idCmde: 0,
            libArticle: '',
            numCmde: '',
            dateCmde: new Date(),
            cmtCmde: '',
            idLcmde: 0,
            qteDmdeLcmde: 1,
            qteApprouveLcmde: 0,
            qteLivreLcmde: 0,
            liste_lcmde_livr: [],
            selectedLcmdeLivr: null,
            ficBcCmde: '',
            ficBlCmde: '',
            urlfileBL: null,
            urlfileBC: null,
            num_ligne: 0,
            cmtLcmde: '',
            pd1: new Date(),
            pd2: new Date(),
            dateDebCmde: new Date(),
            dateLivrCmde: new Date(),
            dateFinCmde: new Date(),
            rnum_cmde: '',
            statutCmde: null,
            etatCmde: null,
            idArticle: null,
            idSite: null,
            typeCmde: null,
            ridSite: null,
            fileBC: null,
            fileBL: null,
            liste_cmde: [],
            liste_lcmde: [],
            liste_site: [],
            liste_article: [],
            liste_ligne: [],
            lprod: [],
            liste_ligne_validation: [],
            liste_regime_patient: [],
            liste_trace: [],
            selectedCmde: null,
            selectedLcmde: null,
            selectedLigne: null,
            selectedLigne_validation: null,
            file: null,
            urlfile: null,
            photoArticle: 'nophoto.png',
            msg: '',
            modif: false,
            modif_lcmde: false,
            visible: false,
            visible_lcmde: false,
            visible_dlg_livraison: false,
            visible_new_cmde_dlg: false,
            visible_dlg_cmde: false,
            visible_dlg_lcmde: false,
            visible_dlg_validation: false,
            visible_dlg_trace: false,
            showindicator: false,
            disable_new_cmde: true,
            disable_add_prod: true,
            disable_val_cmde: true,
            disable_prod: true,
            liste_statut: [
                { label: 'Tout statut', value: 'Tous' },
                { label: 'Livré', value: 'Livré' },
                { label: 'Non livré', value: 'Non livré' }
            ],
            liste_etat: [
                { label: 'Tout etat', value: 'Tous' },
                { label: 'Validé', value: 'Validé' },
                { label: 'En attente', value: 'En attente' },
                { label: 'Annulé', value: 'Annulé' }
            ],
            liste_type_cmde: [
                { label: 'Régulière', value: 'Régulière' },
                { label: 'Urgence', value: 'Urgence' }
            ],
            cmmLcmde: '',
            qteStockLcmde: 0,
            qteRequiseLcmde: 0,
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };

        this.edit_cmde = this.edit_cmde.bind(this);
        this.conf_delete_cmde = this.conf_delete_cmde.bind(this);

        this.edit_lcmde = this.edit_lcmde.bind(this);
        this.conf_delete_lcmde = this.conf_delete_lcmde.bind(this);

        this.show_new_cmde_dlg = this.show_new_cmde_dlg.bind(this);
        this.show_lcmde_dlg = this.show_lcmde_dlg.bind(this);

        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);

        this.show_dlg_validation = this.show_dlg_validation.bind(this);

        this.qteDmdeLcmdeEditor = this.qteDmdeLcmdeEditor.bind(this);
        this.qteApprouveLcmdeEditor = this.qteApprouveLcmdeEditor.bind(this);
        this.cmtLcmdeEditor = this.cmtLcmdeEditor.bind(this);

        this.cmmLcmdeEditor = this.cmmLcmdeEditor.bind(this);
        this.qteStockLcmdeEditor = this.qteStockLcmdeEditor.bind(this);

        this.qteLivreLcmdeEditor = this.qteLivreLcmdeEditor.bind(this);

        this.update_etat_statut_cmde = this.update_etat_statut_cmde.bind(this);
        this.show_trace = this.show_trace.bind(this);
        this.conf_print_bc = this.conf_print_bc.bind(this);
        this.state.dateFinCmde.setDate(this.state.dateFinCmde.getDate() + 120);
        this.myservice = new Dhosservice();
    }



    articleTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-">
                            <label>{option.ref}</label>
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label style={{ fontWeight: 'bold' }}>{option.stock}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    qteStockLcmdeEditor(props) {
        return this.inputNumberEditor(props, 'qteStockLcmde');
    }
    qteLivreLcmdeEditor(props) {
        return this.inputNumberEditor(props, 'qteLivreLcmde');
    }

    cmmLcmdeEditor(props) {
        return this.inputTextEditor(props, 'cmmLcmde');
    }


    qteApprouveLcmdeEditor(props) {
        return this.inputNumberEditor(props, 'qteApprouveLcmde');
    }

    qteDmdeLcmdeEditor(props) {
        return this.inputNumberEditor(props, 'qteDmdeLcmde');
    }
    cmtLcmdeEditor(props) {
        return this.inputTextEditor(props, 'cmtLcmde');
    }
    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    inputNumberEditor(props, field) {
        return <InputText type="text" keyfilter="pint" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }



    componentDidMount() {
        this.get_liste_cmde();
        this.get_liste_site();
    }

    get_liste_cmde() {
        let rstatut_cmde = 'Tous';
        let retat_cmde = 'Tous';
        let rsite = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridSite != undefined || this.state.ridSite != null) {
            rsite = this.state.ridSite.value;
        }
        if (this.state.etatCmde != undefined || this.state.etatCmde != null) {
            retat_cmde = this.state.etatCmde.value;
        }
        if (this.state.statutCmde != undefined || this.state.statutCmde != null) {
            rstatut_cmde = this.state.statutCmde.value;
        }
        this.setState({ showindicator: true });
        this.myservice.get_liste_cmde_by_criteria(spd1, spd2, this.state.rnum_cmde, rsite, retat_cmde, rstatut_cmde).then(data => this.setState({ liste_cmde: data, showindicator: false }));
    }

    get_liste_lcmde(idCmde) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_lcmde(idCmde).then(data => this.setState({ liste_lcmde: data, showindicator: false }));
    }

    get_liste_lcmde_validation(idCmde, idSite) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_lcmde_for_validation(idCmde, idSite).then(data => this.setState({ liste_ligne_validation: data, showindicator: false }));
    }


    get_liste_site() {
        this.myservice.get_liste_sites().then(data => this.setState({ liste_site: data }));
    }

    get_liste_article(id) {
        this.myservice.get_liste_article_moyenne(id).then(data => this.setState({ liste_article: data }, () => {
            if (this.state.liste_article != null && this.state.liste_article != undefined) {
                let lprod = this.state.liste_article.map(x => {
                    return { value: x.idArticle, label: x.libArticle + ' [ ' + x.codeArticle + ' ]', stock: + x.qte_stock, code: x.codeArticle, ref: x.refArticle }
                });
                this.setState({ lprod: lprod });
            }
        }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.setState({ liste_lcmde: [] });
            this.get_liste_cmde();
            this.annule_cmde();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_lcmde(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            if (this.state.selectedCmde != null && this.state.selectedCmde != undefined) {
                this.get_liste_lcmde(this.state.selectedCmde.idCmde);
                this.forceUpdate();
            }
            this.setState({ visible_dlg_validation: false });
            this.get_liste_cmde();
            this.annule_lcmde();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    show_trace(rowData) {
        this.setState({ visible_dlg_trace: true });
        this.myservice.get_liste_trace(rowData.idCmde, 'Commande').then(data => this.setState({ liste_trace: data }));
    }

    conf_print_bc(data) {
        this.myservice.generer_edition_bc(data.idCmde);
    }

    edit_cmde(data) {
        if (data != null && data != undefined) {
            if (data.etatCmde == 'En attente') {
                this.setState({ idCmde: data.idCmde });
                this.setState({ cmtCmde: data.cmtCmde });
                this.setState({ dateCmde: data.dateCmde });
                this.setState({ dateDebCmde: data.dateDebCmde });
                this.setState({ dateLivrCmde: data.dateLivrCmde });
                this.setState({ dateFinCmde: data.dateFinCmde });
                this.setState({ numCmde: data.numCmde });
                this.setState({ typeCmde: { value: data.typeCmde, label: data.typeCmde } });
                this.setState({ idSite: { value: data.idSite.idSite, label: data.idSite.nomSite + '/' + data.idSite.prefectureSite } });
               
                this.setState({ modif: true });
                this.setState({ visible_dlg_cmde: true });
                this.setState({ selectedCmde: data });
                this.setState({ ficBcCmde: data.ficBcCmde });
                this.setState({ ficBlCmde: data.ficBlCmde });

                if (data.ficBcCmde !== null && data.ficBcCmde !== '') {
                    this.setState({ urlfileBC: 'OK' })
                } else {
                    this.setState({ urlfileBC: null })
                }

                if (data.ficBlCmde !== null && data.ficBlCmde !== '') {
                    this.setState({ urlfileBL: 'OK' })
                } else {
                    this.setState({ urlfileBL: null })
                }

                window.scrollTo(0, 0);
                this.forceUpdate();
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette commande' });
            }
        }
    }

    edit_lcmde(data) {
        if (data != null && data != undefined) {
            this.setState({ idLcmde: data.idLcmde });
            this.setState({ qteDmdeLcmde: data.qteDmdeLcmde });
            this.setState({ cmtLcmde: data.cmtLcmde });
            this.setState({ qteApprouveLcmde: data.qteApprouveLcmde });

            this.setState({ cmmLcmde: data.cmmLcmde });
            this.setState({ qteStockLcmde: data.qteStockLcmde });

            let prod = this.state.lprod.filter(function (el) {
                return el.value == data.idArticle.idArticle;
            })[0];
            this.setState({ idArticle: prod });

            this.setState({ modif_lcmde: true });
            this.setState({ visible_dlg_lcmde: true });
            this.setState({ disable_prod: true });
            this.setState({ selectedLcmde: data });
            this.setState({ libArticle: data.idArticle.libArticle });
            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }


    annule_cmde() {
        this.setState({ idCmde: 0 });
        // this.setState({ dateCmde: '' });
        // this.setState({ dateDebCmde: '' });
        // this.setState({ dateFinCmde: '' });
        this.setState({ urlfileBC: null });
        this.setState({ urlfileBL: null });
        this.setState({ ficBcCmde: '', ficBlCmde: '' });
        this.setState({ cmtCmde: '' });
        this.setState({ numCmde: '', ficBlCmde: '', ficBcCmde: '' });
        this.setState({ modif: false });
        this.setState({ disable_val_cmde: true });
        this.setState({ visible_dlg_cmde: false });
        this.setState({ visible_dlg_livraison: false,visible_dlg_validation: false,visible_dlg_cmde: false });
        this.forceUpdate();
    }

    annule_cmde_dlg() {
        this.annule_cmde();
        this.setState({ liste_ligne: [] });
    }

    annule_lcmde() {
        this.setState({ idLcmde: 0 });
        this.setState({ qteDmdeLcmde: 0 });
        this.setState({ qteApprouveLcmde: 0 });
        this.setState({ cmtLcmde: '' });
        this.setState({ cmmLcmde: 0 });
        this.setState({ qteStockLcmde: 0 });
        this.setState({ modif_lcmde: false });
        this.setState({ liste_ligne: [] });
        this.setState({ visible_dlg_livraison: false, visible_dlg_cmde: false });
        this.forceUpdate();
    }

    vider_lcmde() {
        this.setState({
            qteDmdeLcmde: '',
            qteStockLcmde: '',
            qteLivreLcmde: '',
            cmmLcmde: '',
            cmtLcmde: ''
        });
        this.setState({ modif_lcmde: false });
        this.forceUpdate();
    }

    handleKeyPress = e => {
        if (e.key === 'a' && e.ctrlKey) {
            this.add_article();
        } else if (e.keyCode === 13) {
            this.enreg_cmde();
        }
    }


    enreg_cmde() {
        if (this.state.visible_dlg_livraison == true) {
            if (this.state.dateLivrCmde == "" || this.state.dateLivrCmde == null || this.state.dateLivrCmde == undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
            } else {
                this.state.selectedCmde.statutCmde = 'Livré';
                this.state.selectedCmde.dateLivrCmde = moment(this.state.dateLivrCmde).format('YYYY-MM-DD');
                this.update_etat_statut_cmde(this.state.selectedCmde);
            }
        } else {
            if (this.state.dateDebCmde == "" || this.state.dateDebCmde == null || this.state.dateFinCmde == "" || this.state.dateFinCmde == null || this.state.dateCmde == "" || this.state.dateCmde == null || this.state.idSite == null || this.state.idSite == undefined || this.state.typeCmde == null || this.state.typeCmde == undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
            } else {
                let uploadBC = false;
                let nomficBC = this.state.ficBcCmde;
                if (this.state.fileBC != null && this.state.fileBC != undefined) {
                    let name = this.state.fileBC.name;
                    nomficBC = 'BC_' + this.myservice.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                    uploadBC = true;
                }

                let uploadBL = false;
                let nomficBL = this.state.ficBcCmde;
                if (this.state.fileBL != null && this.state.fileBL != undefined) {
                    let name = this.state.fileBL.name;
                    nomficBL = 'BL_' + this.myservice.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                    uploadBL = true;
                }

                let date_cmde = moment(this.state.dateCmde).format('YYYY-MM-DD');
                let date_deb = moment(this.state.dateDebCmde).format('YYYY-MM-DD');
                let date_fin = moment(this.state.dateFinCmde).format('YYYY-MM-DD');

                if (this.state.modif == false) {
                    let data = {
                        ficBcCmde: nomficBC, ficBlCmde: nomficBL,
                        dateCmde: date_cmde, typeCmde: this.state.typeCmde.value, dateDebCmde: date_deb, dateFinCmde: date_fin,
                        numCmde: this.state.numCmde, cmtCmde: this.state.cmtCmde, idSite: { idSite: this.state.idSite.value, nomSite: this.state.idSite.label }
                    };
                    data.idCmde = 0;
                    if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les produits' });
                        return;
                    } else if (this.state.liste_ligne.length <= 0) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les produits' });
                        return;
                    }
                    data.idUser = this.state.userconnected;
                    data.iduser = this.state.userconnected.idUser;
                    for (var i = this.state.liste_ligne.length; i--;) {
                        this.state.liste_ligne[i].idCmde = data;
                        this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
                    }
                    this.setState({ showindicator: true });
                    this.myservice.enreg_lcmde_batch(this.state.liste_ligne).then(reponse => {
                        if (reponse != undefined && reponse != null) {
                            if (reponse.code == 200) {
                                if (uploadBC === true) {
                                    this.fileUpload(this.state.fileBC, nomficBC);
                                }
                                if (uploadBL === true) {
                                    this.fileUpload(this.state.fileBL, nomficBL);
                                }
                            }
                            this.resultat_lcmde(reponse.code, reponse.contenu);
                            this.get_liste_cmde();
                            this.state.idSite = null;
                        }

                    });

                } else {
                    let data = this.state.selectedCmde;
                    let dateLivrCmde = moment(this.state.dateLivrCmde).format('YYYY-MM-DD');
                    if (dateLivrCmde === 'Invalid date') {
                        dateLivrCmde = null;
                    }
                    data.ficBcCmde = nomficBC;
                    data.ficBlCmde = nomficBL;
                    data.dateLivrCmde = dateLivrCmde;
                    data.idUser = this.state.selectedCmde.idUser;
                    data.dateCmde = date_cmde;
                    data.typeCmde = this.state.typeCmde.value;
                    data.dateDebCmde = date_deb;
                    data.dateFinCmde = date_fin;
                    data.numCmde = this.state.numCmde;
                    data.cmtCmde = this.state.cmtCmde;
                    data.idSite = { idSite: this.state.idSite.value, nomSite: this.state.idSite.label };
                    this.setState({ showindicator: true });
                    this.myservice.enreg_cmde(data).then(reponse => {
                        if (reponse != undefined && reponse != null) {
                            if (reponse.code == 200) {
                                if (uploadBC === true) {
                                    this.fileUpload(this.state.fileBC, nomficBC);
                                }
                                if (uploadBL === true) {
                                    this.fileUpload(this.state.fileBL, nomficBL);
                                }
                            }
                            this.resultat_lcmde(reponse.code, reponse.contenu);
                            this.get_liste_cmde();
                            this.state.idSite = null;
                        }
                    });
                }
            }
        }
    }

    enreg_livraison() {
        if (this.state.dateLivrCmde == undefined || this.state.dateLivrCmde == null || this.state.dateLivrCmde == "") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let uploadBL = false;
            let nomficBL = this.state.ficBcCmde;
            if (this.state.fileBL != null && this.state.fileBL != undefined) {
                let name = this.state.fileBL.name;
                nomficBL = 'BL_' + this.myservice.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadBL = true;
            }
            let data = this.state.selectedCmde;
            let dateLivrCmde = moment(this.state.dateLivrCmde).format('YYYY-MM-DD');
            if (dateLivrCmde === 'Invalid date') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez indiquer une date de livraison correcte' });
                return;
            }
            let liste_lcmde_livr = this.state.liste_lcmde_livr;
            data.ficBlCmde = nomficBL;
            data.statutCmde = 'Livré';
            data.dateLivrCmde = dateLivrCmde;
            for (var i = liste_lcmde_livr.length; i--;) {
                liste_lcmde_livr[i].idCmde = data;
                liste_lcmde_livr[i].iduser = this.state.userconnected.idUser;
            }
            this.setState({ showindicator: true });
            this.myservice.enreg_livraison(liste_lcmde_livr).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    if (reponse.code == 200) {
                        if (uploadBL === true) {
                            this.fileUpload(this.state.fileBL, nomficBL);
                        }
                    }
                    this.resultat_lcmde(reponse.code, reponse.contenu);
                    this.get_liste_cmde();
                    this.state.idSite = null;
                }
            });
        }
    }

    update_etat_statut_cmde(rowData) {
        this.setState({ showindicator: true });
        this.myservice.enreg_cmde(rowData).then(data => this.resultat(data.code, data.contenu));
    }

    enreg_lcmde() {
        if (this.state.selectedCmde == null || this.state.selectedCmde == undefined || this.state.qteDmdeLcmde == '' || this.state.qteDmdeLcmde == undefined || this.state.idArticle == null || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif_lcmde == false) {
                let data = { idLcmde: 0, qteStockLcmde: this.state.qteStockLcmde, qteLivreLcmde: this.state.qteLivreLcmde, cmmLcmde: this.state.cmmLcmde, qteDmdeLcmde: this.state.qteDmdeLcmde, qteApprouveLcmde: this.state.qteApprouveLcmde, cmtLcmde: this.state.cmtLcmde, idCmde: this.state.selectedCmde, idArticle: { idArticle: this.state.idArticle.value, nomSite: this.state.idArticle.label } };
                for (var i = this.state.liste_lcmde.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_lcmde[i].idArticle.idArticle) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Ce produit existe déja dans la commande sélectionnée, vous ne pouvez que modifier sa quantité' });
                        return;
                    }
                }
                this.myservice.enreg_lcmde(data).then(data => this.resultat_lcmde(data.code, data.contenu));
            } else {
                let data = this.state.selectedLcmde;
                data.qteStockLcmde = this.state.qteStockLcmde;
                data.qteLivreLcmde = this.state.qteLivreLcmde;
                data.qteDmdeLcmde = this.state.qteDmdeLcmde;
                data.cmmLcmde = this.state.cmmLcmde;
                data.qteApprouveLcmde = this.state.qteApprouveLcmde;
                data.cmtLcmde = this.state.cmtLcmde;
                this.myservice.enreg_lcmde(data).then(data => this.resultat_lcmde(data.code, data.contenu));
            }
        }
    }

    valider_commande() {
        this.setState({ showindicator: true });
        this.myservice.valider_cmde(this.state.liste_ligne_validation).then(data => {
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                this.resultat_lcmde(data.code, data.contenu);
            }
        });
    }

    conf_delete_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idArticle.idArticle == this.state.liste_ligne[i].idArticle.idArticle) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    add_article() {
        if (this.state.idSite == null || this.state.idSite == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un site' });
            return;
        }
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        let trouve = false;

        if (this.state.idArticle === null || this.state.idArticle === '' || this.state.idArticle === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un produit' });
        } else if (this.state.qteDmdeLcmde === null || this.state.qteDmdeLcmde === '' || this.state.qteDmdeLcmde === 0 || this.state.qteStockLcmde === null || this.state.qteStockLcmde === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les quantités ' });
        } else if (this.state.cmmLcmde === null || this.state.cmmLcmde === '' || this.state.cmmLcmde <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'La consommation moyenne mensuelle (CMM) doit être supérieure à zéro' });
        }
        else {
            let qts = this.state.qteDmdeLcmde;
            let qtc = this.state.qteApprouveLcmde;
            if (this.state.qteDmdeLcmde === null || this.state.qteDmdeLcmde === '') {
                qts = 1;
            }
            if (this.state.qteApprouveLcmde === null || this.state.qteApprouveLcmde === '') {
                qtc = 0;
            }
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            if (this.state.liste_ligne.length == 0) {
                this.state.liste_ligne.insert(this.state.num_ligne, { idLcmde: 0, qteLivreLcmde: 0, qteDmdeLcmde: qts, qteApprouveLcmde: qtc, qteStockLcmde: this.state.qteStockLcmde, cmmLcmde: this.state.cmmLcmde, cmtLcmde: this.state.cmtLcmde, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
            } else {
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                        trouve = true;
                        this.state.liste_ligne[i].qteDmdeLcmde = parseFloat(this.state.liste_ligne[i].qteDmdeLcmde) + parseFloat(qts);
                    }
                }
                if (trouve == false) {
                    this.state.liste_ligne.insert(this.state.num_ligne, { idLcmde: 0, qteLivreLcmde: 0, qteDmdeLcmde: qts, qteApprouveLcmde: qtc, qteStockLcmde: this.state.qteStockLcmde, cmmLcmde: this.state.cmmLcmde, cmtLcmde: this.state.cmtLcmde, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
                }
            }
            this.vider_lcmde();
            this.state.idArticle = null;
            document.getElementById("new_dp_prod").click();
        }
        this.setState({ disable_val_cmde: false });
    }


    actionTemplate(rowData, column) {
        let items = [
            {
                label: 'Editer BC',
                icon: 'pi pi-print',
                command: (e) => {
                    this.setState({ selectedCmde: rowData });
                    this.conf_print_bc(rowData);
                }
            },
            {
                label: 'Historique',
                icon: 'pi pi-search',
                command: (e) => {
                    this.setState({ selectedCmde: rowData });
                    this.show_trace(rowData);
                }
            }
        ];
        if (rowData.etatCmde == 'En attente') {
            items.push(
                {
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {

                        this.edit_cmde(rowData);
                    }
                },
                {
                    label: 'Valider',
                    icon: 'pi pi-check',
                    command: (e) => {
                        this.setState({ selectedCmde: rowData });
                        this.show_dlg_validation();
                        this.get_liste_regime_patient(rowData.idSite.idSite);
                        this.get_liste_lcmde_validation(rowData.idCmde, rowData.idSite.idSite);
                    }
                },
            )
        } else {
            if (rowData.statutCmde == 'Non livré') {
                items.push(
                    {
                        label: 'Livrer',
                        icon: 'pi pi-download',
                        command: (e) => {
                            this.setState({ liste_lcmde_livr: this.state.liste_lcmde.length > 0 ? this.state.liste_lcmde.filter(function (el) { return el.qteApprouveLcmde > 0 }) : [] })
                            this.setState({ selectedCmde: rowData });
                            this.setState({ visible_dlg_livraison: true });
                        }
                    },
                )
            } else {
                if (this.myservice.get_userconnected().profilUser == 'Administrateur') {
                    items.push(
                        {
                            label: 'Annuler livraison',
                            icon: 'pi pi-upload',
                            command: (e) => {
                                this.setState({ selectedCmde: rowData });
                                var data = rowData;
                                data.statutCmde = 'Non livré';
                                data.dateLivrCmde = null;
                                this.setState({ showindicator: true });
                                this.myservice.enreg_cmde(data).then(reponse => {
                                    this.setState({ showindicator: false });
                                    if (reponse != undefined && reponse != null) {
                                        this.resultat_lcmde(reponse.code, reponse.contenu);
                                    }
                                });
                            }
                        },
                    )
                }
            }

            if (this.myservice.get_userconnected().profilUser == 'Administrateur') {
                items.push(
                    {
                        label: 'Annuler validation',
                        icon: 'pi pi-ban',
                        command: (e) => {
                            this.setState({ selectedCmde: rowData });
                            var data = rowData;
                            data.etatCmde = 'En attente';
                            this.setState({ showindicator: true });
                            this.myservice.enreg_cmde(data).then(reponse => {
                                this.setState({ showindicator: false });
                                if (reponse != undefined && reponse != null) {
                                    this.resultat_lcmde(reponse.code, reponse.contenu);
                                }
                            });
                        }
                    },
                )
            }
        }
        if (this.myservice.get_userconnected().profilUser == 'Administrateur') {
            items.push(
                {
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.setState({ selectedCmde: rowData });
                        this.conf_delete_cmde(rowData);
                    }
                },
            )
        }


        if (rowData.ficBcCmde !== null && rowData.ficBcCmde !== undefined && rowData.ficBcCmde !== '') {
            items.push(
                {
                    label: 'Télécharger BC',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.setState({ selectedCmde: rowData });
                        this.myservice.download_fichier_joint(rowData.ficBcCmde);
                    }
                },
            )
        }

        if (rowData.ficBlCmde !== null && rowData.ficBlCmde !== undefined && rowData.ficBlCmde !== '') {
            items.push(
                {
                    label: 'Télécharger BL',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.setState({ selectedCmde: rowData });
                        this.myservice.download_fichier_joint(rowData.ficBlCmde);
                    }
                },
            )
        }

        return <div>
            <SplitButton onClick={(e) => this.edit_cmde(rowData)} icon="pi pi-pencil" model={items}></SplitButton>
        </div>

    }

    actionTemplate_lcmde(rowData, column) {
        let disable_button = true;
        if (rowData.idCmde.etatCmde == 'En attente') {
            disable_button = false;
        }
        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.edit_lcmde(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.conf_delete_lcmde(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_ligne(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_cmde() {
        if (this.state.selectedCmde == null || this.state.selectedCmde == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_cmde(this.state.selectedCmde).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_lcmde() {
        if (this.state.selectedLcmde == null || this.state.selectedLcmde == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_lcmde(this.state.selectedLcmde).then(data => this.resultat_lcmde(data.code, data.contenu));
        }
    }

    get_liste_regime_patient(idSite) {
        this.myservice.get_liste_regime_patient().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.filter(function (el) {
                    return el.idSite.idSite == idSite;
                });
                this.setState({ liste_regime_patient: liste });
            }
        });
    }

    conf_delete_cmde(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedCmde: data });
            this.setState({ msg: data.numCmde });
            this.setState({ visible: true });
        }
    }

    conf_delete_lcmde(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLcmde: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_lcmde: true });
        }
    }


    show_new_cmde_dlg() {
        //window.location = "#/commandes/Nouvelle commande";
        this.setState({ visible_new_cmde_dlg: true });
        this.setState({ modif: false });
    }

    show_lcmde_dlg() {
        this.setState({ visible_dlg_lcmde: true });
        this.setState({ modif_lcmde: false });
        this.setState({ disable_prod: false });
    }


    show_dlg_validation() {
        this.setState({ visible_dlg_validation: true });
    }


    openFileUploadBC() {
        document.getElementById("hiddenFileBC").click();
    }

    openFileUploadBL() {
        document.getElementById("hiddenFileBL").click();
    }

    /* Uploader sur le serveur les fichiers */
    fileUpload(file, namefile) {
        this.myservice.upload_file(file, namefile).then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.growl.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_cmde();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_lcmde = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_lcmde: false });
                    this.delete_lcmde();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_lcmde: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );


        const dialogFooter_validation = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_validation: false })} label="Annuler" className="p-button-secondary" style={{ width: 120 }} />

                {this.state.showindicator ?
                    <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" />
                    : <Button icon="pi pi-check" onClick={() => {
                        this.valider_commande();
                    }} label="Valider" style={{ width: 120 }} />}
            </div>
        );

        const dialogFooter_trace = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_trace: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let lsite = null;
        let lsite_rech = [{ value: 'Tous', label: 'Sites' }];
        let lprod = null;

        if (this.state.liste_site != null && this.state.liste_site != undefined) {
            lsite = this.state.liste_site.map(x => {
                return { value: x.idSite, label: x.nomSite + '/' + x.prefectureSite }
            });
            lsite_rech = lsite_rech.concat(lsite);
        }



        const header_cmde =
            <div style={{ textAlign: 'left', width: 280 }}>
                <div className="p-inputgroup">
                    <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouvelle commande" style={{ width: 200 }} onClick={this.show_new_cmde_dlg}></Button>
                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                </div>
            </div>;


        const header_lcmde = <div style={{ textAlign: 'left', width: 280 }}>
            <div className="p-inputgroup">
                <Button type="button" disabled={this.state.disable_add_prod} icon="pi pi-plus" iconPos="left" label="Ajouter un produit" style={{ width: 200 }} onClick={this.show_lcmde_dlg}></Button>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            </div>
        </div>;

        let mode = false;
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            if (this.props.match.params.typeOp === 'Nouvelle commande') {
                mode = true;
            } else {
                mode = false;
            }
        }

        if (mode === true) {
            return <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

            </div>
        } else
            return (
                <div className="p-fluid">
                    <Growl ref={(el) => this.growl = el} />
                    <Dialog header="Nouvelle commande" visible={this.state.visible_new_cmde_dlg} style={{ width: '97%' }} modal={true} maximizable onHide={(e) => this.setState({ visible_new_cmde_dlg: false })}>
                        <div className="p-grid" style={{ marginBottom: -40 }}>
                            <div className="p-col-12 p-lg-10">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid" >
                                            <div className="p-col-12 p-md-4">
                                                <label >Date émission *</label>
                                                <Calendar value={this.state.dateCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateCmde: e.value })} />
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <label >Type commande *</label>
                                                <Dropdown options={this.state.liste_type_cmde} value={this.state.typeCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                                    this.setState({ typeCmde: e.value })
                                                }} autoWidth={false} />
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <label >Site *</label>
                                                <Dropdown options={lsite} value={this.state.idSite} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                                    this.setState({ idSite: e.value }, () => {
                                                        this.get_liste_article(this.state.idSite.value);
                                                    })
                                                }
                                                } autoWidth={false} />
                                            </div>


                                            <div className="p-col-12 p-md-4">
                                                <label >Date début *</label>
                                                <Calendar value={this.state.dateDebCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateDebCmde: e.value })} />
                                            </div>


                                            <div className="p-col-12 p-md-4">
                                                <label >Date fin *</label>
                                                <Calendar value={this.state.dateFinCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateFinCmde: e.value })} />
                                            </div>


                                            <div className="p-col-12 p-md-4">
                                                <label >Commentaire </label>
                                                <InputText value={this.state.cmtCmde} onChange={(e) => this.setState({ cmtCmde: e.target.value })} />
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-2">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-2">
                                            </div>
                                            <div className="p-col-10">
                                                <a onClick={() => this.openFileUploadBC()} style={{ cursor: 'pointer' }}>
                                                    <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                        <center>
                                                            {this.state.urlfileBC == null ?
                                                                <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30, marginTop: 15 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>SCAN BC</label></div> :
                                                                <img id="footer-logo" src="assets/layout/images/doc_bc.png" alt="diamond-layout" style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', marginTop: 15 }} />
                                                            }
                                                        </center>
                                                    </div>
                                                </a>
                                                <input type="file" id="hiddenFileBC" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                    if (e.target.files && e.target.files[0]) {
                                                        this.setState({ fileBC: e.target.files[0], urlfileBC: 'OK' })
                                                    }
                                                }} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <i style={{ marginTop: -20 }} className="fa fa-list"></i>
                        <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Détails de la commande</span>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-3">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="card card-w-title" style={{ height: 370, marginBottom: -22 }}>
                                            <div className="p-grid" >
                                                <div className="p-col-12">
                                                    <span >
                                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Produit *</label>
                                                    </span>
                                                    <Dropdown id="new_dp_prod" options={this.state.lprod} value={this.state.idArticle} panelStyle={{ width: '600px' }} optionLabel='label' filter={true} filterBy='label' itemTemplate={this.articleTemplate} filterMatchMode='contains' onChange={(e) => this.setState({ idArticle: e.value })} autoWidth={false} />
                                                </div>
                                                <div className="p-col-12">
                                                    <span >
                                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">QAC *</label>
                                                    </span>
                                                    <InputText keyfilter="pint" onKeyDown={this.handleKeyPress} value={this.state.qteDmdeLcmde} onChange={(e) => this.setState({ qteDmdeLcmde: e.target.value })} />
                                                </div>
                                                <div className="p-col-12">
                                                    <span >
                                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">SDU *</label>
                                                    </span>
                                                    <InputText keyfilter="pint" onKeyDown={this.handleKeyPress} value={this.state.qteStockLcmde} onChange={(e) => this.setState({ qteStockLcmde: e.target.value })} />
                                                </div>

                                                <div className="p-col-12">
                                                    <span >
                                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">CMM *</label>
                                                    </span>
                                                    <InputText keyfilter="pint" onKeyDown={this.handleKeyPress} value={this.state.cmmLcmde} onChange={(e) => this.setState({ cmmLcmde: e.target.value })} />
                                                </div>

                                                <div className="p-col-12">
                                                    <span >
                                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Commentaire </label>
                                                    </span>
                                                    <InputText onKeyDown={this.handleKeyPress} value={this.state.cmtLcmde} onChange={(e) => this.setState({ cmtLcmde: e.target.value })} />
                                                </div>
                                                <div className="p-col-12">
                                                    <span >
                                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">.</label>
                                                    </span>
                                                    <Button label="Ajouter >>" style={{ width: 150 }} icon="pi pi-plus" onClick={(e) => this.add_article()} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-9">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="card card-w-title" style={{ height: 370, marginBottom: -22 }}>
                                            <div className="p-grid" style={{ height: 320, borderBottom: 1 }}>
                                                <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="250"
                                                    onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                                    responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }}
                                                    editable={true}>
                                                    <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                                    <Column field="idArticle.libArticle" header="Produit" />
                                                    <Column field="qteStockLcmde" header="SDU" style={{ width: '10%' }} editor={this.qteStockLcmdeEditor} />
                                                    <Column field="cmmLcmde" header="CMM" style={{ width: '10%' }} editor={this.cmmLcmdeEditor} />
                                                    <Column field="qteDmdeLcmde" header="QAC" style={{ width: '10%' }} editor={this.qteDmdeLcmdeEditor} />
                                                    <Column field="cmtLcmde" header="Commentaire" style={{ width: '24%' }} editor={this.cmtLcmdeEditor} />
                                                </DataTable>
                                            </div>
                                            <center>
                                                <div className="p-grid" style={{ width: 300 }}>
                                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => {
                                                        this.annule_cmde_dlg();
                                                        this.setState({ visible_new_cmde_dlg: false });
                                                    }} /></div>
                                                    <div className="p-col"><Button disabled={this.state.disable_val_cmde} label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_cmde()} /></div>
                                                </div>
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Dialog>


                    <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                        <div>
                            <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                            Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                        </div>
                    </Dialog>

                    <Dialog header="Confirmation" visible={this.state.visible_lcmde} style={{ width: '250px' }} modal={true} footer={dialogFooter_lcmde} onHide={(e) => this.setState({ visible_lcmde: false })}>
                        <div>
                            <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                            Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                        </div>
                    </Dialog>

                    <Dialog header="Historique" visible={this.state.visible_dlg_trace} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_trace: false })} footer={dialogFooter_trace}>
                        <DataTable value={this.state.liste_trace} scrollable={true} scrollHeight="350px"
                            responsive={true} style={{ marginBottom: 12 }}>
                            <Column field="dateTrace" header="Date" style={{ width: '20%' }} />
                            <Column field="actionTrace" header="Action" style={{ width: '15%' }} />
                            <Column field="idUser.loginUser" header="Utilisateur" style={{ width: '15%' }} />
                            <Column field="detailTrace" header="Détail" style={{ width: '50%' }} />
                        </DataTable>
                    </Dialog>




                    <Dialog header="Livraison" visible={this.state.visible_dlg_livraison} style={{ width: '650px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_livraison: false })}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ marginBottom: -10 }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">

                                        </div>
                                        <div className="p-col-12 p-md-7">
                                            <label >Date livraison * </label>
                                            <Calendar value={this.state.dateLivrCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateLivrCmde: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <a onClick={() => this.openFileUploadBL()} style={{ cursor: 'pointer' }}>
                                                <div style={{ width: 75, height: 80, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfileBL == null ?
                                                            <div style={{ width: '75px', height: '80px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>SCAN BL</label></div> :
                                                            <img id="footer-logo" src="assets/layout/images/doc_bl.png" alt="diamond-layout" style={{ width: '75px', height: '80px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                                        }
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFileBL" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ fileBL: e.target.files[0], urlfileBL: 'OK' })
                                                }
                                            }} />
                                        </div>

                                    </div>

                                    <DataTable value={this.state.liste_lcmde_livr} selectionMode="single" scrollable={true} scrollHeight="250"
                                        onRowClick={e => this.setState({ selectedLcmdeLivr: e.data })} onRowSelect={e => this.setState({ selectedLcmdeLivr: e.data })}
                                        responsive={true} selection={this.state.selectedLcmdeLivr} style={{ marginBottom: 12 }}
                                        editable={true}>
                                        <Column body={(rowData, props) => {
                                            return <div>
                                                <span >{props.rowIndex + 1}</span>
                                            </div>
                                        }} header="#" style={{ width: '5%' }} />
                                        <Column field="idArticle.libArticle" header="Produit" style={{ width: '65%' }} />
                                        <Column field="qteDmdeLcmde" header="QAC" style={{ width: '10%' }} />
                                        <Column field="qteApprouveLcmde" header="QVAL" style={{ width: '10%' }} />
                                        <Column field="qteLivreLcmde" header="QL" style={{ width: '10%' }} editor={this.qteLivreLcmdeEditor} />
                                    </DataTable>


                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_cmde()} /></div>
                                            <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_livraison()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </Dialog>


                    <Dialog header="Modification commande" visible={this.state.visible_dlg_cmde} style={{ width: '600px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_cmde: false })}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ marginBottom: -10 }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <label >Date émission *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Calendar value={this.state.dateCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateCmde: e.value })} />
                                        </div>
                                        {/* <div className="p-col-12 p-md-3">
                                            <label >Date livraison </label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Calendar value={this.state.dateLivrCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateLivrCmde: e.value })} />
                                        </div> */}

                                        <div className="p-col-12 p-md-3">
                                            <label >Type commande *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Dropdown options={this.state.liste_type_cmde} value={this.state.typeCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                                this.setState({ typeCmde: e.value })
                                            }
                                            } autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label >Site *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Dropdown options={lsite} value={this.state.idSite} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idSite: e.value })} autoWidth={false} />
                                        </div>

                                        <div className="p-col-12 p-md-3">
                                            <label >Date début *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Calendar value={this.state.dateDebCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateDebCmde: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label >Date fin *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Calendar value={this.state.dateFinCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateFinCmde: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label >N° BC *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText value={this.state.numCmde} onChange={(e) => this.setState({ numCmde: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label >Commentaire</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText value={this.state.cmtCmde} onChange={(e) => this.setState({ cmtCmde: e.target.value })} />
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <center>
                                                <a onClick={() => this.openFileUploadBC()} style={{ cursor: 'pointer' }}>
                                                    <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                        <center>
                                                            {this.state.urlfileBC == null ?
                                                                <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>SCAN BC</label></div> :
                                                                <img id="footer-logo" src="assets/layout/images/doc_bc.png" alt="diamond-layout" style={{ width: '120px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                                            }
                                                        </center>
                                                    </div>
                                                </a>
                                                <input type="file" id="hiddenFileBC" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                    if (e.target.files && e.target.files[0]) {
                                                        this.setState({ fileBC: e.target.files[0], urlfileBC: 'OK' })
                                                    }
                                                }} />
                                            </center>
                                        </div>
                                        {/* <div className="p-col-12 p-md-2">

                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <a onClick={() => this.openFileUploadBL()} style={{ cursor: 'pointer' }}>
                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfileBL == null ?
                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>SCAN BL</label></div> :
                                                            <img id="footer-logo" src="assets/layout/images/doc_bl.png" alt="diamond-layout" style={{ width: '120px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                                        }
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFileBL" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ fileBL: e.target.files[0], urlfileBL: 'OK' })
                                                }
                                            }} />
                                        </div> */}

                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_cmde()} /></div>
                                            <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_cmde()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Validation commande" visible={this.state.visible_dlg_validation} style={{ width: '97%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_validation: false })} footer={dialogFooter_validation}>
                        <div className="p-grid" style={{ marginBottom: -25 }}>
                            <div className="p-col-12 p-lg-4">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="card card-w-title" style={{ height: 450 }}>
                                            <i className="fa fa-users"></i>
                                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>File active</span>
                                            <hr />
                                            <DataTable value={this.state.liste_regime_patient} responsive={true} scrollable={true} scrollHeight="450px">
                                                <Column field="idLregime.codeLregime" header="Régime" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '34%' }} />
                                                <Column field="idLregime.idRegime.categorieRegime" header="Catégorie" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '40%' }} />
                                                <Column field="nbPatientRegime" header="File" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '26%' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-8">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="card card-w-title" style={{ height: 450 }}>
                                            <i className="fa fa-shopping-cart"></i>
                                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Détails du bon de commande</span>
                                            <hr />
                                            <DataTable value={this.state.liste_ligne_validation} selectionMode="single" scrollable={true} scrollHeight="400px"
                                                onRowClick={e => this.setState({ selectedLigne_validation: e.data })} onRowSelect={e => this.setState({ selectedLigne_validation: e.data })}
                                                responsive={true} selection={this.state.selectedLigne_validation} style={{ marginBottom: 12 }}
                                                editable={true}>
                                                <Column field="idArticle.libArticle" header="Produit" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="qteDmdeLcmde" header="QAC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                                <Column field="qteDepotLcmde" header="QDP" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                                <Column field="qteStockLcmde" header="SDU" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                                <Column field="cmmLcmde" header="CMM" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                                <Column field="qteRequiseLcmde" header="QDHOS" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                                <Column field="qteApprouveLcmde" header="QVAL" style={{ width: '10%' }} editor={this.qteApprouveLcmdeEditor} filter={true} filterMatchMode='contains' />
                                                <Column field="cmtLcmde" header="Commentaire" style={{ width: '16%' }} editor={this.cmtLcmdeEditor} filter={true} filterMatchMode='contains' />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header={this.state.modif_lcmde == true ? "Modification ligne commande" : "Ajout produit à la commande"} visible={this.state.visible_dlg_lcmde} style={{ width: '550px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_lcmde: false })}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" >
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="profile">Produit *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Dropdown disabled={this.state.disable_prod} options={this.state.lprod} value={this.state.idArticle} panelStyle={{ width: '600px' }} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idArticle: e.value })} autoWidth={false} itemTemplate={this.articleTemplate} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label title='Quantité commandée par le site' htmlFor="profile">QAC *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText keyfilter="pint" value={this.state.qteDmdeLcmde} onChange={(e) => this.setState({ qteDmdeLcmde: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label title='Stock Disponible Utilisable' htmlFor="profile">SDU *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText keyfilter="pint" value={this.state.qteStockLcmde} onChange={(e) => this.setState({ qteStockLcmde: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label title='Consommation Moyenne Mensuelle' htmlFor="profile">CMM *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText keyfilter="pint" value={this.state.cmmLcmde} onChange={(e) => this.setState({ cmmLcmde: e.target.value })} />
                                        </div>



                                        {/*  
                                       <div className="p-col-12 p-md-3">
                                            <label title='Quantité livrée' htmlFor="profile">QL *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText keyfilter="pint" value={this.state.qteLivreLcmde} onChange={(e) => this.setState({ qteLivreLcmde: e.target.value })} />
                                        </div> 
                                        */}


                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="profile">Commentaire </label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText value={this.state.cmtLcmde} onChange={(e) => this.setState({ cmtLcmde: e.target.value })} />
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_lcmde: false }) }} /></div>
                                            <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_lcmde()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </Dialog>


                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                                <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown options={lsite_rech} placeholder="Site" value={this.state.ridSite} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridSite: e.value })} autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown options={this.state.liste_statut} placeholder="Statut" value={this.state.statutCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ statutCmde: e.value })} autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown options={this.state.liste_etat} placeholder="Etat" value={this.state.etatCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ etatCmde: e.value })} autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_cmde()} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6">
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div className="card card-w-title" style={{ height: 600 }}>
                                                    <DataTable value={this.state.liste_cmde} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                                        onRowClick={e => this.setState({ selectedCmde: e.data })} onRowSelect={e =>
                                                            this.setState({ selectedCmde: e.data }, () => {
                                                                this.get_liste_lcmde(this.state.selectedCmde.idCmde);
                                                                this.get_liste_article(this.state.selectedCmde.idSite.idSite);
                                                                if (this.state.selectedCmde.etatCmde == 'En attente') {
                                                                    this.setState({ disable_add_prod: false });
                                                                } else {
                                                                    this.setState({ disable_add_prod: true });
                                                                }
                                                            })
                                                        }
                                                        header={header_cmde}
                                                        responsive={true} selection={this.state.selectedCmde} >
                                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '12%', overflow: 'visible' }} />
                                                        <Column field="idCmde" header="Num BC" style={{ width: '14%' }} />
                                                        <Column field="dateCmde" header="Date" style={{ width: '16%' }} />
                                                        <Column field="statutCmde" header="Statut" style={{ width: '14%' }} />
                                                        <Column field="etatCmde" header="Etat" style={{ width: '16%' }} />
                                                        <Column field="idSite.nomSite" header="Site" style={{ width: '28%' }} />
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-6">
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div className="card card-w-title" style={{ height: 600 }}>
                                                    <DataTable value={this.state.liste_lcmde} selectionMode="single" scrollable={true} scrollHeight="460px"
                                                        onRowClick={e => this.setState({ selectedLcmde: e.data })} onRowSelect={e => this.setState({ selectedLcmde: e.data })}
                                                        responsive={true} selection={this.state.selectedLcmde} header={header_lcmde} >
                                                        <Column header="#" body={this.actionTemplate_lcmde.bind(this)} style={{ width: '16%' }} />
                                                        <Column field="idArticle.libArticle" header="Produit" />
                                                        <Column field="cmmLcmde" header="CMM" style={{ width: '10%' }} />
                                                        <Column field="qteDmdeLcmde" header="QAC" style={{ width: '10%' }} />
                                                        <Column field="qteStockLcmde" header="SDU" style={{ width: '10%' }} />
                                                        <Column field="qteApprouveLcmde" header="QVAL" style={{ width: '10%' }} />
                                                        <Column field="qteLivreLcmde" header="QL" style={{ width: '10%' }} />
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            );
    }
}