import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';

export class Form_depot extends Component {
    constructor() {
        super();
        this.state = {
            idDepot: 0,
            nomDepot: '',
            idDepsite: 0,
            idSite: null,
            liste_depot: [],
            liste_depot_site: [],
            liste_site: [],
            selectedDepot: null,
            selectedDepot_site: null,
            msg: '',
            modif: false,
            modif_depot_site: false,
            visible: false,
            visible_depot_site: false,
            showindicator: false,
            selectedSites: null,
        };

        this.edit_depot = this.edit_depot.bind(this);
        this.conf_delete_depot = this.conf_delete_depot.bind(this);

        this.conf_delete_depot_site = this.conf_delete_depot_site.bind(this);

        this.myservice = new Dhosservice();
    }

    componentDidMount() {
        this.get_liste_depot();
        this.get_liste_site();
    }

    get_liste_depot() {
        this.myservice.get_liste_depot().then(data => this.setState({ liste_depot: data }));
    }

    get_liste_depot_site(idDepot) {
        this.myservice.get_liste_depot_site(idDepot).then(data => this.setState({ liste_depot_site: data }));
    }


    get_liste_site() {
        this.myservice.get_liste_sites().then(data => this.setState({ liste_site: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_depot();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_depot_site(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_depot_site();
            this.get_liste_depot_site(this.state.selectedDepot.idDepot);
            this.setState({selectedSites: [] });
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false});
    }



    edit_depot(data) {
        if (data != null && data != undefined) {
            this.setState({ idDepot: data.idDepot });
            this.setState({ nomDepot: data.nomDepot });
            this.setState({ categorieRegime: { value: data.categorieRegime, label: data.categorieRegime } });
            this.setState({ modif: true });
            this.setState({ selectedDepot: data });
            window.scrollTo(0, 0);
        }
    }



    annule_depot() {
        this.setState({ idDepot: 0 });
        this.setState({ nomDepot: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annule_depot_site() {
        this.setState({ idDepsite: 0 });
        this.setState({ modif_depot_site: false });
        this.forceUpdate();
    }

    enreg_depot() {
        if (this.state.nomDepot == "" || this.state.nomDepot == null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let data = { nomDepot: this.state.nomDepot };
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                data.idDepot = 0;
                this.myservice.enreg_depot(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                data.idDepot = this.state.idDepot;
                this.myservice.enreg_depot(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_depot_site() {
        if (this.state.selectedDepot == null || this.state.selectedDepot == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner un dépot' });
        } else if (this.state.selectedSites == null || this.state.selectedSites == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les sites' });
        } else if (this.state.selectedSites.length == 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les sites' });
        } else {
            this.setState({ showindicator: true });
            Array.prototype.insert = function (index) {
                this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
            };
            var data = [];
            let userconnected = JSON.parse(localStorage.getItem('userconnected'));
            for (var i = 1; i < this.state.selectedSites.length; i++) {
                let obj = { idDepsite: 0, idDepot: this.state.selectedDepot, idSite: { idSite: this.state.selectedSites[i].value }, iduser: userconnected.idUser };
                data.insert(i, obj);
            }
            this.myservice.enreg_depot_site_batch(data).then(data => this.resultat_depot_site(data.code, data.contenu));
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_depot(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_depot(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_depot_site(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_depot_site(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_depot() {
        if (this.state.selectedDepot == null || this.state.selectedDepot == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_depot(this.state.selectedDepot).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_depot_site() {
        if (this.state.selectedDepot_site == null || this.state.selectedDepot_site == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_depot_site(this.state.selectedDepot_site).then(data => this.resultat_depot_site(data.code, data.contenu));
        }
    }

    conf_delete_depot(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedDepot: data });
            this.setState({ msg: data.nomDepot });
            this.setState({ visible: true });
        }
    }

    conf_delete_depot_site(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedDepot_site: data });
            this.setState({ msg: data.idSite.nomSite });
            this.setState({ visible_depot_site: true });
        }
    }

    render() {
        const header_depot_site =
            <div style={{ textAlign: 'left', width: 280 }}>
                <div className="label">
                    <span >
                        <label style={{ fontSize: '13px', color: 'green' }} htmlFor="inPu">{"Nombre de sites: " + this.state.liste_depot_site.length}</label>
                    </span>
                </div>
            </div>;

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_depot();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_depot_site = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_depot_site: false });
                    this.delete_depot_site();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_depot_site: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let lsite = null;

        if (this.state.liste_site != null && this.state.liste_site != undefined) {
            lsite = this.state.liste_site.map(x => {
                return { value: x.idSite, label: x.nomSite + '/' + x.regionSite }
            });
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_depot_site} style={{ width: '250px' }} modal={true} footer={dialogFooter_depot_site} onHide={(e) => this.setState({ visible_depot_site: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">

                                <div className="card card-w-title" style={{ height: 600 }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-4">
                                            <label >Nom dépot *</label>
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <InputText value={this.state.nomDepot} onChange={(e) => this.setState({ nomDepot: e.target.value })} />
                                        </div>
                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_depot()} /></div>
                                            <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_depot()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>
                                    <DataTable value={this.state.liste_depot} selectionMode="single" scrollable={true} scrollHeight="400"
                                        onRowClick={e => this.setState({ selectedDepot: e.data })} onRowSelect={e =>
                                            this.setState({ selectedDepot: e.data }, () => {
                                                this.get_liste_depot_site(this.state.selectedDepot.idDepot);
                                            })
                                        }
                                        responsive={true} selection={this.state.selectedDepot} >
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '22%' }} />
                                        <Column field="nomDepot" header="Désignation" style={{ width: '78%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-8">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 600 }}>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-1">
                                            <label htmlFor="profile">Site *</label>
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <MultiSelect options={lsite} value={this.state.selectedSites} onChange={(e) => this.setState({ selectedSites: e.value })} scrollHeight='200px' optionLabel="label" filter={true} filterBy='label' filterMatchMode='contains' placeholder="Selectionner les sites" autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button label="Affecter" icon="pi pi-check" onClick={(e) => this.enreg_depot_site()} />
                                        </div>
                                        <div className="p-col-12 p-md-1">
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                    <DataTable value={this.state.liste_depot_site} header={header_depot_site} selectionMode="single" scrollable={true} scrollHeight="430px"
                                        onRowClick={e => this.setState({ selectedDepot_site: e.data })} onRowSelect={e => this.setState({ selectedDepot_site: e.data })}
                                        responsive={true} selection={this.state.selectedDepot_site} >
                                        <Column header="#" body={this.actionTemplate_depot_site.bind(this)} style={{ width: '10%' }} />
                                        <Column field="idSite.nomSite" header="Nom site" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="idSite.crdSite" header="CR" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="idSite.prefectureSite" header="Prefecture" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="idSite.regionSite" header="Region" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}