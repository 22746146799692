import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import './styles.css';

export class Form_users extends Component {
    constructor() {
        super();
        this.state = {
            liste_users: [],
            selectedSites: null,
            idUser: '',
            loginUser: '',
            emailUser: '',
            telUser: '',
            pwdUser: '',
            nomUser: '',
            profilUser: { label: 'Administrateur', value: 'Administrateur' },
            actifUser: { label: 'Oui', value: 'Oui' },
            selecteduser: null,
            modif: false,
            modif_gerer_site: false,
            visible: false,
            idSite: null,
            idGererSite: 0,
            nomSite: '',
            paramIdUser: '',
            liste_site: [],
            liste_gerer_site: [],
            selectedGerersite: null,
            showindicator: false,
            visible_del_gerer: false
        };
        this.get_liste_users = this.get_liste_users.bind(this);
        this.get_liste_site = this.get_liste_site.bind(this);
        this.get_liste_gerer_site = this.get_liste_gerer_site.bind(this);
        this.myservice = new Dhosservice();
        this.edit_user = this.edit_user.bind(this);
        this.show_dlg_gerer = this.show_dlg_gerer.bind(this);
        this.enreg_user = this.enreg_user.bind(this);
        this.enreg_gerer_site = this.enreg_gerer_site.bind(this);
        this.act_gerer_site = this.act_gerer_site.bind(this);
        this.conf_delete_gerer = this.conf_delete_gerer.bind(this);
    }

    componentDidMount() {
        this.get_liste_users();
        this.get_liste_site();
    }

    conf_delete_gerer(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedGerersite: data });
            this.setState({ visible_del_gerer: true });
        }
    }

    delete_gerer() {
        if (this.state.selectedGerersite == null || this.state.selectedGerersite == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_gerer_site(this.state.selectedGerersite).then(data => this.resultat_gerer_site(data.code, data.contenu));
        }
    }

    get_liste_site() {
        this.myservice.get_liste_sites().then(data => this.setState({ liste_site: data }));
    }

    get_liste_users() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_users().then(data => this.setState({ liste_users: data, showindicator: false }));
    }

    get_liste_gerer_site() {
        this.myservice.get_liste_gerer_site_user(this.state.paramIdUser).then(data => this.setState({ liste_gerer_site: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_user();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_gerer_site(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.setState({ modif_gerer_site: false, selectedSites: [] });
            this.get_liste_gerer_site();

            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_user(data) {
        if (data != null && data != undefined) {
            this.setState({ idUser: data.idUser });
            this.setState({ emailUser: data.emailUser });
            this.setState({ loginUser: data.loginUser });
            this.setState({ nomUser: data.nomUser });
            this.setState({ profilUser: { value: data.profilUser, label: data.profilUser } });
            this.setState({ actifUser: { label: data.actifUser, value: data.actifUser } });
            this.setState({ telUser: data.telUser });
            this.setState({ modif: true });
            this.setState({ selecteduser: data });
            window.scrollTo(0, 0);
        }
    }

    annule_user() {
        this.setState({ loginUser: '' });
        this.setState({ nomUser: '' });
        this.setState({ emailUser: '' });
        this.setState({ telUser: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_user() {
        if (this.state.loginUser == "" || this.state.nomUser == '' || this.state.profilUser == null || this.state.profilUser == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let data = { idUser: this.state.idUser, nomUser: this.state.nomUser, loginUser: this.state.loginUser, emailUser: this.state.emailUser, telUser: this.state.telUser, actifUser: this.state.actifUser.value, profilUser: this.state.profilUser.value };
            if (this.state.modif == false) {
                data.idUser = '0';
            } else {
                data.idUser = this.state.idUser;
            }
            this.myservice.enreg_user(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    enreg_gerer_site() {
        if (this.state.selectedSites == null || this.state.selectedSites == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les sites' });
        } else if (this.state.selectedSites.length == 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les sites' });
        } else {
            this.setState({ showindicator: true });
            Array.prototype.insert = function (index) {
                this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
            };
            if (this.state.modif_gerer_site == false) {
                this.setState({ showindicator: true });
                var data = [];
                let userconnected = JSON.parse(localStorage.getItem('userconnected'));
                for (var i = this.state.selectedSites.length; i--;) {
                    var obj = { actifGererSite: 'Oui', idSite: { idSite: this.state.selectedSites[i].value }, idUser: this.state.selecteduser, iduser: userconnected.idUser };
                    obj.idGererSite = 0;
                    data.insert(i, obj);
                }
                this.myservice.enreg_gerer_site_bulk(data).then(data => this.resultat_gerer_site(data.code, data.contenu));
            } else {
                let data = this.state.selectedGerersite;
                if (this.state.selectedGerersite.actifGererSite == 'Oui') {
                    data.actifGererSite = 'Non';
                } else {
                    data.actifGererSite = 'Oui';
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_gerer_site(data).then(data => this.resultat_gerer_site(data.code, data.contenu));
            }
        }
    }

    act_gerer_site(selected_data) {
        if (selected_data.actifGererSite == 'Oui') {
            selected_data.actifGererSite = 'Non';
        } else {
            selected_data.actifGererSite = 'Oui';
        }
        this.myservice.enreg_gerer_site(selected_data).then(data => this.resultat_gerer_site(data.code, data.contenu));
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_user(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.show_dlg_gerer(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-sitemap" /></div>
        </div>
    }


    actionTemplate_gerer_site(rowData, column) {
        if (rowData.actifGererSite == 'Oui') {
            return <div className="p-grid" style={{ width: 90 }}>
                <div className="p-col"><Button title="Désactiver" onClick={(e) => this.act_gerer_site(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-check" /></div>
                <div className="p-col"><Button onClick={(e) => this.conf_delete_gerer(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
            </div>

        } else {
            return <div className="p-grid" style={{ width: 90 }}>
                <div className="p-col"><Button title="Activer" onClick={(e) => this.act_gerer_site(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-check" /></div>
                <div className="p-col"><Button onClick={(e) => this.conf_delete_gerer(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
            </div>
        }
    }

    delete_user() {
        if (this.state.selecteduser == null || this.state.selecteduser == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_user(this.state.selecteduser).then(data => this.resultat(data.code, data.contenu));
        }
    }

    show_dlg_gerer(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selecteduser: data });
            this.setState({ paramIdUser: data.idUser }, () => {
                this.get_liste_gerer_site();
            });
            this.setState({ msg: data.loginUser });
            this.setState({ visible: true });
        }
    }

 
    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Fermer" className="p-button-secondary" />
            </div>
        );

        const dialogFooterGerer = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_del_gerer: false });
                    this.delete_gerer();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_del_gerer: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const liste_profilUser = [
            { label: 'Administrateur', value: 'Administrateur' },
            { label: 'Approbateur', value: 'Approbateur' },
            { label: 'Responsabe site/Agent de saisie', value: 'Responsabe site/Agent de saisie' },
            { label: 'Consultation', value: 'Consultation' }
        ];

        const liste_actifUser = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];
        let lsites = this.state.liste_site.map(x => {
            return { value: x.idSite, label: x.nomSite + "/" + x.regionSite }
        });
        let header_print = <Button icon='pi pi-print' className="p-button-success" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dt.exportCSV()} />

        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible_del_gerer} style={{ width: '250px' }} modal={true} footer={dialogFooterGerer} onHide={(e) => this.setState({ visible_del_gerer: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <Dialog header="Sites rattachés" visible={this.state.visible} style={{ width: '55%' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 450, marginBottom: -20 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="profile">Sites *</label>
                                    </div>
                                    <div className="p-col-12 p-md-7">
                                        <MultiSelect options={lsites} value={this.state.selectedSites} onChange={(e) => this.setState({ selectedSites: e.value })} scrollHeight='200px' optionLabel="label" filter={true} filterBy='label' filterMatchMode='contains' placeholder="Selectionner les sites" autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Button label="Ajouter" icon="pi pi-check" onClick={(e) => this.enreg_gerer_site()} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div> 
                                <DataTable value={this.state.liste_gerer_site} selectionMode="single" scrollable={true} scrollHeight="300px"
                                    onRowClick={e => this.setState({ selectedGerersite: e.data })} onRowSelect={e => this.setState({ selectedGerersite: e.data })}
                                    responsive={true} selection={this.state.selectedGerersite} >
                                    <Column header="#" body={this.actionTemplate_gerer_site.bind(this)} style={{ width: '14%' }} />
                                    <Column field="idSite.nomSite" header="Nom site" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idSite.crdSite" header="CRD" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idSite.regionSite" header="Région" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Nom prénoms * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="libCodif" value={this.state.nomUser} onChange={(e) => this.setState({ nomUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Login * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="libCodif" value={this.state.loginUser} onChange={(e) => this.setState({ loginUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Profil *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.profilUser} options={liste_profilUser} onChange={(e) => { this.setState({ profilUser: e.value }) }} optionLabel="label" style={{ width: 200 }} placeholder="profilUser" />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Email  </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="libCodif" value={this.state.emailUser} onChange={(e) => this.setState({ emailUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Téléphone  </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="libCodif" value={this.state.telUser} onChange={(e) => this.setState({ telUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Actif *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.actifUser} options={liste_actifUser} onChange={(e) => { this.setState({ actifUser: e.value }) }} optionLabel="label" style={{ width: 200 }} placeholder="actifUser" />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_user()} /></div>
                                    <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_user()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                            <DataTable value={this.state.liste_users} paginatorPosition="top" paginator={true} rows={20} style={{ width: '100%' }}
                                alwaysShowPaginator={false} responsive={true} ref={(el) => { this.dt = el; }} selectionMode="single"
                                onRowClick={e => this.setState({ selecteduser: e.data })} onRowSelect={e => this.setState({ selecteduser: e.data })}
                                selection={this.state.selecteduser} csvSeparator=";" paginatorLeft={header_print}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="nomUser" header="Nom prénoms" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '25%' }} />
                                <Column field="loginUser" header="Login" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="telUser" header="Téléphone" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="emailUser" header="Email" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '25%' }} />
                                <Column field="profilUser" header="Profil" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                <Column field="actifUser" header="Actif" filter={true} filterMatchMode='contains' sortable={true} tyle={{ width: '7%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}