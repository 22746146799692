import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { Panel } from 'primereact/components/panel/Panel';
import { Button } from 'primereact/components/button/Button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadialGauge } from 'react-canvas-gauges';
import { Dialog } from 'primereact/dialog';

//import { GMap } from 'primereact/gmap';

export class Dashboard extends Component {

    constructor() {
        super();
        this.state = {
            liste_cmde_site: [],
            liste_st_top_prod: [],
            liste_st_top_prod_all: [],
            liste_st_top_cmde: [],
            liste_st_top_livr: [],
            liste_st_down_livr: [],
            liste_st_top_cmde_prod_site: [],
            liste_st_cmde_annee: [],
            selectedProd: null,
            liste_top_prod_site: [],
            nb_cmde: 0,
            nb_cmde_non_livr: 0,
            nb_cmde_attente: 0,
            duree_moy_livr: 0,
            taux_livr: 0,
            liste_region: [
                { label: 'Toutes les regions', value: 'Tous' },
                { label: 'Boke', value: 'Boke' },
                { label: 'Conakry', value: 'Conakry' },
                { label: 'Faranah', value: 'Faranah' },
                { label: 'Kankan', value: 'Kankan' },
                { label: 'Kindia', value: 'Kindia' },
                { label: 'Labe', value: 'Labe' },
                { label: 'Mamou', value: 'Mamou' },
                { label: 'Nzérkoré', value: 'Nzérkoré' }
            ],
            liste_famille: [
                { label: 'Toutes les familles', value: 'Tous' },
                { label: 'Antirétroviraux', value: 'Antirétroviraux' },
                { label: 'Parapharmaceutique', value: 'Parapharmaceutique' },
                { label: 'Antibiotique', value: 'Antibiotique' },
                { label: 'Généric', value: 'Généric' },
                { label: 'Outil', value: 'Outil' },
                { label: 'Autre', value: 'Autre' }
            ],
            familleArticle: { label: 'Toutes les familles', value: 'Tous' },
            region: { label: 'Toutes les regions', value: 'Tous' },
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            showindicator: false,
            visible_region: false,


            classe_table_top_livr: 'p-button-primary',
            classe_chart_top_livr: 'p-button-secondary',
            visible_table_top_livr: true,
            visible_chart_top_livr: false,

            classe_table_down_livr: 'p-button-secondary',
            classe_chart_down_livr: 'p-button-primary',
            visible_table_down_livr: false,
            visible_chart_down_livr: true,

            classe_table_top_cmde_prod: 'p-button-primary',
            classe_chart_top_cmde_prod: 'p-button-secondary',
            visible_table_top_cmde_prod: true,
            visible_chart_top_cmde_prod: false,

            visible_dlg_top_prod_site: false,


        };
        this.show_dlg_top_prod = this.show_dlg_top_prod.bind(this);
        this.myservice = new Dhosservice();
    }


    show_dlg_top_prod(data) {
        this.myservice.get_liste_st_top_cmde_prod_site(this.state.region.value, data.id_article).then(data => this.setState({ liste_st_top_cmde_prod_site: data }, () => {
            this.setState({ visible_dlg_top_prod_site: true });
        }));
    }


    charger_dashboard(id_region) {
        this.setState({ showindicator: true });
        this.myservice.get_dashboard(id_region).then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_cmde_site: data.liste_cmde_site });
                this.setState({ liste_st_cmde: data.liste_st_cmde });
                this.setState({ liste_st_top_prod_all: data.liste_st_top_prod },()=>{
                    if(this.state.liste_st_top_prod_all!==null && this.state.liste_st_top_prod_all!==undefined){
                        let liste=this.state.liste_st_top_prod_all.slice(0, 10);
                        this.setState({liste_st_top_prod: liste});
                    }
                });
                this.setState({ nb_cmde: data.nb_cmde });
                this.setState({ nb_cmde_non_livr: data.nb_cmde_non_livr });
                this.setState({ nb_cmde_attente: data.nb_cmde_attente });
                this.setState({ duree_moy_livr: data.duree_moy_livr });
                this.setState({ taux_livr: data.taux_livr });
                this.setState({ liste_st_top_livr: data.liste_st_top_livr });
                this.setState({ liste_st_down_livr: data.liste_st_down_livr });
                //: data.liste_st_top_cmde_prod });
                this.setState({ liste_st_cmde_annee: data.liste_st_cmde_annee });
                this.setState({ liste_st_top_cmde: data.liste_st_top_cmde });


            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
        this.charger_dashboard(this.state.region.value);
    }



    actionTemplate_top_prod(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.show_dlg_top_prod(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-search" /></div>
        </div>
    }

    render() {
        let liste_label = null;
        let liste_ds_cmde_a = null;
        let liste_ds_cmde_a1 = null;
        if (this.state.liste_st_cmde_annee != null && this.state.liste_st_cmde_annee != undefined) {
            if (this.state.liste_st_cmde_annee.length > 0) {
                liste_label = this.state.liste_st_cmde_annee.map(x => {
                    return x.date_cmde
                });
                liste_ds_cmde_a = this.state.liste_st_cmde_annee.map(x => {
                    return x.nb_cmde_a
                });
                liste_ds_cmde_a1 = this.state.liste_st_cmde_annee.map(x => {
                    return x.nb_cmde_a1
                });
            }
        }

        const chart_data_st_cmde = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Commandes année en cours',
                    backgroundColor: '#4071a7',
                    borderColor: '#4071a7',
                    fill: false,
                    data: liste_ds_cmde_a
                },
                {
                    label: 'Commandes année précédante',
                    backgroundColor: '#d87a3c',
                    borderColor: '#d87a3c',
                    fill: false,
                    data: liste_ds_cmde_a1
                }
            ]
        };

        let liste_label_top_prod = null;
        let liste_ds_top_prod = null;
        let liste_ds_top_prod_cmde = null;

        let liste_label_top_livr = null;
        let liste_ds_top_livr = null;

        let liste_label_down_livr = null;
        let liste_ds_down_livr = null;

        let liste_label_top_cmde_prod = null;
        let liste_ds_top_cmde_prod = null;

        if (this.state.liste_st_top_prod != null && this.state.liste_st_top_prod != undefined) {
            if (this.state.liste_st_top_prod.length > 0) {
                liste_label_top_prod = this.state.liste_st_top_prod.map(x => {
                    return x.lib_article
                });

                liste_ds_top_prod = this.state.liste_st_top_prod.map(x => {
                    return x.qte_approuve_lcmde
                });
                liste_ds_top_prod_cmde = this.state.liste_st_top_prod.map(x => {
                    return x.qte_dmde_lcmde
                });
            }
        }

        if (this.state.liste_st_top_livr != null && this.state.liste_st_top_livr != undefined) {
            if (this.state.liste_st_top_livr.length > 0) {
                liste_label_top_livr = this.state.liste_st_top_livr.map(x => {
                    return x.code_site
                });

                liste_ds_top_livr = this.state.liste_st_top_livr.map(x => {
                    return x.nbj_livr
                });
            }
        }

        if (this.state.liste_st_down_livr != null && this.state.liste_st_down_livr != undefined) {
            if (this.state.liste_st_down_livr.length > 0) {
                liste_label_down_livr = this.state.liste_st_down_livr.map(x => {
                    return x.code_site
                });

                liste_ds_down_livr = this.state.liste_st_down_livr.map(x => {
                    return x.nbj_livr
                });
            }
        }


        if (this.state.liste_st_top_cmde != null && this.state.liste_st_top_cmde != undefined) {
            if (this.state.liste_st_top_cmde.length > 0) {
                liste_label_top_cmde_prod = this.state.liste_st_top_cmde.map(x => {
                    return x.code_site
                });

                liste_ds_top_cmde_prod = this.state.liste_st_top_cmde.map(x => {
                    return x.nb_cmde
                });
            }
        }

        const chart_top_cmde_prod = {
            labels: liste_label_top_cmde_prod,
            datasets: [
                {
                    label: 'Top 5 commandes',
                    backgroundColor: '#4071a7',
                    data: liste_ds_top_cmde_prod
                }
            ]
        };

        const chart_top_livr = {
            labels: liste_label_top_livr,
            datasets: [
                {
                    label: 'Top 5 livraison',
                    backgroundColor: '#4071a7',
                    data: liste_ds_top_livr
                }
            ]
        };

        const chart_down_livr = {
            labels: liste_label_down_livr,
            datasets: [
                {
                    label: 'Down 5 livraison',
                    backgroundColor: '#4071a7',
                    data: liste_ds_down_livr
                }
            ]
        };


        const chart_hdata = {
            labels: liste_label_top_prod,
            datasets: [
                {
                    label: 'Quantitées validées',
                    backgroundColor: '#4071a7',
                    data: liste_ds_top_prod
                }
                /*  ,
                 {
                     label: 'Commandées',
                     backgroundColor: '#4071a7',
                     data: liste_ds_top_prod_cmde
                 } */
            ]
        };


        const header_top_livr = <div style={{ marginTop: '4px' }}>

            <label>TOP 5 DURÉE LIVRAISON </label>

            <Button icon="pi pi-chart-bar" className={this.state.classe_chart_top_livr} onClick={() => {
                this.setState({ visible_chart_top_livr: true });
                this.setState({ visible_table_top_livr: false });
                this.setState({ classe_chart_top_livr: 'p-button-primary' });
                this.setState({ classe_table_top_livr: 'p-button-secondary' });
            }} style={{width: 40}}/>

            <Button icon="pi pi-table" className={this.state.classe_table_top_livr} onClick={() => {
                this.setState({ visible_chart_top_livr: false });
                this.setState({ visible_table_top_livr: true });
                this.setState({ classe_chart_top_livr: 'p-button-secondary' });
                this.setState({ classe_table_top_livr: 'p-button-primary' });
            }} style={{width: 40}}/>

        </div>;

        const header_down_livr = <div style={{ marginTop: '4px' }}>

            <label>DOWN 5 DURÉE LIVRAISON </label>

            <Button icon="pi pi-chart-bar" className={this.state.classe_chart_down_livr} onClick={() => {
                this.setState({ visible_chart_down_livr: true });
                this.setState({ visible_table_down_livr: false });
                this.setState({ classe_chart_down_livr: 'p-button-primary' });
                this.setState({ classe_table_down_livr: 'p-button-secondary' });
            }} style={{width: 40}}/>

            <Button icon="pi pi-table" className={this.state.classe_table_down_livr} onClick={() => {
                this.setState({ visible_chart_down_livr: false });
                this.setState({ visible_table_down_livr: true });
                this.setState({ classe_chart_down_livr: 'p-button-secondary' });
                this.setState({ classe_table_down_livr: 'p-button-primary' });
            }} style={{width: 40}}/>

        </div>;

        const header_top_cmde_prod = <div style={{ marginTop: '4px' }}>

            <label>TOP 5 COMMANDE </label>

            <Button icon="pi pi-chart-bar" className={this.state.classe_chart_top_cmde_prod} onClick={() => {
                this.setState({ visible_chart_top_cmde_prod: true });
                this.setState({ visible_table_top_cmde_prod: false });
                this.setState({ classe_chart_top_cmde_prod: 'p-button-primary' });
                this.setState({ classe_table_top_cmde_prod: 'p-button-secondary' });
            }} style={{width: 40}}/>

            <Button icon="pi pi-table" className={this.state.classe_table_top_cmde_prod} onClick={() => {
                this.setState({ visible_chart_top_cmde_prod: false });
                this.setState({ visible_table_top_cmde_prod: true });
                this.setState({ classe_chart_top_cmde_prod: 'p-button-secondary' });
                this.setState({ classe_table_top_cmde_prod: 'p-button-primary' });
            }} style={{width: 40}}/>

        </div>;

        const options = {
            center: { lat: 9.9308337, lng: -13.7044954 },
            zoom: 12
        };



        return <div className="p-grid dashboard" style={{ marginTop: -22 }}>
            {this.state.selectedProd !== null && this.state.selectedProd !== undefined ?
                <Dialog header="Détails par site " visible={this.state.visible_dlg_top_prod_site} style={{ width: '50%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_top_prod_site: false })} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400 }}>

                                <div className="card card-w-title" style={{ marginBottom: -10, backgroundColor: '#ccff66' }}>
                                    <div className="p-grid" >
                                        <span >
                                            <label style={{ fontSize: '16px', color: '#000', fontWeight: 'bold' }} htmlFor="inPu">{this.state.selectedProd.lib_article}</label>
                                        </span>
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_st_top_cmde_prod_site} scrollable={true} scrollHeight="350px"
                                    responsive={true} tyle={{ marginBottom: 12 }} >
                                    <Column field="nom_site" header="Site" style={{ width: '50%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="region_site" header="Région" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="qte_approuve_lcmde" header="QVAL" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="qte_dmde_lcmde" header="QAC" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
                : null}
            <div className="p-col-12" >
                <div className="p-grid" style={{ float: "right", width: 300 }}>
                    <div className="p-col">
                        <div className="p-inputgroup">
                            <Dropdown placeholder="Toutes les regions" style={{ width: 200, marginBottom: -8 }} value={this.state.region} options={this.state.liste_region} onChange={(e) => {
                                this.setState({ region: e.value }, () => {
                                    this.charger_dashboard(e.value);
                                });
                            }
                            } />
                            <Button type="button" style={{ marginBottom: -8,width: 40 }} icon="pi pi-refresh" className="p-button-info" onClick={() => this.charger_dashboard(this.state.region.value)} />
                        </div>
                    </div>
                    <div className="p-col">
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>
            </div>

            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-1"><h1>TOTAL COMMANDE</h1>
                    <div className="overview-value">{this.state.nb_cmde}</div>
                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-2">
                    <h1>COMMANDES NON LIVRÉES</h1>
                    <div className="overview-value">{this.state.nb_cmde_non_livr}</div>
                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-3">
                    <h1>COMMANDES EN ATTENTE</h1>
                    <div className="overview-value">{this.state.nb_cmde_attente}</div>
                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-4">
                    <h1>DURÉE MOYENNE LIVRAISON</h1>
                    <div className="overview-value">{this.state.duree_moy_livr} J</div>
                    <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12">
                <Panel header="TOP/DAWN 5 " className="circle-panel">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4 p-md-6">
                            <div className="status-title" style={{ color: '#FC5146' }}>{header_top_livr}</div>
                            <ScrollPanel style={{ width: '100%', height: '250px' }}>
                                {this.state.visible_chart_top_livr ? <Chart type="bar" data={chart_top_livr} height="130w" /> : <DataTable value={this.state.liste_st_top_livr} scrollable={true} scrollHeight="180px"
                                    responsive={true} >
                                    <Column field="code_site" header="Code" style={{ width: '15%' }} />
                                    <Column field="nom_site" header="Site" style={{ width: '50%' }} />
                                    <Column field="region_site" header="Region" style={{ width: '20%' }} />
                                    <Column field="nbj_livr" header="Moy." style={{ width: '15%' }} />
                                </DataTable>
                                }
                            </ScrollPanel>
                        </div>
                        <div className="p-col-12 p-lg-4 p-md-6">
                            <div className="status-title" style={{ color: '#6ebc3b' }}>{header_down_livr}</div>
                            <ScrollPanel style={{ width: '100%', height: '250px' }}>
                                {this.state.visible_chart_down_livr ? <Chart type="bar" data={chart_down_livr} height="130w" /> : <DataTable value={this.state.liste_st_down_livr} scrollable={true} scrollHeight="180px"
                                    responsive={true} >
                                    <Column field="code_site" header="Code" style={{ width: '15%' }} />
                                    <Column field="nom_site" header="Site" style={{ width: '50%' }} />
                                    <Column field="region_site" header="Region" style={{ width: '20%' }} />
                                    <Column field="nbj_livr" header="Moy." style={{ width: '15%' }} />
                                </DataTable>
                                }
                            </ScrollPanel>
                        </div>

                        <div className="p-col-12 p-lg-4 p-md-6">
                            <div className="status-title" style={{ color: '#000000' }}>{header_top_cmde_prod}</div>
                            <ScrollPanel style={{ width: '100%', height: '250px' }}>
                                {this.state.visible_chart_top_cmde_prod ? <Chart type="bar" data={chart_top_cmde_prod} height="130w" /> : <DataTable value={this.state.liste_st_top_cmde} scrollable={true} scrollHeight="180px"
                                    responsive={true} >
                                    <Column field="code_site" header="Code" style={{ width: '15%' }} />                                        
                                    <Column field="nom_site" header="Site" style={{ width: '50%' }} />
                                    <Column field="region_site" header="Region" style={{ width: '20%' }} />
                                    <Column field="nb_cmde" header="Nb" style={{ width: '15%' }} />
                                </DataTable>
                                }

                            </ScrollPanel>

                        </div>
                    </div>
                </Panel>
            </div>


            <div className="p-col-12 p-md-6 p-lg-3 task-list">
                <Panel header="DURÉE MOYENNE DE LIVRAISON" style={{ minHeight: '415px' }}>
                    <RadialGauge
                        units='%'
                        title='Durée moyenne de livraison (J)'
                        value={this.state.duree_moy_livr}
                        minValue={0}
                        maxValue={100}
                        colorValueBoxShadow={false}
                        highlights={[
                            { from: 50, to: 400, color: 'rgba(255,0,0,.60)' },
                            { from: 30, to: 50, color: 'rgba(255,255,0,.60)' },
                            { from: 0, to: 30, color: 'rgba(0,128,0,.60)' }
                        ]}
                        majorTicks={['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100']}
                        minorTicks={2}
                    />
                </Panel>
            </div>

            <div className="p-col-12 p-md-6 p-lg-9 p-fluid contact-form">
                <Panel header="SITUATION DES COMMANDES (ANNÉE EN COURS)" style={{ minHeight: '415px' }}>
                    <DataTable value={this.state.liste_cmde_site} scrollable={true} scrollHeight="320px"
                        responsive={true} >
                        <Column field="ordre" header="#" style={{ width: '6%' }} />
                        <Column field="nom_site" header="Site"  filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="region_site" header="Région" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="nb_cmde" header="Total" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="nb_attente" header="En attente" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="nb_livr" header="livré" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="nb_non_livr" header="Non livré" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="nbj_livr" header="Moy. livr." style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    </DataTable>
                </Panel>
            </div>

            <div className="p-col-12">
                <Panel >
                    <div className="p-grid" style={{ width: 400 }}>
                        <div className="p-col-12 p-md-4">
                            <label >Choisir le groupe </label>
                        </div>
                        <div className="p-col-12 p-md-8">
                            <Dropdown options={this.state.liste_famille} value={this.state.familleArticle} style={{ width: '200px' }} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ familleArticle: e.value },()=>{
                                if(this.state.liste_st_top_prod_all!==null && this.state.liste_st_top_prod_all!==undefined){
                                    let selectedfamille=this.state.familleArticle.value;
                                    let liste_prod =null;
                                    if(selectedfamille!=='Tous'){
                                        liste_prod = this.state.liste_st_top_prod_all.filter(function (el) {
                                            return el.famille_article == selectedfamille;
                                        });
                                        if(liste_prod!==null && liste_prod!==undefined){
                                            let liste=liste_prod.slice(0, 10);
                                            this.setState({liste_st_top_prod: liste});
                                        }
                                    }else{
                                        liste_prod=this.state.liste_st_top_prod_all.slice(0, 10);
                                        this.setState({liste_st_top_prod: liste_prod});
                                    }
                                    
                                    
                                }
                            })} autoWidth={false} />
                        </div>
                    </div>


                    <div className="p-grid">
                        <div className="p-col-12 p-md-6 p-lg-6 task-list">
                            <Panel header="TOP 10 PRODUITS COMMANDÉS" style={{ minHeight: '215px' }}>
                                <Chart type="horizontalBar" data={chart_hdata} height="180w" />
                            </Panel>
                        </div>

                        <div className="p-col-12 p-md-6 p-lg-6 p-fluid contact-form">
                            <Panel header="TOP 10 PRODUITS COMMANDÉS" style={{ minHeight: '215px' }}>
                                <DataTable value={this.state.liste_st_top_prod} scrollable={true} scrollHeight="280px"
                                    responsive={true} onRowClick={e => this.setState({ selectedProd: e.data })} selection={this.state.selectedProd} selectionMode="single">
                                    <Column header="#" body={this.actionTemplate_top_prod.bind(this)} style={{ width: '7%' }} />
                                    <Column field="ordre" header="No" style={{ width: '7%' }} />
                                    <Column field="lib_article" header="Désignation" style={{ width: '48%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="qte_dmde_lcmde" header="QAC" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="qte_approuve_lcmde" header="QVAL" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="prc_qte_dmde_lcmde" header="Prc" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </Panel>
                        </div>
                    </div>
                </Panel>
            </div>
            <div className="p-col-12">
                <Panel header="STATISTIQUES DES COMMANDES PAR ANNÉE" className="no-pad">
                    <div className="content-section implementation">
                        <Chart type="line" data={chart_data_st_cmde} height="80w" />
                    </div>
                </Panel>
            </div>


        </div>
    }
}