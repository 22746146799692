import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";

class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	verif_acces(profil, page) {
		page = page.replace("/", "");

		let liste_acces = [''];
		if (profil == 'Administrateur') {
			liste_acces = ['sample', 'changer_mot_de_passe', 'rapprochement_stock', 'sortie', 'transfert', 'Form_inventaire', 'sample', 'produits', 'users', 'regimes', 'sites', 'file_active', 'depots', 'commandes', 'edition_commandes', 'situation_commandes', 'commandes_produits', 'stat_commandes/mois', 'stat_commandes/trimestre', '/', 'dashboard', 'inventaire', 'arrivage', 'commandes/liste', 'commandes/Nouvelle commande'];
		} else if (profil == 'Approbateur') {
			liste_acces = ['sample', 'changer_mot_de_passe', 'rapprochement_stock', 'sortie', 'transfert', 'Form_inventaire', 'sample', 'commandes', 'edition_commandes', 'situation_commandes', 'commandes_produits', 'stat_commandes/mois', 'stat_commandes/trimestre', '/', 'dashboard', 'inventaire', 'arrivage', 'commandes/liste', 'commandes/Nouvelle commande'];
		} else if (profil == 'Consultation') {
			liste_acces = ['changer_mot_de_passe', 'rapprochement_stock', 'edition_commandes', 'situation_commandes', 'commandes_produits', 'stat_commandes', '/', 'dashboard', 'inventaire', 'stat_commandes/trimestre'];
		} else if (profil == 'Responsabe site/Agent de saisie') {
			liste_acces = ['changer_mot_de_passe', 'edition_commandes', 'sortie', 'transfert', 'Form_inventaire', 'situation_commandes', 'commandes_produits', 'stat_commandes', '/', 'dashboard', 'inventaire', 'commandes', 'arrivage', 'commandes/liste', 'commandes/Nouvelle commande', 'stat_commandes/trimestre', 'stat_commandes/mois', 'stat_commandes/trimestre'];
		}
		var ind = parseInt(liste_acces.indexOf(page));
		if (ind < 0) {
			window.location = "#/login";
		}
	}

	render() {
		switch (this.props.location.pathname) {
			case "/":
				return <Route path="/" exact component={Login} />
			case "/login":
				return <Route path="/login" component={Login} />
			case "/error":
				return <Route path="/error" component={Error} />
			case "/notfound":
				return <Route path="/notfound" component={NotFound} />
			case "/access":
				return <Route path="/access" component={Access} />
			default:
				let key = localStorage.getItem('userconnected');
				if (key == null) {
					window.location = "#/login";
					return <Route path="/login" component={Login} />
				} else {
					let user = JSON.parse(key);
					if (user == null && user == undefined) {
						window.location = "#/login";
					} else {
						this.verif_acces(user.profilUser, this.props.location.pathname);
					}
					return <App />;
				}
		}
	}
}

export default withRouter(AppWrapper);