import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';

export class Form_regime extends Component {
    constructor() {
        super();
        this.state = {
            idRegime: 0,
            libRegime: '',
            idLregime: 0,
            idCompo: 0,
            qteArticleCompo: 3,
            nbJour: 30,
            idArticle: null,
            titre_dlg_compo: 'Composition régime',
            codeLregime: '',
            descLregime: '',
            refLregime: '',
            liste_regime: [],
            liste_lregime: [],
            liste_cas: [],
            liste_compo: [],
            liste_article: [],
            categorieRegime: { label: 'Adulte', value: 'Adulte' },
            liste_categorie: [
                { label: 'Adulte', value: 'Adulte' },
                { label: 'Enfant', value: 'Enfant' }
            ],
            selectedRegime: null,
            selectedCompo: null,
            selectedLregime: null,
            msg: '',
            modif: false,
            modif_lregime: false,
            modif_compo: false,
            visible: false,
            visible_lregime: false,
            visible_compo: false,
            showindicator: false,
            visible_dlg_compo: false,
            selectedCas: null,
            idCas: 0,
            libCas: ''
        };

        this.edit_regime = this.edit_regime.bind(this);
        this.conf_delete_regime = this.conf_delete_regime.bind(this);

        this.edit_lregime = this.edit_lregime.bind(this);
        this.conf_delete_lregime = this.conf_delete_lregime.bind(this);

        this.edit_compo = this.edit_compo.bind(this);
        this.conf_delete_compo = this.conf_delete_compo.bind(this);

        this.show_dlg_compo = this.show_dlg_compo.bind(this);

        this.delete_cas = this.delete_cas.bind(this);

        this.myservice = new Dhosservice();
    }

    componentDidMount() {
        this.get_liste_regime();
        //this.get_liste_all_lregime();
        this.get_liste_article();
    }

    get_liste_regime() {
        this.myservice.get_liste_regime().then(data => this.setState({ liste_regime: data }));
    }

    get_liste_all_lregime() {
         this.myservice.get_liste_all_lregime().then(data => this.setState({ liste_lregime: data }));
     }

    get_liste_lregime(idRegime) {
        this.myservice.get_liste_lregime(idRegime).then(data => this.setState({ liste_lregime: data }));
    }

    get_liste_compo(idCas) {
        this.myservice.get_liste_compo(idCas).then(data => this.setState({ liste_compo: data }));
    }

    get_liste_cas(idLregime) {
        this.myservice.get_liste_cas(idLregime).then(data => this.setState({ liste_cas: data }));
    }

    get_liste_article() {
        this.myservice.get_liste_article().then(data => this.setState({ liste_article: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_regime();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    resultat_cas(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_cas(this.state.selectedLregime.idLregime);
            this.setState({libCas: ''});
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    resultat_lregime(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_lregime();
            this.get_liste_all_lregime();
            //this.get_liste_lregime(this.state.selectedRegime.idRegime);
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    resultat_compo(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_compo();
            this.get_liste_compo(this.state.selectedCas.idCas);
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_regime(data) {
        if (data != null && data != undefined) {
            this.setState({ idRegime: data.idRegime });
            this.setState({ libRegime: data.libRegime });
            this.setState({ categorieRegime: { value: data.categorieRegime, label: data.categorieRegime } });
            this.setState({ modif: true });
            this.setState({ selectedRegime: data });
            window.scrollTo(0, 0);
        }
    }

    edit_lregime(data) {
        if (data != null && data != undefined) {
            this.setState({ idLregime: data.idLregime });
            this.setState({ codeLregime: data.codeLregime });
            this.setState({ descLregime: data.descLregime });
            this.setState({ modif_lregime: true });
            this.setState({ selectedLregime: data });
            this.setState({ refLregime: data.refLregime });
            this.setState({ selectedRegime: data.idRegime });
            window.scrollTo(0, 0);
        }
    }

    edit_compo(data) {
        if (data != null && data != undefined) {
            this.setState({ idCompo: data.idCompo });
            this.setState({ idArticle: { value: data.idArticle.idArticle, label: data.idArticle.libArticle+' [ '+data.idArticle.codeArticle+ ' ]' } });
            this.setState({ nbJour: data.nbJour });
            this.setState({ qteArticleCompo: data.qteArticleCompo });
            this.setState({ modif_compo: true });
            this.setState({ selectedCompo: data });
            window.scrollTo(0, 0);
        }
    }


    annule_regime() {
        this.setState({ idRegime: 0 });
        this.setState({ libRegime: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annule_lregime() {
        this.setState({ idLregime: 0 });
        this.setState({ codeLregime: '' });
        this.setState({ descLregime: '' });
        this.setState({ refLregime: '' });
        this.setState({ modif_lregime: false });
        this.forceUpdate();
    }

    annule_compo() {
        this.setState({ idCompo: 0 });
        this.setState({ nbJour: '' });
        this.setState({ qteArticleCompo: '' });
        this.setState({ modif_compo: false });
        this.forceUpdate();
    }

    enreg_regime() {
        if (this.state.libRegime == "" || this.state.categorieRegime == null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let data = { libRegime: this.state.libRegime, categorieRegime: this.state.categorieRegime.value };
            if (this.state.modif == false) {
                data.idRegime = 0;
                this.myservice.enreg_regime(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                data.idRegime = this.state.idRegime;
                this.myservice.enreg_regime(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_lregime() {
        //|| this.state.selectedRegime == null || this.state.selectedRegime == undefined
        if (this.state.codeLregime == "" || this.state.codeLregime == null ) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner un régime' });
        } else {
            let data = { codeLregime: this.state.codeLregime, descLregime: this.state.descLregime, refLregime: this.state.refLregime, idRegime: this.state.selectedRegime };
            if (this.state.modif_lregime == false) {
                data.idLregime = 0;
                this.myservice.enreg_lregime(data).then(data => this.resultat_lregime(data.code, data.contenu));
            } else {
                data.idLregime = this.state.idLregime;
                this.myservice.enreg_lregime(data).then(data => this.resultat_lregime(data.code, data.contenu));
            }
        }
    }

    enreg_compo() {
        if (this.state.idArticle == "" || this.state.idArticle == null || this.state.qteArticleCompo == "" || this.state.qteArticleCompo == null || this.state.nbJour == "" || this.state.nbJour == null || this.state.selectedCas == null || this.state.selectedCas == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let data = { idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label }, nbJour: this.state.nbJour, qteArticleCompo: this.state.qteArticleCompo, idCas: this.state.selectedCas };
            if (this.state.modif_compo == false) {
                data.idCompo = 0;
                this.myservice.enreg_compo(data).then(data => this.resultat_compo(data.code, data.contenu));
            } else {
                data.idCompo = this.state.idCompo;
                this.myservice.enreg_compo(data).then(data => this.resultat_compo(data.code, data.contenu));
            }
        }
    }

    enreg_cas() {
        if (this.state.libCas == "" || this.state.libCas == null || this.state.libCas == undefined || this.state.selectedLregime == null || this.state.selectedLregime == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let data = { idCas: 0, libCas: this.state.libCas, idLregime: this.state.selectedLregime };
            this.myservice.enreg_cas(data).then(data => this.resultat_cas(data.code, data.contenu));

        }
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_regime(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_regime(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_lregime(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.edit_lregime(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_lregime(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.show_dlg_compo(rowData)} className="p-button-info" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-sitemap" /></div>
        </div>
    }

    actionTemplate_compo(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_compo(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_compo(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_cas(rowData, column) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button onClick={(e) => this.delete_cas(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_regime() {
        if (this.state.selectedRegime == null || this.state.selectedRegime == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne ‡ supprimer' });
        } else {
            this.myservice.delete_regime(this.state.selectedRegime).then(data => this.resultat(data.code, data.contenu));
        }
    }
    delete_lregime() {
        if (this.state.selectedLregime == null || this.state.selectedLregime == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_lregime(this.state.selectedLregime).then(data => this.resultat_lregime(data.code, data.contenu));
        }
    }

    delete_cas() {
        if (this.state.selectedCas == null || this.state.selectedCas == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_cas(this.state.selectedCas).then(data => this.resultat_cas(data.code, data.contenu));
        }
    }


    delete_compo() {
        if (this.state.selectedCompo == null || this.state.selectedCompo == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_compo(this.state.selectedCompo).then(data => this.resultat_compo(data.code, data.contenu));
        }
    }

    conf_delete_regime(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedRegime: data });
            this.setState({ msg: data.libRegime + ' ' + data.categorieRegime });
            this.setState({ visible: true });
        }
    }

    conf_delete_lregime(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLregime: data });
            this.setState({ msg: data.codeLregime });
            this.setState({ visible_lregime: true });
        }
    }

    conf_delete_compo(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedCompo: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_compo: true });
        }
    }

    show_dlg_compo(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLregime: data });
            this.setState({ visible_dlg_compo: true });
            this.get_liste_cas(data.idLregime);
            this.setState({liste_compo: []});
            this.setState({ selectedCas: null });
            this.setState({ titre_dlg_compo: 'Composition régime [ ' + data.codeLregime + ' ' + this.state.selectedRegime.categorieRegime + ']' });
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_regime();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_lregime = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_lregime: false });
                    this.delete_lregime();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_lregime: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_compo = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_compo: false });
                    this.delete_compo();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_compo: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_dlg_compo = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_compo: false })} label="Fermer" className="p-button-secondary" />
            </div>
        );

        let lprod = this.state.liste_article.map(x => {
            return { value: x.idArticle, label: x.libArticle+' [ '+x.codeArticle+ ' ]' }
        });

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_lregime} style={{ width: '250px' }} modal={true} footer={dialogFooter_lregime} onHide={(e) => this.setState({ visible_lregime: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_compo} style={{ width: '250px' }} modal={true} footer={dialogFooter_compo} onHide={(e) => this.setState({ visible_compo: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header={this.state.titre_dlg_compo} visible={this.state.visible_dlg_compo} style={{ width: '80%' }} modal={true} footer={dialogFooter_dlg_compo} onHide={(e) => this.setState({ visible_dlg_compo: false })}>

                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 400 }}>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-8">
                                                <InputText placeholder="Désignation cas *" value={this.state.libCas} onChange={(e) => this.setState({ libCas: e.target.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <Button label="Ajouter" icon="pi pi-check" onClick={(e) => this.enreg_cas()} />
                                            </div>
                                        </div>
                                        <DataTable value={this.state.liste_cas} selectionMode="single" scrollable={true} scrollHeight="300px"
                                            onRowClick={e => this.setState({ selectedCas: e.data })} onRowSelect={e =>
                                                this.setState({ selectedCas: e.data }, () => {
                                                    this.get_liste_compo(this.state.selectedCas.idCas);
                                                })
                                            }
                                            responsive={true} selection={this.state.selectedCas} >
                                            <Column header="#" body={this.actionTemplate_cas.bind(this)} style={{ width: '15%' }} />
                                            <Column field="libCas" header="Cas" style={{ width: '85%' }} />
                                        </DataTable>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 400 }}>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-1">
                                                <label htmlFor="profile">Produit *</label>
                                            </div>
                                            <div className="p-col-12 p-md-5">
                                                <Dropdown options={lprod} value={this.state.idArticle} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idArticle: e.value })} autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <label htmlFor="profile">Nb jour *</label>
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <InputText  keyfilter="pint" value={this.state.nbJour} onChange={(e) => this.setState({ nbJour: e.target.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <label htmlFor="profile">Quantité *</label>
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <InputText  keyfilter="pnum" value={this.state.qteArticleCompo} onChange={(e) => this.setState({ qteArticleCompo: e.target.value })} />
                                            </div>
                                        </div>
                                        <center>
                                            <div className="p-grid" style={{ width: 300 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_compo()} /></div>
                                                <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_compo()} /></div>
                                            </div>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </center>

                                        <DataTable value={this.state.liste_compo} selectionMode="single" scrollable={true} scrollHeight="200"
                                            onRowClick={e => this.setState({ selectedCompo: e.data })} onRowSelect={e => this.setState({ selectedCompo: e.data })}
                                            responsive={true} selection={this.state.selectedCompo} >
                                            <Column header="#" body={this.actionTemplate_compo.bind(this)} style={{ width: '15%' }} />
                                            <Column field="idArticle.libArticle" header="Produit" style={{ width: '55%' }} />
                                            <Column field="nbJour" header="NB Jour" style={{ width: '15%' }} />
                                            <Column field="qteArticleCompo" header="Quantité" style={{ width: '15%' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </Dialog>




                <div className="p-grid">
                    <div className="p-col-12 p-lg-5">
                        <div className="p-grid">
                            <div className="p-col-12">

                                <div className="card card-w-title" style={{ height: 650 }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <label >Désignation *</label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <InputText value={this.state.libRegime} onChange={(e) => this.setState({ libRegime: e.target.value })} />
                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <label htmlFor="profile">Classe age *</label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <Dropdown options={this.state.liste_categorie} value={this.state.categorieRegime} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ categorieRegime: e.value })} autoWidth={false} />
                                        </div>

                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_regime()} /></div>
                                            <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_regime()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                    <DataTable value={this.state.liste_regime} selectionMode="single" scrollable={true} scrollHeight="400"
                                        onRowClick={e => this.setState({ selectedRegime: e.data })} onRowSelect={e =>
                                            this.setState({ selectedRegime: e.data }, () => {
                                                this.get_liste_lregime(this.state.selectedRegime.idRegime);
                                            })
                                        }
                                        responsive={true} selection={this.state.selectedRegime} >
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '20%' }} />
                                        <Column field="libRegime" header="Désignation" style={{ width: '50%' }} />
                                        <Column field="categorieRegime" header="Classe age" style={{ width: '30%' }} />
                                    </DataTable>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-7">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 650 }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <label >Code *</label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <InputText value={this.state.codeLregime} onChange={(e) => this.setState({ codeLregime: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <label htmlFor="profile">Code DHIS2 </label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <InputText value={this.state.refLregime} onChange={(e) => this.setState({ refLregime: e.target.value })} />
                                        </div>
                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_lregime()} /></div>
                                            <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_lregime()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>
                                    <DataTable value={this.state.liste_lregime} selectionMode="single" scrollable={true} scrollHeight="460px"
                                        onRowClick={e => this.setState({ selectedLregime: e.data })} onRowSelect={e => this.setState({ selectedLregime: e.data })}
                                        responsive={true} selection={this.state.selectedLregime} >
                                        <Column header="#" body={this.actionTemplate_lregime.bind(this)} style={{ width: '20%' }} />
                                        <Column field="codeLregime" header="Code" style={{ width: '20%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="refLregime" header="Code DHIS2" style={{ width: '20%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="idRegime.libRegime" header="Régime" style={{ width: '20%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="idRegime.categorieRegime" header="Catégorie" style={{ width: '20%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}