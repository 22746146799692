import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';


export class Form_inventaire extends Component {
    constructor() {
        super();
        this.state = {
            liste_inv: [],
            liste_depot: [],
            selected_ins: [],
            visible_dlg: false,
            visible_dlg_conf: false,
            file: null,
            idInv: 0,
            codeInv: '',
            dateInv: new Date(),
            qteInv: 0,
            idArticle: null,
            idDepot: null,
            ridDepot: null,
            rcode_inv: '',
            obsInv: '',
            bailleurInv: '',
            numLoInv: '',
            uniteInv: '',
            moisInv: { value: '01', label: 'Janvier' },
            anneeInv: { value: moment(new Date()).format('YYYY'), label: moment(new Date()).format('YYYY') },
            liste_article: [],
            pd1: new Date(),
            pd2: new Date(),
            liste_mois: [
                { label: 'Janvier', value: '01' },
                { label: 'Février', value: '02' },
                { label: 'Mars', value: '03' },
                { label: 'Avril', value: '04' },
                { label: 'Mai', value: '05' },
                { label: 'Juin', value: '06' },
                { label: 'Juillet', value: '07' },
                { label: 'Aout', value: '08' },
                { label: 'Septembre', value: '09' },
                { label: 'Octobre', value: '10' },
                { label: 'Novembre', value: '11' },
                { label: 'Décembre', value: '12' },
                { label: 'T1', value: 'T1' },
                { label: 'T2', value: 'T2' },
                { label: 'T3', value: 'T3' },
                { label: 'T4', value: 'T4' }
            ],
            liste_annee: [],
            disable_supr: true,
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Dhosservice();
        this.upload_inventaire = this.upload_inventaire.bind(this);
        this.onChange = this.onChange.bind(this);
        this.upload_bulk = this.upload_bulk.bind(this);
        this.show_dlg = this.show_dlg.bind(this);
        this.conf_delete_inv = this.conf_delete_inv.bind(this);
        this.generate_annee();
    }


    generate_annee() {
        var numbers = [];
        let annee = parseInt(moment(new Date()).format('YYYY'));
        for (var i = 2016; i <= annee; i++) {
            numbers.push({ label: i + '', value: i + '' });
        }
        this.state.liste_annee = numbers;
    }

    static readUploadedFileAsText = inputFile => {
        const temporaryFileReader = new FileReader();
        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsText(inputFile);
        });
    };

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_inv();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    upload_inventaire() {
        this.upload_bulk();
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    get_liste_article() {
        this.myservice.get_liste_article().then(data => this.setState({ liste_article: data }));
    }
    get_liste_depot() {
        this.myservice.get_liste_depot().then(data => this.setState({ liste_depot: data }));
    }

    get_liste_inv() {
        let rid_depot = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridDepot != undefined || this.state.ridDepot != null) {
            rid_depot = this.state.ridDepot.value;
        }
        this.myservice.get_liste_invetaire_by_param(spd1, spd2, this.state.rcode_inv, rid_depot).then(data => this.setState({ liste_inv: data }));
    }

    componentDidMount() {
        this.get_liste_article();
        this.get_liste_depot();
    }

    get_article_bycode(code, lib) {
        let article = null;
        try {
            article = this.state.liste_article.filter(function (el) {
                return el.idArticle == code || el.libArticle == lib;
            })[0];
        } catch (e) {
            console.log(e)
        }
        return article;
    }

    upload_bulk = async () => {
        if (this.state.file.type.match(/text\/csv/) || this.state.file.type.match(/vnd\.ms-excel/)) {
            try {
                const csv = await Form_inventaire.readUploadedFileAsText(this.state.file);
                var lines = csv.split("\n");
                var result = [];
                Array.prototype.insert = function (index) {
                    this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
                };
                let headers = lines[0].split(";");
                if (headers.length < 7) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le fichier choisi ne respecte pas le format attendu' });
                    return;
                }
                for (var i = 1; i < lines.length; i++) {
                    var currentline = lines[i].split(";");
                    if (currentline[0] == null || currentline[0] == '' || currentline[0] == undefined) {
                        break;
                    }
                    var date = '';
                    try {
                        var date_format = moment(currentline[4], 'DD/MM/YYYY');
                        date = moment(date_format).format('YYYY-MM-DD');
                    } catch (error) {
                        //alert(error);
                        date = '';
                    }
                    var article = this.get_article_bycode(currentline[0], currentline[1]);
                    if (article === undefined || article === null) {
                        article = { idArticle: currentline[0], libArticle: currentline[1], codeArticle: currentline[0], refArticle: currentline[0], uniteArticle: currentline[2],seuilArticle: 0, statutArticle: 'Utilisé',cibleArticle: 'Inconnu',familleArticle: 'Inconnu',cbArticle: currentline[0], puRefAchatArticle: 0  }
                    }
                    if (date == 'Invalid date') {
                        //this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Mauvais format de date à la ligne ' + (i + 1) });
                        // return;
                        date = '';
                    }
                    var obj = {
                        codeInv: this.state.moisInv.value + '/' + this.state.anneeInv.value,
                        dateInv: moment(new Date()).format('YYYY-MM-DD'),
                        qteInv: parseInt(currentline[5]),
                        iduser: this.state.userconnected.idUser,
                        idArticle: article,
                        idDepot: { idDepot: this.state.idDepot.value, nomDepot: this.state.idDepot.label },
                        obsInv: currentline[7],
                        bailleurInv: currentline[6],
                        numLoInv: currentline[3],
                        uniteInv: currentline[2],
                        datePeremptionInv: date
                    };
                    result.insert(i, obj);
                }
                this.setState({ liste_inv: [] }, () => {
                    if (result.length > 0) {
                        this.myservice.enreg_inventaire_batch(result).then(data => {
                            this.resultat(data.code, data.contenu)
                        }
                        );
                        this.forceUpdate();
                    } else {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Aucune données enregistrée, merci de vérifier le contenu du fichier" });
                    }
                });
            } catch (e) {
                alert(e);
            }
        } else {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Le fichier choisi n'est pas un fichier CSV" });
            return;
        }
    }

    show_dlg() {
        this.setState({ visible_dlg: true });
    }

    conf_delete_inv(data) {
        if (data !== null && data !== undefined) {
            if (this.state.selected_ins.length > 0) {
                this.setState({ msg: data.length });
                this.setState({ visible_dlg_conf: true });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez rechercher et sélectionner les lignes à supprimer" });
            }
        } else {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez sélectionner les lignes à supprimer" });
        }
    }

    delete_inv() {
        if (this.state.selected_ins == null || this.state.selected_ins == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.myservice.delete_inventaire_batch(this.state.selected_ins).then(data => this.resultat(data.code, data.contenu));
        }
    }


    render() {
        let ldepot = null;
        if (this.state.liste_depot != null && this.state.liste_depot != undefined) {
            ldepot = this.state.liste_depot.map(x => {
                return { value: x.idDepot, label: x.nomDepot }
            });
        }

        var header = <div style={{ textAlign: 'left' }}>
            <div className="p-grid" style={{ width: 300 }}>
                <div className="p-col"><Button type="button" icon="pi pi-upload" iconPos="left" label="Chargement" style={{ width: 120 }} onClick={this.show_dlg}></Button></div>
                <div className="p-col"><Button label="Supprimer" disabled={this.state.disable_supr} icon="pi pi-times" onClick={(e) => this.conf_delete_inv(this.state.selected_ins)} style={{ width: 120, }} /></div>
            </div>
        </div>;

        const dialogFooter_inv = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_dlg_conf: false });
                    this.delete_inv();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_conf: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter = (
            <div>
                <Button icon="pi pi-search" onClick={() => {
                    this.setState({ visible_dlg: false });
                }} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-search" onClick={() => {
                    this.upload_inventaire();
                    this.setState({ visible_dlg: false });
                }} label="Charger" className="p-button-primary" />
            </div>
        );

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible_dlg_conf} style={{ width: '250px' }} modal={true} footer={dialogFooter_inv} onHide={(e) => this.setState({ visible_dlg_conf: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer les <b>{this.state.msg} ligne(s) selectionné(es) </b>?
                    </div>
                </Dialog>

                <Dialog header="Chargement" visible={this.state.visible_dlg} style={{ width: '400px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible_dlg: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Mois / trimestre *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_mois} value={this.state.moisInv} optionLabel='label' onChange={(e) => this.setState({ moisInv: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Année *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_annee} value={this.state.anneeInv} optionLabel='label' onChange={(e) => this.setState({ anneeInv: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Dépot *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={ldepot} placeholder="Dépot" value={this.state.idDepot} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idDepot: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Fichier CSV *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <input type="file" onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 1300 }}>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <InputText value={this.state.rcode_inv} placeholder="Code" onChange={(e) => this.setState({ rcode_inv: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={ldepot} placeholder="Dépot" value={this.state.ridDepot} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridDepot: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_inv()} />
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_inv} header={header}
                                responsive={true} paginatorPosition="top" paginator={true} rows={22}
                                selection={this.state.selected_ins} onSelectionChange={e => {
                                    this.setState({ selected_ins: e.value }, () => {
                                        if (this.state.selected_ins.length > 0) {
                                            this.setState({ disable_supr: false });
                                        } else {
                                            this.setState({ disable_supr: true });
                                        }
                                    });
                                }}>
                                <Column selectionMode="multiple" style={{ width: '4%' }} />
                                <Column field="dateInv" header="Date" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="codeInv" header="Periode" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="idArticle.libArticle" header="Produit" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '27%' }} />
                                <Column field="idDepot.nomDepot" header="Dépot" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="qteInv" header="Qté" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                <Column field="uniteInv" header="Unité" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="datePeremptionInv" header="Peremption" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '9%' }} />
                                <Column field="numLoInv" header="N° lot" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="bailleurInv" header="Bailleur" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}