import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class Dhosservice {
    constructor() {
        this.state = {
            //wsurl: 'http://localhost:8787/dhos',
            wsurl: 'https://opt-api224.com/ws_dhos/dhos',
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
    }

    get_userconnected() {
        return this.state.userconnected;
    }

    //Gestion des cas
    delete_cas(data) {
        var lien = this.state.wsurl + '/cas/delete/' + data.idCas;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_cas(data) {
        var lien = '';
        var methode = 'POST';
        lien = this.state.wsurl + '/cas/add';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_cas(id) {
        var lien = this.state.wsurl + '/cas/get_by_ligne/' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            // alert(error)
            return error
        })
    }


    // Gestion des articles
    delete_article(data) {
        var lien = this.state.wsurl + '/articles/delete/' + data.idArticle;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_article(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idArticle == '0') {
            lien = this.state.wsurl + '/articles/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/articles/update/' + data.idArticle;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_article() {
        var lien = this.state.wsurl + '/articles/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Gestion des régimes
    delete_regime(data) {
        var lien = this.state.wsurl + '/regimes/delete/' + data.idRegime;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_regime(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idRegime == 0) {
            lien = this.state.wsurl + '/regimes/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/regimes/update/' + data.idRegime;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_regime() {
        var lien = this.state.wsurl + '/regimes/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Gestion des utilisateurs
    delete_user(data) {
        var lien = this.state.wsurl + '/users/delete/' + data.idUser;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_user(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idUser == '0') {
            lien = this.state.wsurl + '/users/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/users/update/' + data.idUser;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_users() {
        var lien = this.state.wsurl + '/users/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    // Gestion des sites
    get_VIH_organisationUnit() {
        var lien = 'https://entrepot.sante.gov.gn/dhis/api/30/organisationUnitGroups/C8CafxRWifh.json';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                //'Host': 'dhis2.sante.gov.gn',
                'Authorization': 'Basic a2FsaWwuY29uZGU6Sm9ic0AxMzA3'
                //'Authorization': 'Basic base64encode(kalil.conde:Jobs@1307)'
                //'Cache-Control': 'no-cache'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_Global_organisationUnit() {
        var lien = 'https://entrepot.sante.gov.gn/dhis/api/30/organisationUnits.json?fields=:all&paging=false';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic a2FsaWwuY29uZGU6Sm9ic0AxMzA3'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_site(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idSite == '0') {
            lien = this.state.wsurl + '/sites/add';
            data.idSite = moment(new Date()).format('DDMMYYYYhmmss');
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/sites/update/' + data.idSite;
        }

        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_site(data) {
        var lien = this.state.wsurl + '/sites/delete/' + data.idSite;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_site_batch(data) {
        var lien = this.state.wsurl + '/sites/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_sites() {
        var lien = this.state.wsurl + '/sites/get_liste_site/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    // Gestion de Gerer site
    enreg_gerer_site_bulk(data) {
        var lien = this.state.wsurl + '/gerer_site/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    enreg_gerer_site(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idGererSite == 0) {
            lien = this.state.wsurl + '/gerer_site/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/gerer_site/update/' + data.idGererSite;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_gerer_site() {
        var lien = this.state.wsurl + '/gerer_site/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_gerer_site(data) {
        var lien = this.state.wsurl + '/gerer_site/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_gerer_site_user(idUser) {
        var lien = this.state.wsurl + '/gerer_site/get_site_users/' + idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    // Gestion ligne regime
    get_liste_all_lregime() {
        var lien = this.state.wsurl + '/ligne_regime/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_lregime(idRegime) {
        var lien = this.state.wsurl + '/ligne_regime/get_by_regime/' + idRegime;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_lregime(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idLregime == 0) {
            lien = this.state.wsurl + '/ligne_regime/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/ligne_regime/update/' + data.idLregime;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_lregime(data) {
        var lien = this.state.wsurl + '/ligne_regime/delete/' + data.idLregime;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Gestion compo regime
    get_liste_compo(idCas) {
        var lien = this.state.wsurl + '/compo_regime/get_by_cas/' + idCas;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_compo(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCompo == 0) {
            lien = this.state.wsurl + '/compo_regime/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/compo_regime/update/' + data.idCompo;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_compo(data) {
        var lien = this.state.wsurl + '/compo_regime/delete/' + data.idCompo;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Gestion regime patient

    enreg_regime_patient_batch(data) {
        var lien = this.state.wsurl + '/regime_patient/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_suivi_patients_dataValues(period, orgUnit) {
        var lien = 'https://entrepot.sante.gov.gn/dhis/api/30/dataValueSets?fields=:all&dataSet=lDvKWRd5Bhb&period=' + period + '&orgUnit=' + orgUnit;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic a2FsaWwuY29uZGU6Sm9ic0AxMzA3'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    enreg_regime_patient(data) {
        var lien = this.state.wsurl + '/regime_patient/add';
        var methode = 'POST';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    delete_regime_patient(data) {
        var lien = this.state.wsurl + '/regime_patient/delete/' + data.idRegimePat;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    refresh_regime_patient() {
        var lien = this.state.wsurl + '/regime_patient/refresh_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_regime_patient() {
        var lien = this.state.wsurl + '/regime_patient/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_regime_patient_site(idSite) {
        var lien = this.state.wsurl + '/regime_patient/find_by_site/' + idSite;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Gestion depot site
    get_liste_depot_site(idDepot) {
        var lien = this.state.wsurl + '/depot_site/get_sites_depot/' + idDepot;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_depot_site(data) {
        var lien = this.state.wsurl + '/depot_site/add';
        var methode = 'POST';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_depot_site_batch(data) {
        var lien = this.state.wsurl + '/depot_site/add_batch';
        var methode = 'POST';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_depot_site(data) {
        var lien = this.state.wsurl + '/depot_site/delete/' + data.idDepsite;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    // Gestion depot 
    get_liste_depot() {
        var lien = this.state.wsurl + '/depot/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_depot(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idDepot == 0) {
            lien = this.state.wsurl + '/depot/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/depot/update/' + data.idDepot;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_depot(data) {
        var lien = this.state.wsurl + '/depot/delete/' + data.idDepot;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Gestion inventaire 
    get_liste_invetaire() {
        var lien = this.state.wsurl + '/inventaire/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_invetaire_by_param(pd1, pd2, pcode, piddepot) {
        if (pcode == '') {
            pcode = 'Tous';
        }
        var lien = this.state.wsurl + '/inventaire/get_all/' + pd1 + '/' + pd2 + '/' + pcode + '/' + piddepot + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_inventaire(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCompo == 0) {
            lien = this.state.wsurl + '/inventaire/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/inventaire/update/' + data.idInv;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_inventaire_batch(data) {
        var lien = this.state.wsurl + '/inventaire/add_batch/' + this.state.userconnected.idUser;
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    delete_inventaire(data) {
        var lien = this.state.wsurl + '/inventaire/delete/' + data.idInv;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_inventaire_batch(data) {
        var lien = this.state.wsurl + '/inventaire/delete_batch/' + this.state.userconnected.idUser;
        var methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }



    // Gestion ligne commande 

    get_liste_lcmde_for_validation(idCmde, idSite) {
        var lien = this.state.wsurl + '/ligne_cmde/get_lignes_cmde_for_validation/' + idCmde + '/' + idSite + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_lcmde(idCmde) {
        var lien = this.state.wsurl + '/ligne_cmde/get_ligne_by_cmde/' + idCmde;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_lcmde(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idLcmde == 0) {
            lien = this.state.wsurl + '/ligne_cmde/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/ligne_cmde/update/' + data.idLcmde;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_lcmde_batch(data) {
        var lien = this.state.wsurl + '/ligne_cmde/add_batch';
        var methode = 'POST';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_livraison(data) {
        var lien = this.state.wsurl + '/ligne_cmde/livrer';
        var methode = 'PUT';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_lcmde(data) {
        var lien = this.state.wsurl + '/ligne_cmde/delete/' + data.idLcmde;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    // Gestion commande 
    get_liste_cmde_by_criteria(pd1, pd2, rnumcmde, ridsite, retat, rstatut) {
        if (rnumcmde == '') {
            rnumcmde = 'Tous';
        }
        var lien = this.state.wsurl + '/commande/get_by_criteria/' + pd1 + '/' + pd2 + '/' + rnumcmde + '/' + ridsite + '/' + retat + '/' + rstatut + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_cmde() {
        var lien = this.state.wsurl + '/commande/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    valider_cmde(data) {
        let methode = 'PUT';
        let lien = this.state.wsurl + '/ligne_cmde/valider_cmde';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_cmde(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCmde == 0) {
            data.idUser = this.state.userconnected;
            lien = this.state.wsurl + '/commande/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/commande/update/' + data.idCmde;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_etat_statut_cmde(data, etat) {
        var lien = '';
        var methode = 'PUT';
        if (etat == true) {
            lien = this.state.wsurl + '/commande/update_statut/' + data.idCmde;
        } else {
            data.idUserUtilisateur = this.state.userconnected;
            lien = this.state.wsurl + '/commande/update_etat/' + data.idCmde;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    delete_cmde(data) {
        var lien = this.state.wsurl + '/commande/delete/' + data.idCmde;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    //Gestion des traces
    get_liste_trace(refTrace, cibleTrace) {
        var lien = this.state.wsurl + '/traces/get_trace_byref/' + refTrace + '/' + cibleTrace;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    //Gestion des etats

    get_liste_st_top_cmde_prod_site(rregion, ridprod) {
        var lien = this.state.wsurl + '/etats/liste_st_top_commande_prod_site_annee/' + rregion + '/' + ridprod + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_edition_liste_cmde(pd1, pd2, rnumcmde, rregion, rpref, rcrd, ridsite, retat, rstatut) {
        if (rnumcmde == '') {
            rnumcmde = 'Tous';
        }
        var lien = this.state.wsurl + '/etats/liste_cmde_site/' + pd1 + '/' + pd2 + '/' + rnumcmde + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + ridsite + '/' + retat + '/' + rstatut + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    edition_liste_cmde(pd1, pd2, rnumcmde, rregion, rpref, rcrd, ridsite, retat, rstatut, format) {
        if (rnumcmde == '') {
            rnumcmde = 'Tous';
        }
        var lien = this.state.wsurl + '/etats/generer_etat_liste_cmde_site/' + pd1 + '/' + pd2 + '/' + rnumcmde + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + ridsite + '/' + retat + '/' + rstatut + '/' + format + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }


    get_edition_situation_cmde(pd1, pd2, rregion, rpref, rcrd) {
        var lien = this.state.wsurl + '/etats/liste_total_cmde_site/' + pd1 + '/' + pd2 + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_edition_situation_cmde(pd1, pd2, rregion, rpref, rcrd, format) {
        var lien = this.state.wsurl + '/etats/generer_liste_total_cmde_site/' + pd1 + '/' + pd2 + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + format + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }


    generer_edition_bs(idArv) {
        var lien = this.state.wsurl + '/etats/generer_bs/' + idArv + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_edition_br(idArv) {
        var lien = this.state.wsurl + '/etats/generer_br/' + idArv + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_edition_bs_peremption(idArv) {
        var lien = this.state.wsurl + '/etats/generer_bs_peremption/' + idArv + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }


    generer_edition_bc(idCmde) {
        var lien = this.state.wsurl + '/etats/generer_bc/' + idCmde + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_edition_liste_cmde_prod(pd1, pd2, rregion, rpref, rcrd, ridsite, rdepot, format) {
        var lien = this.state.wsurl + '/etats/generer_liste_cmde_prod/' + pd1 + '/' + pd2 + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + ridsite + '/' + rdepot + '/' + format + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }
    generer_edition_cmde_st_cmde(pd1, pd2, rregion, rpref, rcrd, ridsite, periode, format) {
        var lien = this.state.wsurl + '/etats/generer_liste_st_commande/' + pd1 + '/' + pd2 + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + ridsite + '/' + periode + '/' + format + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_plan_appro_depot(pd1, pd2) {
        var lien = this.state.wsurl + '/etats/generer_plan_appro_depot?pd1=' + pd1 + '&pd2=' + pd2 + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }
    generer_plan_appro_site(pd1, pd2, rregion, rpref, rcrd, ridsite) {
        var lien = this.state.wsurl + '/etats/generer_plan_appro_site?pd1=' + pd1 + '&pd2=' + pd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&ridsite=' + ridsite + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_plan_appro_global(pd1, pd2, rdepot) {
        var lien = this.state.wsurl + '/etats/generer_plan_appro_global?pd1=' + pd1 + '&pd2=' + pd2 + '&rdepot=' + rdepot + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    get_edition_cmde_prod(pd1, pd2, rregion, rpref, rcrd, ridsite, rdepot) {
        var lien = this.state.wsurl + '/etats/liste_cmde_prod/' + pd1 + '/' + pd2 + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + ridsite + '/' + rdepot + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    get_edition_cmde_st_cmde(pd1, pd2, rregion, rpref, rcrd, ridsite, rperiode) {
        var lien = this.state.wsurl + '/etats/liste_st_commande/' + pd1 + '/' + pd2 + '/' + rregion + '/' + rpref + '/' + rcrd + '/' + ridsite + '/' + rperiode + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    get_liste_article_moyenne(ridsite) {
        var lien = this.state.wsurl + '/etats/liste_article/' + ridsite + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_article_depot(ridsite) {
        var lien = this.state.wsurl + '/etats/liste_article/' + ridsite + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    get_edition_rapprochement_inventaire(piddepot) {
        var lien = this.state.wsurl + '/etats/get_rapprochement_inventaire/' + piddepot + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_edition_rapprochement_inventaire(piddepot, format) {
        var lien = this.state.wsurl + '/etats/generer_rapprochement_inventaire/' + piddepot + '/' + format + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }



    get_edition_inventaire(piddepot) {
        var lien = this.state.wsurl + '/etats/get_inventaire/' + piddepot + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_edition_inventaire(piddepot, format) {
        var lien = this.state.wsurl + '/etats/generer_inventaire/' + piddepot + '/' + format + '/' + this.state.userconnected.idUser;
        window.location = lien;
    }


    get_identification(login, pwd) {
        var lien = this.state.wsurl + '/users/get_identification/' + login + '/' + pwd;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }





    get_dashboard(region) {
        var lien = this.state.wsurl + '/etats/get_dashboard/' + region + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    // Gestion arrivage 
    get_liste_arrivage_by_criteria(pd1, pd2, rnum_arv, rid_depot_srce, rid_depot_dest, retat_arv, rlib_arv) {
        if (rnum_arv == '') {
            rnum_arv = 'Tous';
        }
        var lien = this.state.wsurl + '/arrivage/get_by_criteria/' + pd1 + '/' + pd2 + '/' + rnum_arv + '/' + rid_depot_srce + '/' + rid_depot_dest + '/' + retat_arv + '/' + rlib_arv + '/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_arrivage(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idArv == 0) {
            data.idUser = this.state.userconnected;
            lien = this.state.wsurl + '/arrivage/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/arrivage/update/' + data.idArv;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_arrivage(data) {
        console.log(JSON.stringify(data))
        var lien = this.state.wsurl + '/arrivage/delete/' + data.idArv;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            }
            // ,body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Gestion ligne arrivage 

    get_liste_larv(idArv) {
        var lien = this.state.wsurl + '/ligne_arrivage/get_ligne_by_arv/' + idArv;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    enreg_larv(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idLarv == 0) {
            lien = this.state.wsurl + '/ligne_arrivage/add';
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/ligne_arrivage/update/' + data.idLarv;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_larv_batch(data) {
        var lien = this.state.wsurl + '/ligne_arrivage/add_batch';
        var methode = 'POST';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    delete_larv(data) {
        var lien = this.state.wsurl + '/ligne_arrivage/delete/' + data.idLarv;
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    // Info de base
    get_liste_prefecture() {
        var lien = this.state.wsurl + '/etats/liste_prefecture';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_crd() {
        var lien = this.state.wsurl + '/etats/liste_crd';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_fournisseur_transitaire() {
        var lien = this.state.wsurl + '/etats/liste_fournisseur_transitaire/' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    download_fichier_joint(namefile) {
        var lien = this.state.wsurl + '/utils/download_attachment?namefile=' + namefile + '&iduser=' + this.get_userconnected().idUser;
        window.location = lien;
    }

    download_fichier(namefile) {
        var lien = this.state.wsurl + '/utils/download_file?namefile=' + namefile + '&iduser=' + this.get_userconnected().idUser;
        return lien
    }

    upload_file(file, namefile) {
        var methode = 'POST';
        var lien = this.state.wsurl + '/utils/upload_file';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('namefile', namefile);
        formData.append('iduser', this.get_userconnected().idUser);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


}