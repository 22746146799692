import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class Form_regime_patient extends Component {
    constructor() {
        super();
        this.state = {
            liste_site: [],
            liste_site_refresh: [],
            liste_lregime: [],
            idRegimePat: 0,
            codeMajRegime: '',
            nbPatientRegime: 0,
            idLregime: null,
            selectedLigne: null,
            idSite: null,
            liste_all_lregime: [],
            idSite_refresh: null,
            moisRefresh: null,
            anneeRefresh: null,
            modif: false,
            visible: false,
            msg: '',
            liste_mois: [
                { label: 'Janvier', value: '01' },
                { label: 'Février', value: '02' },
                { label: 'Mars', value: '03' },
                { label: 'Avril', value: '04' },
                { label: 'Mai', value: '05' },
                { label: 'Juin', value: '06' },
                { label: 'Juilleit', value: '07' },
                { label: 'Aout', value: '08' },
                { label: 'Septembre', value: '09' },
                { label: 'Octobre', value: '10' },
                { label: 'Novembre', value: '11' },
                { label: 'Décembre', value: '12' }
            ],
            liste_annee: [],
            liste_regime_patient: [],
            showindicator: false,
            visible_dlg: false,
            visible_dlg_chargement: false,
            file: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };
        this.myservice = new Dhosservice();

        this.onChange = this.onChange.bind(this);
        this.upload_bulk = this.upload_bulk.bind(this);
        this.show_dlg = this.show_dlg.bind(this);

        this.edit_ligne = this.edit_ligne.bind(this);
        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);
        this.show_dlg_chargement = this.show_dlg_chargement.bind(this);
        this.generate_annee();
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    generate_annee() {
        var numbers = [];
        let annee = parseInt(moment(new Date()).format('YYYY'));
        for (var i = 2016; i <= annee; i++) {
            numbers.push({ label: i + '', value: i + '' });
        }
        this.state.liste_annee = numbers;
        this.state.codeMajRegime = moment(new Date()).format('MM') + '/' + moment(new Date()).format('YYYY');
    }

    static readUploadedFileAsText = inputFile => {
        const temporaryFileReader = new FileReader();
        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsText(inputFile);
        });
    };

    get_regime_patient(idLregime, idSite) {
        let val = null;
        try {
            val = this.state.liste_regime_patient.filter(function (el) {
                return el.idLregime.idLregime == idLregime && el.idSite.idSite == idSite;
            })[0];
        } catch (e) {
            console.log(e)
        }
        return val;
    }

    upload_bulk = async () => {
        if (this.state.moisRefresh == null || this.state.moisRefresh == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veillez choisir le mois' });
        } else if (this.state.anneeRefresh == null || this.state.anneeRefresh == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Veillez choisir l'année" });
        } else if (this.state.idSite_refresh == null || this.state.idSite_refresh == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Veillez choisir le site" });
        } else if (this.state.file.type.match(/text\/csv/) || this.state.file.type.match(/vnd\.ms-excel/)) {
            try {
                const csv = await Form_regime_patient.readUploadedFileAsText(this.state.file);
                var lines = csv.split("\n");
                var result = [];
                Array.prototype.insert = function (index) {
                    this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
                };
                let headers = lines[0].split(";");
                if (headers.length < 3) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le fichier choisi ne respecte pas le format attendu' });
                    return;
                }
                let periode = this.state.moisRefresh.value + '/' + this.state.anneeRefresh.value;
                for (var i = 1; i < lines.length; i++) {
                    var currentline = lines[i].split(";");
                    if (currentline[0] == null || currentline[0] == '' || currentline[0] == undefined) {
                        break;
                    }
                    let vallregime = null;
                    try {
                        if (parseFloat(currentline[1]) > 0) {
                            let lval = this.state.liste_lregime.filter(function (el) {
                                return el.idLregime.refLregime == currentline[0] && el.idLregime.idRegime.categorieRegime == 'Adulte';
                            });
                            if (lval !== null && lval !== undefined) {
                                vallregime = lval[0];
                                var obj = null;
                                let regimepatient = this.get_regime_patient(vallregime.idLregime, this.state.idSite_refresh.idSite);
                                if (regimepatient !== null && regimepatient !== undefined) {
                                    obj = regimepatient;
                                    obj.nbPatientRegime = parseInt(currentline[1]);
                                    obj.codeMajRegime = periode;
                                    obj.iduser = this.state.userconnected.idUser;
                                } else {
                                    obj = {
                                        nbPatientRegime: parseInt(currentline[1]),
                                        codeMajRegime: periode,
                                        iduser: this.state.userconnected.idUser,
                                        idLregime: vallregime,
                                        idSite: { idSite: this.state.idSite.value, nomSite: this.state.idSite.label }
                                    };
                                }
                                if (obj !== null) {
                                    result.insert(i, obj);
                                }

                            }
                        }
                        if (parseFloat(currentline[2]) > 0) {
                            let lval = this.state.liste_lregime.filter(function (el) {
                                return el.idLregime.refLregime == currentline[0] && el.idLregime.idRegime.categorieRegime == 'Enfant';
                            });
                            if (lval !== null && lval !== undefined) {
                                vallregime = lval[0];
                                var obj = null;
                                let regimepatient = this.get_regime_patient(vallregime.idLregime, this.state.idSite_refresh.idSite);
                                if (regimepatient !== null && regimepatient !== undefined) {
                                    obj = regimepatient;
                                    obj.nbPatientRegime = parseInt(currentline[2]);
                                    obj.codeMajRegime = periode;
                                    obj.iduser = this.state.userconnected.idUser;
                                } else {
                                    obj = {
                                        nbPatientRegime: parseInt(currentline[2]),
                                        codeMajRegime: periode,
                                        iduser: this.state.userconnected.idUser,
                                        idLregime: vallregime,
                                        idSite: { idSite: this.state.idSite.value, nomSite: this.state.idSite.label }
                                    };
                                }
                                if (obj !== null) {
                                    result.insert(i, obj);
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.setState({ liste_regime_patient: [] }, () => {
                    if (result.length > 0) {
                        this.myservice.enreg_regime_patient_batch(result).then(data => {
                            this.resultat(data.code, data.contenu)
                        }
                        );
                        this.forceUpdate();
                    } else {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Aucune données enregistrée, merci de vérifier le contenu du fichier" });
                    }
                });
            } catch (e) {
                alert(e);
            }
        } else {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Le fichier choisi n'est pas un fichier CSV" });
            return;
        }
    }



    componentDidMount() {
        this.get_liste_lregime();
        this.get_liste_site();
        this.get_liste_regime_patient();
    }

    get_liste_site() {
        this.myservice.get_liste_sites().then(data => {
            if (data != null && data != undefined) {
                if (data.length > 0) {
                    let lsite_rech = [{ value: 'Tous', label: 'Tous les sites' }];
                    let lsite = data.map(x => {
                        return { value: x.idSite, label: x.nomSite + '/' + x.regionSite }
                    });
                    lsite_rech = lsite_rech.concat(lsite);
                    this.setState({ liste_site: lsite });
                    this.setState({ liste_site_refresh: lsite_rech });
                }
            }
        });
    }

    get_liste_lregime() {
        this.myservice.get_liste_all_lregime().then(data => this.setState({ liste_lregime: data, liste_all_lregime: data }));
    }



    get_liste_regime_patient() {
        this.myservice.get_liste_regime_patient().then(data => this.setState({ liste_regime_patient: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    refresh_data() {
        if (this.state.idSite_refresh == null || this.state.idSite_refresh == undefined || this.state.moisRefresh == null || this.state.moisRefresh == undefined || this.state.anneeRefresh == null || this.state.anneeRefresh == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let liste_regime_patient_to_save = [];
            let lsite = [];
            if (this.state.idSite_refresh.value == 'Tous') {
                lsite = this.state.liste_site;
            } else {
                lsite.push(this.state.idSite_refresh);
            }
            for (var i = 0; i < lsite.length; i++) {
                let id_site = lsite[i].value;
                this.setState({ showindicator: true });
                let periode = this.state.moisRefresh.value + '/' + this.state.anneeRefresh.value;
                let periode_req = this.state.anneeRefresh.value + '' + this.state.moisRefresh.value;

                this.myservice.get_suivi_patients_dataValues(periode_req, id_site).then(data => {
                    if (data != null && data != undefined) {
                        if (data.orgUnit != undefined) {
                            let dataValues = data.dataValues;
                            if (dataValues != undefined && dataValues != null) {
                                if (dataValues.length > 0) {
                                    liste_regime_patient_to_save = [];
                                    for (var j = 0; j < this.state.liste_all_lregime.length; j++) {
                                        this.setState({ showindicator: true });
                                        if (this.state.liste_all_lregime[j].refLregime != '' && this.state.liste_all_lregime[j].refLregime != null && this.state.liste_all_lregime[j].refLregime != undefined) {
                                            let dataElement = this.state.liste_all_lregime[j].refLregime;
                                            if (this.state.liste_all_lregime[j].idRegime.categorieRegime == 'Enfant') {
                                                let code = 'MCIZLajVtON';
                                                let idrp = 0;
                                                let lregime_value = dataValues.filter(function (el) {
                                                    return el.dataElement == dataElement && el.categoryOptionCombo == code;
                                                });
                                                if (lregime_value != null && lregime_value != undefined) {
                                                    if (lregime_value.length > 0) {

                                                        let id_lregime = this.state.liste_all_lregime[j].idLregime;

                                                        let rp = this.state.liste_regime_patient.filter(function (el) {
                                                            return el.idLregime.idLregime == id_lregime && el.idSite.idSite == id_site;
                                                        })[0];

                                                        if (rp != null && rp != undefined) {
                                                            idrp = rp.idRegimePat;
                                                        }

                                                        let regime = lregime_value[0];
                                                        let data = { idRegimePat: idrp, codeMajRegime: periode, iduser: this.state.userconnected.idUser, idLregime: { idLregime: this.state.liste_all_lregime[j].idLregime }, nbPatientRegime: parseInt(regime.value), idSite: { idSite: id_site } };
                                                        liste_regime_patient_to_save.push(data);
                                                    }
                                                }
                                            } else {
                                                let idrp = 0;
                                                let code = 'MFdh7MLPHvV';
                                                let lregime_value = dataValues.filter(function (el) {
                                                    return el.dataElement == dataElement && el.categoryOptionCombo == code;
                                                });

                                                if (lregime_value != null && lregime_value != undefined) {

                                                    if (lregime_value.length > 0) {

                                                        let id_lregime = this.state.liste_all_lregime[j].idLregime;

                                                        let rp = this.state.liste_regime_patient.filter(function (el) {
                                                            return el.idLregime.idLregime == id_lregime && el.idSite.idSite == id_site;
                                                        })[0];

                                                        if (rp != null && rp != undefined) {
                                                            idrp = rp.idRegimePat;
                                                        }
                                                        let regime = lregime_value[0];
                                                        let data = { idRegimePat: idrp, codeMajRegime: periode, iduser: this.state.userconnected.idUser, idLregime: { idLregime: this.state.liste_all_lregime[j].idLregime }, nbPatientRegime: parseInt(regime.value), idSite: { idSite: id_site } };
                                                        liste_regime_patient_to_save.push(data);
                                                    }
                                                }
                                            }
                                        }
                                        this.setState({ showindicator: false });
                                    }

                                    if (liste_regime_patient_to_save.length > 0) {
                                        this.myservice.enreg_regime_patient_batch(liste_regime_patient_to_save).then(data_save => this.resultat(data_save.code, data_save.contenu));
                                    }

                                }
                            }
                        }
                    }
                    this.setState({ showindicator: false });
                });
            }
        }

    }

    edit_ligne(data) {
        if (data != null && data != undefined) {
            this.setState({ idRegimePat: data.idRegimePat });
            this.setState({ nbPatientRegime: data.nbPatientRegime });
            this.setState({ idSite: { value: data.idSite.idSite, label: data.idSite.nomSite + '/' + data.idSite.regionSite } });
            this.setState({ idLregime: { value: data.idLregime.idLregime, label: data.idLregime.codeLregime + '[ ' + data.idLregime.idRegime.libRegime + ' - ' + data.idLregime.idRegime.categorieRegime + ' ]' } });
            this.setState({ modif: true });
            this.setState({ selectedLigne: data });
            window.scrollTo(0, 0);
        }
    }

    annule_ligne() {
        this.setState({ idRegimePat: 0 });
        this.setState({ nbPatientRegime: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_ligne() {
        if (this.state.codeMajRegime == "" || this.state.codeMajRegime == undefined || this.state.idLregime == "" || this.state.idLregime == undefined || this.state.idLregime == null || this.state.idSite == "" || this.state.idSite == null || this.state.idSite == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let data = { codeMajRegime: this.state.codeMajRegime, idLregime: { idLregime: this.state.idLregime.value }, nbPatientRegime: this.state.nbPatientRegime, idSite: { idSite: this.state.idSite.value, nomSite: this.state.idSite.label } };
            if (this.state.modif == false) {
                data.idRegimePat = 0;
                this.myservice.enreg_regime_patient(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                data.idRegimePat = this.state.idRegimePat;
                this.myservice.enreg_regime_patient(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_ligne(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_ligne() {
        if (this.state.selectedLigne == null || this.state.selectedLigne == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_regime_patient(this.state.selectedLigne).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_ligne(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLigne: data });
            this.setState({ msg: data.idLregime.codeLregime + ' / ' + data.idSite.nomSite });
            this.setState({ visible: true });
        }
    }

    show_dlg() {
        this.setState({ visible_dlg: true });
    }

    show_dlg_chargement() {
        this.setState({ visible_dlg_chargement: true });
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_ligne();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_refresh = (
            <div>
                <Button icon="pi pi-search" onClick={() => {
                    this.setState({ visible_dlg: false });
                }} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-search" onClick={() => {
                    this.refresh_data();
                }} label="Charger" className="p-button-primary" />
            </div>
        );

        var header = <div className="p-grid" style={{ width: 350 }}>
            <div className="p-col"><Button type="button" icon="pi pi-refresh" iconPos="left" label="Refresh" style={{ width: 100 }} onClick={this.show_dlg} /></div>
            <div className="p-col"><Button type="button" icon="pi pi-upload" iconPos="left" label="Chargement" style={{ width: 110, marginLeft: -10 }} className="p-button-warning" onClick={this.show_dlg_chargement} /></div>
            <div className="p-col"><Button type="button" icon="pi pi-print" iconPos="left" label="Exporter" className="p-button-success" style={{ width: 100, marginLeft: -10 }} onClick={(e) => this.dt.exportCSV()} /></div>
        </div>;



        let lsite = null;
        let lligne = null;
        if (this.state.liste_site != null && this.state.liste_site != undefined) {
            lsite = this.state.liste_site.map(x => {
                return { value: x.idSite, label: x.nomSite + '/' + x.regionSite }
            });
        }
        if (this.state.liste_lregime != null && this.state.liste_lregime != undefined) {
            lligne = this.state.liste_lregime.map(x => {
                return { value: x.idLregime, label: x.codeLregime + '[ ' + x.idRegime.libRegime + ' - ' + x.idRegime.categorieRegime + ' ]' }
            });
        }

        const dialogFooter_chargement = (
            <div>
                <Button icon="pi pi-times" onClick={() => {
                    this.setState({ visible_dlg_chargement: false });
                }} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.upload_bulk();
                    this.setState({ visible_dlg_chargement: false });
                }} label="Charger" className="p-button-primary" />
            </div>
        );

        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Chargement des données du DHIS2" visible={this.state.visible_dlg} style={{ width: '400px' }} modal={true} footer={dialogFooter_refresh} onHide={(e) => this.setState({ visible_dlg: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Mois *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_mois} value={this.state.moisRefresh} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ moisRefresh: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Année *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_annee} value={this.state.anneeRefresh} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ anneeRefresh: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Site </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_site_refresh} value={this.state.idSite_refresh} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idSite_refresh: e.value })} autoWidth={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </Dialog>


                <Dialog header="Chargement file active" visible={this.state.visible_dlg_chargement} style={{ width: '400px' }} modal={true} footer={dialogFooter_chargement} onHide={(e) => this.setState({ visible_dlg_chargement: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Mois / trimestre *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_mois} value={this.state.moisRefresh} optionLabel='label' onChange={(e) => this.setState({ moisRefresh: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Année *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_annee} value={this.state.anneeRefresh} optionLabel='label' onChange={(e) => this.setState({ anneeRefresh: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Site *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_site_refresh} value={this.state.idSite_refresh} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idSite_refresh: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Fichier CSV *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <input type="file" onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 1200 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Régime *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lligne} value={this.state.idLregime} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idLregime: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Site *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_site} value={this.state.idSite} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idSite: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Periode *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputMask mask="99/9999" value={this.state.codeMajRegime} onChange={(e) => this.setState({ codeMajRegime: e.target.value })}></InputMask>
                                    {/* <InputText value={this.state.codeMajRegime} keyfilter="pint" onChange={(e) => this.setState({ codeMajRegime: e.target.value })} /> */}
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Nb patient *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText keyfilter="pint" value={this.state.nbPatientRegime} onChange={(e) => this.setState({ nbPatientRegime: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_ligne()} /></div>
                                    <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_ligne()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_regime_patient} header={header}
                                responsive={true} paginatorPosition="top" paginator={true} rows={20} selectionMode="single"
                                onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                selection={this.state.selectedLigne} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="idSite.nomSite" header="Nom site" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '22%' }} />
                                <Column field="idSite.crdSite" header="District" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="idSite.regionSite" header="Région" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="idLregime.idRegime.libRegime" header="Ligne" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="idLregime.idRegime.categorieRegime" header="Classe age" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="idLregime.codeLregime" header="Régime" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="nbPatientRegime" header="Nb patient" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="codeMajRegime" header="Periode" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}