import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dhosservice } from '../service/Dhosservice';
import { ProgressSpinner } from 'primereact/progressspinner';


export default class Login extends Component {
	constructor() {
		super();
		this.state = {
			login: '',
			pwd: '',
			msg: '',
			showindicator: false,

		};
		this.identification = this.identification.bind(this);
		this.myservice = new Dhosservice();
	}

	handleKeyPress = e => {
		if (e.keyCode === 13) {
			this.identification();
		}
	}

	set_liste_pref_crd(){
		this.myservice.get_liste_prefecture().then(data => {
			if (data != null && data != undefined) {
				localStorage.setItem('liste_pref', JSON.stringify(data));
			} 
		});

		this.myservice.get_liste_crd().then(data => {
			if (data != null && data != undefined) {
				localStorage.setItem('liste_crd', JSON.stringify(data));
			} 
		});
	}

	identification() {
		if (this.state.login == '' || this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
		} else { 
			this.setState({ showindicator: true });
			this.myservice.get_identification(this.state.login, this.state.pwd).then(data => {
				if (data != null && data != undefined) {
					if (data.actifUser == 'Oui') {
						localStorage.setItem('userconnected', JSON.stringify(data));
						window.location = "#/dashboard";
						this.set_liste_pref_crd();
						this.setState({ showindicator: false });
					} else if (data.actifUser === 'Expiré') {
						this.setState({ showindicator: false });
						this.setState({ msg: "Votre compte a expiré, merci de contacter l'administrateur " });
					} else {
						this.setState({ showindicator: false });
						this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
					}
				} else {
					this.setState({ showindicator: false });
					this.setState({ msg: 'Login ou mot de passe incorrects' });
				}
			});
		}
	}

	render() {
		return <div className="login-body">
			<div className="body-container">
				<div className="p-grid">
					<div className="p-col-12 p-lg-6 left-side">
						<img src="assets/layout/images/logo_login.png" style={{width: '300px', height: '300px'}} alt="apollo-layout" className="logo" />
						<h1>Bienvenue</h1>
						<p>
							Sur DHOS (District Health Order Ssytem) l'outil de gestion des commandes du PNLSH
						</p>
					</div>
					<div className="p-col-12 p-lg-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Identification</span>
								<div className="p-grid p-fluid">
									<div className="p-col-12">
										<InputText  onKeyDown={this.handleKeyPress} value={this.state.login} onChange={(e) => this.setState({ login: e.target.value })} placeholder="Login" />
									</div>
									<div className="p-col-12">
										<InputText  onKeyDown={this.handleKeyPress} value={this.state.pwd} onChange={(e) => this.setState({ pwd: e.target.value })} type="password" placeholder="Mot de passe" />
									</div>
									<div className="p-col-6">
										<Button label="Connexion" icon="pi pi-sign-in" onClick={() => { this.identification() }} />
										{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px',marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
										<label style={{color: 'red', marginTop: 10}} >{this.state.msg}</label>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}