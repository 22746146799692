import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Menu } from 'primereact/menu';
import { SplitButton } from 'primereact/splitbutton';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';


export class Form_transfert extends Component {
    constructor() {
        super();
        this.state = {
            idArv: 0,
            libArticle: '',
            numFactureArv: '',
            dateReceptionArv: new Date(),
            dateArv: new Date(),
            dateEntreArv: new Date(),
            obsArv: '',
            bailleurArv: '',
            numPvArv: '',
            vppArv: '',
            idLarv: 0,
            qteAnnonceLarv: 1,
            qteRecuLarv: 0,
            numLotLarv: '',
            datePerempLarv: new Date(),
            num_ligne: 0,
            obsLarv: '',
            pd1: new Date(),
            pd2: new Date(),
            etatArv: null,
            retatArv: null,
            rnum_arv: '',
            fournisseurArv: null,
            transitaireArv: null,
            idArticle: null,
            idDepotSrce: null,
            ridDepotSrce: null,
            idDepotDest: null,
            ridDepotDest: null,
            liste_etat: [
                { label: 'Tout etat', value: 'Tous' },
                { label: 'Validé', value: 'Validé' },
                { label: 'En attente', value: 'En attente' }
            ],
            liste_arv: [],
            liste_larv: [],
            liste_transitaire: [],
            liste_fournisseur: [],
            liste_depot: [],
            liste_article: [],
            liste_ligne: [],
            liste_trace: [],
            selectedArv: null,
            selectedLarv: null,
            selectedLigne: null,
            msg: '',
            modif: false,
            modif_larv: false,
            visible: false,
            visible_larv: false,
            visible_new_arv_dlg: false,
            visible_dlg_arv: false,
            visible_dlg_larv: false,
            visible_dlg_trace: false,
            showindicator: false,
            disable_new_arv: true,
            disable_add_prod: true,
            disable_prod: true,
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };


        this.edit_arv = this.edit_arv.bind(this);
        this.conf_delete_arv = this.conf_delete_arv.bind(this);

        this.edit_larv = this.edit_larv.bind(this);
        this.conf_delete_larv = this.conf_delete_larv.bind(this);

        this.show_new_arv_dlg = this.show_new_arv_dlg.bind(this);
        this.show_larv_dlg = this.show_larv_dlg.bind(this);

        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);

        this.numLotLarvEditor = this.numLotLarvEditor.bind(this);
        this.datePerempLarvEditor = this.datePerempLarvEditor.bind(this);
        this.qteAnnonceLarvEditor = this.qteAnnonceLarvEditor.bind(this);
        this.qteRecuLarvEditor = this.qteRecuLarvEditor.bind(this);
        this.obsLarvEditor = this.obsLarvEditor.bind(this);
        this.show_trace = this.show_trace.bind(this);
        this.conf_print_bc = this.conf_print_bc.bind(this);


        this.myservice = new Dhosservice();
    }



    articleTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-9">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.ref}</label>
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label style={{ fontWeight: 'bold' }}>{option.stock}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    numLotLarvEditor(props) {
        return this.inputTextEditor(props, 'numLotLarv');
    }
    datePerempLarvEditor(props) {
        return this.inputTextEditor(props, 'datePerempLarv');
    }

    qteRecuLarvEditor(props) {
        return this.inputTextEditor(props, 'qteRecuLarv');
    }

    qteAnnonceLarvEditor(props) {
        return this.inputTextEditor(props, 'qteAnnonceLarv');
    }

    obsLarvEditor(props) {
        return this.inputTextEditor(props, 'obsLarv');
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }
    componentDidMount() {
        this.get_liste_arv();
        this.get_liste_depot();
        this.get_liste_value();
    }

    get_liste_value() {
        this.myservice.get_liste_fournisseur_transitaire().then(data => {
            if (data != null && data != undefined) {
                if (data.length > 0) {
                    this.setState({ liste_fournisseur: data });
                    let four = data.filter(function (el) {
                        return el.lib == 'Fournisseur';
                    })
                    let transit = data.filter(function (el) {
                        return el.lib == 'Transitaire';
                    })
                    this.setState({ liste_fournisseur: four });
                    this.setState({ liste_transitaire: transit });
                }
            }

        });
    }


    get_liste_arv() {
        this.setState({ showindicator: true });
        let rid_depot_srce = 'Tous';
        let rid_depot_dest = 'Tous';
        let retat_arv = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridDepotSrce != undefined || this.state.ridDepotSrce != null) {
            rid_depot_srce = this.state.ridDepotSrce.value;
        }
        if (this.state.ridDepotDest != undefined || this.state.ridDepotDest != null) {
            rid_depot_dest = this.state.ridDepotDest.value;
        }

        if (this.state.retatArv != undefined || this.state.retatArv != null) {
            retat_arv = this.state.retatArv.value;
        }

        this.myservice.get_liste_arrivage_by_criteria(spd1, spd2, this.state.rnum_arv, rid_depot_srce, rid_depot_dest, retat_arv, 'Transfert').then(data => this.setState({ liste_arv: data, showindicator: false }));
    }

    get_liste_larv(idArv) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_larv(idArv).then(data => this.setState({ liste_larv: data, showindicator: false }));
    }


    get_liste_depot() {
        this.myservice.get_liste_depot().then(data => this.setState({ liste_depot: data }));
    }

    get_liste_article(id) {
        this.myservice.get_liste_article_depot(id).then(data => this.setState({ liste_article: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_arv();
            this.setState({ liste_larv: [] });
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_larv(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_larv();
            if (this.state.selectedArv != null && this.state.selectedArv != undefined) {
                this.get_liste_larv(this.state.selectedArv.idArv);
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    show_trace(rowData) {
        this.setState({ visible_dlg_trace: true });
        this.setState({ showindicator: true });
        this.myservice.get_liste_trace(rowData.idArv, 'Arrivage').then(data => this.setState({ liste_trace: data, showindicator: false }));
    }

    conf_print_bc(data) {
        this.myservice.generer_edition_bs(data.idArv);
    }

    edit_arv(data) {
        if (data != null && data != undefined) {
            if (data.etatArv == 'En attente') {
                this.setState({ idArv: data.idArv });
                this.setState({ obsArv: data.obsArv });
                this.setState({ dateArv: data.dateArv });
                this.setState({ dateEntreArv: data.dateEntreArv });
                this.setState({ numPvArv: data.numPvArv });
                this.setState({ numFactureArv: data.numFactureArv });
                this.setState({ bailleurArv: data.bailleurArv });
                this.setState({ dateReceptionArv: data.dateReceptionArv });
                this.setState({ idDepotSrce: { value: data.idDepotSrce.idDepot, label: data.idDepotSrce.nomDepot } });
                this.setState({ idDepotDest: { value: data.idDepotDest.idDepot, label: data.idDepotDest.nomDepot } });
                this.setState({ etatArv: { value: data.etatArv, label: data.etatArv } });
                this.setState({ transitaireArv: data.transitaireArv });
                this.setState({ fournisseurArv: data.fournisseurArv });
                this.setState({ vppArv: data.vppArv });
                this.setState({ modif: true });
                this.setState({ visible_dlg_arv: true });
                this.setState({ selectedArv: data });
                window.scrollTo(0, 0);
                this.forceUpdate();
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cet arrivage, car déja validé' });
            }
        }
    }

    edit_larv(data) {
        if (data != null && data != undefined) {
            this.setState({ idLarv: data.idLarv });
            this.setState({ qteAnnonceLarv: data.qteAnnonceLarv });
            this.setState({ obsLarv: data.obsLarv });
            this.setState({ qteRecuLarv: data.qteRecuLarv });
            this.setState({ idArticle: { value: data.idArticle.idArticle, label: data.idArticle.libArticle + ' [ ' + data.idArticle.codeArticle + ' ]', stock: 0, code: data.idArticle.codeArticle, ref: data.idArticle.refArticle } });
            this.setState({ modif_larv: true });
            this.setState({ visible_dlg_larv: true });
            this.setState({ disable_prod: true });
            this.setState({ selectedLarv: data });

            this.setState({ numLotLarv: data.numLotLarv });
            this.setState({ datePerempLarv: data.datePerempLarv });

            this.setState({ libArticle: data.idArticle.libArticle });
            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }


    annule_arv() {
        this.setState({ idArv: 0 });
        this.setState({ dateArv: '' });
        this.setState({ dateEntreArv: '' });
        this.setState({ numPvArv: '' });
        this.setState({ obsArv: '' });
        this.setState({ numFactureArv: '' });
        this.setState({ vppArv: '' });
        this.setState({ fournisseurArv: '' });
        this.setState({ transitaireArv: '' });
        this.setState({ modif: false });
        this.setState({ bailleurArv: '' });
        this.setState({ dateReceptionArv: '' });
        this.setState({ visible_dlg_arv: false });
        this.forceUpdate();
    }

    annule_arv_dlg() {
        this.annule_arv();
        this.setState({ liste_ligne: [] });
    }

    annule_larv() {
        this.setState({ idLarv: 0 });
        this.setState({ qteAnnonceLarv: 0 });
        this.setState({ qteRecuLarv: 0 });
        this.setState({ obsLarv: '' });
        this.setState({ modif_larv: false });
        this.setState({ liste_ligne: [] });
        this.setState({ numLotLarv: '' });
        this.setState({ datePerempLarv: '' });
        this.forceUpdate();
    }



    enreg_arv() {
        if (this.state.idDepotSrce == null || this.state.idDepotSrce == undefined || this.state.idDepotDest == "" || this.state.idDepotDest == null || this.state.idDepotDest == undefined || this.state.numPvArv == undefined || this.state.numPvArv == '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let date_reception = moment(this.state.dateReceptionArv).format('YYYY-MM-DD');
            let data = { libArv: 'Transfert', dateReceptionArv: date_reception, bailleurArv: 'NA', fournisseurArv: 'NA', transitaireArv: 'NA', numPvArv: this.state.numPvArv, obsArv: this.state.obsArv, idDepotDest: { idDepot: this.state.idDepotDest.value, nomDepot: this.state.idDepotDest.label }, idDepotSrce: { idDepot: this.state.idDepotSrce.value, nomDepot: this.state.idDepotSrce.label } };
            if (this.state.modif == false) {
                data.idArv = 0;
                data.etatArv = 'En attente';
                if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les produits' });
                    return;
                } else if (this.state.liste_ligne.length <= 0) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les produits' });
                    return;
                } else if (this.state.idDepotSrce.value == this.state.idDepotDest.value) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'La source ne peut etre identique à la destination' });
                    return;
                }
                this.myservice.enreg_arrivage(data).then(data => {
                    if (data != null && data != undefined) {
                        this.resultat(data.code, data.contenu);
                        for (var i = this.state.liste_ligne.length; i--;) {
                            this.state.liste_ligne[i].idArv = { idArv: data.id };
                            this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
                        }
                        this.myservice.enreg_larv_batch(this.state.liste_ligne).then(data => {
                            if (data != null && data != undefined) {
                                this.resultat_larv(data.code, data.contenu);
                            }
                        });
                    }
                });
            } else {
                data.idArv = this.state.idArv;
                data.etatArv = this.state.selectedArv.etatArv;
                this.myservice.enreg_arrivage(data).then(data => this.resultat(data.code, data.contenu));
            }

        }
    }

    update_etat_arv(data) {
        this.myservice.enreg_arrivage(data).then(data => this.resultat(data.code, data.contenu));
    }

    enreg_larv() {
        if (this.state.selectedArv == null || this.state.selectedArv == undefined || this.state.qteRecuLarv == '' || this.state.qteRecuLarv == undefined || this.state.qteAnnonceLarv == '' || this.state.qteAnnonceLarv == undefined || this.state.idArticle == null || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        } else {
            this.setState({ showindicator: true });
            let date_peremption = moment(this.state.datePerempLarv).format('YYYY-MM-DD');
            let data = { idLarv: 0, qteAnnonceLarv: this.state.qteAnnonceLarv, datePerempLarv: date_peremption, numLotLarv: this.state.numLotLarv, qteRecuLarv: this.state.qteRecuLarv, obsLarv: this.state.obsLarv, idArv: this.state.selectedArv, idArticle: { idArticle: this.state.idArticle.value, nomSite: this.state.idArticle.label } };
            if (this.modif_larv == false) {
                for (var i = this.state.liste_larv.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_larv[i].idArticle.idArticle) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Ce produit existe déja dans la commande sélectionnée, vous ne pouvez que modifier sa quantité' });
                        return;
                    }
                }
                this.myservice.enreg_larv(data).then(data => this.resultat_larv(data.code, data.contenu));
            } else {
                data.idLarv = this.state.idLarv;
                this.myservice.enreg_larv(data).then(data => this.resultat_larv(data.code, data.contenu));
            }
        }
    }



    conf_delete_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idArticle.idArticle == this.state.liste_ligne[i].idArticle.idArticle) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    add_article() {
        if (this.state.idDepotDest == null || this.state.idDepotDest == undefined || this.state.datePerempLarv == null || this.state.numLotLarv == undefined || this.state.numLotLarv == '' || this.state.numLotLarv == null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un dépot' });
            return;
        }
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        let trouve = false;

        if (this.state.idArticle == null || this.state.idArticle == '' || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un produit' });
        } else if (this.state.qteAnnonceLarv == null || this.state.qteAnnonceLarv == '' || this.state.qteAnnonceLarv == 0 || this.state.datePerempLarv == '' || this.state.datePerempLarv == null || this.state.datePerempLarv == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
        }
        else {
            let qts = this.state.qteAnnonceLarv;
            let qtc = this.state.qteAnnonceLarv;
            let date_peremption = moment(this.state.datePerempLarv).format('YYYY-MM-DD');
            if (this.state.qteAnnonceLarv === null || this.state.qteAnnonceLarv === '') {
                qts = 1;
            }
            if (this.state.qteRecuLarv === null || this.state.qteRecuLarv === '') {
                qtc = 1;
            }
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            if (this.state.liste_ligne.length == 0) {
                this.state.liste_ligne.insert(this.state.num_ligne, { idLarv: 0, qteAnnonceLarv: qts, qteRecuLarv: qtc, datePerempLarv: date_peremption, numLotLarv: this.state.numLotLarv, obsLarv: this.state.obsLarv, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
            } else {
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                        trouve = true;
                        this.state.liste_ligne[i].qteAnnonceLarv = parseFloat(this.state.liste_ligne[i].qteAnnonceLarv) + parseFloat(qts);
                        this.state.liste_ligne[i].qteRecuLarv = parseFloat(this.state.liste_ligne[i].qteRecuLarv) + parseFloat(qts);
                    }
                }
                if (trouve == false) {
                    this.state.liste_ligne.insert(this.state.num_ligne, { idLarv: 0, qteAnnonceLarv: qts, qteRecuLarv: qtc, datePerempLarv: date_peremption, numLotLarv: this.state.numLotLarv, obsLarv: this.state.obsLarv, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
                }
            }
            this.forceUpdate();
        }
        this.setState({ disable_val_cmde: false });
    }


    actionTemplate(rowData, column) {
        let items = [];
        if (rowData.etatArv == 'En attente') {
            items = [
                {
                    label: 'Bon de sortie',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_bc(rowData);
                    }
                },
                {
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {
                        this.edit_arv(rowData);
                    }
                },
                {
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_arv(rowData);
                    }
                },
                {
                    label: 'Valider',
                    icon: 'pi pi-check',
                    command: (e) => {
                        rowData.etatArv = 'Validé';
                        this.update_etat_arv(rowData);
                    }
                },
                {
                    label: 'Historique',
                    icon: 'pi pi-search',
                    command: (e) => {
                        this.show_trace(rowData);
                    }
                }
            ]
        } else {
            items = [
                {
                    label: 'Bon de sortie',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_bc(rowData);
                    }
                },
                {
                    label: 'Historique',
                    icon: 'pi pi-search',
                    command: (e) => {
                        this.show_trace(rowData);
                    }
                }
            ]
        }
        return <div>
            <SplitButton onClick={(e) => this.edit_arv(rowData)} icon="pi pi-pencil" model={items}></SplitButton>
        </div>

    }

    actionTemplate_larv(rowData, column) {
        let disable_button = true;
        if (this.state.selectedArv.etatArv == 'En attente') {
            disable_button = false;
        }
        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.edit_larv(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.conf_delete_larv(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_ligne(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_arv() {
        if (this.state.selectedArv == null || this.state.selectedArv == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_arrivage(this.state.selectedArv).then(data => this.resultat(data.code, data.contenu));
        }
    }
    delete_larv() {
        if (this.state.selectedLarv == null || this.state.selectedLarv == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_larv(this.state.selectedLarv).then(data => this.resultat_larv(data.code, data.contenu));
        }
    }


    conf_delete_arv(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedArv: data });
            this.setState({ msg: data.numFactureArv });
            this.setState({ visible: true });
        }
    }

    conf_delete_larv(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLarv: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_larv: true });
        }
    }


    show_new_arv_dlg() {
        this.setState({ visible_new_arv_dlg: true });
        this.setState({ modif: false });
    }

    show_larv_dlg() {
        this.setState({ visible_dlg_larv: true });
        this.setState({ modif_larv: false });
        this.setState({ disable_prod: false });
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_arv();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_larv = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_larv: false });
                    this.delete_larv();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_larv: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_trace = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_trace: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let ldepot = null;
        let ldepot_rech = [{ value: 'Tous', label: 'Dépot' }];
        let lprod = null;

        if (this.state.liste_depot != null && this.state.liste_depot != undefined) {
            ldepot = this.state.liste_depot.map(x => {
                return { value: x.idDepot, label: x.nomDepot }
            });
            ldepot_rech = ldepot_rech.concat(ldepot);
        }

        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            lprod = this.state.liste_article.map(x => {
                return { value: x.idArticle, label: x.libArticle + ' [ ' + x.codeArticle + ' ]', stock: + x.qte_stock, code: x.codeArticle, ref: x.refArticle }
            });
        }

        const header_arv = <div style={{ textAlign: 'left', width: 280 }}>
            <div className="p-inputgroup">
                <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouveau transfert" style={{ width: 200 }} onClick={this.show_new_arv_dlg}></Button>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            </div>
        </div>;

        const header_larv = <div style={{ textAlign: 'left', width: 280 }}>
            <div className="p-inputgroup">
                <Button type="button" disabled={this.state.disable_add_prod} icon="pi pi-plus" iconPos="left" label="Ajouter un produit" style={{ width: 200 }} onClick={this.show_larv_dlg}></Button>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            </div>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_larv} style={{ width: '250px' }} modal={true} footer={dialogFooter_larv} onHide={(e) => this.setState({ visible_larv: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Historique" visible={this.state.visible_dlg_trace} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_trace: false })} footer={dialogFooter_trace}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400 }}>
                                <DataTable value={this.state.liste_trace} scrollable={true} scrollHeight="350px"
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column field="dateTrace" header="Date" style={{ width: '20%' }} />
                                    <Column field="actionTrace" header="Action" style={{ width: '15%' }} />
                                    <Column field="idUser.loginUser" header="Utilisateur" style={{ width: '15%' }} />
                                    <Column field="detailTrace" header="Détail" style={{ width: '50%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Nouveaux transfert" visible={this.state.visible_new_arv_dlg} style={{ width: '85%' }} modal={true} onHide={(e) => this.setState({ visible_new_arv_dlg: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 600 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label >Dépot source *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={ldepot} value={this.state.idDepotSrce} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                            this.setState({ idDepotSrce: e.value }, () => {
                                                this.get_liste_article(this.state.idDepotSrce.value);
                                            })
                                        }
                                        } autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label >Dépot destination *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={ldepot} value={this.state.idDepotDest} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                            this.setState({ idDepotDest: e.value })
                                        }
                                        } autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label >Date reception *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar value={this.state.dateReceptionArv} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateReceptionArv: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label >N° PV </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.numPvArv} onChange={(e) => this.setState({ numPvArv: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label >Observation </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.obsArv} onChange={(e) => this.setState({ obsArv: e.target.value })} />
                                    </div>

                                </div>

                                <Panel style={{ height: '415px' }}>
                                    <i class="fa fa-search"></i>
                                    <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Détails du transfert</span>

                                    <div className="p-grid" style={{ marginTop: 6 }}>

                                        <div className="p-col-12 p-md-3">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Produit * </label>
                                            </span>
                                            <Dropdown options={lprod} value={this.state.idArticle} panelStyle={{ width: '500px' }} optionLabel='label' filter={true} filterBy='label' itemTemplate={this.articleTemplate} filterMatchMode='contains' onChange={(e) => this.setState({ idArticle: e.value })} autoWidth={false} />
                                        </div>

                                        <div className="p-col-12 p-md-1">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Quantité * </label>
                                            </span>
                                            <InputText  keyfilter="pint" value={this.state.qteAnnonceLarv} onChange={(e) => this.setState({ qteAnnonceLarv: e.target.value })} />
                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">N° lot </label>
                                            </span>
                                            <InputText value={this.state.numLotLarv} onChange={(e) => this.setState({ numLotLarv: e.target.value })} />
                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Péremption * </label>
                                            </span>
                                            <Calendar value={this.state.datePerempLarv} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ datePerempLarv: e.value })} />
                                        </div>

                                        <div className="p-col-12 p-md-3">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Observation </label>
                                            </span>
                                            <InputText value={this.state.obsLarv} onChange={(e) => this.setState({ obsLarv: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-1">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">. </label>
                                            </span>
                                            <Button label="Ajouter" icon="pi pi-plus" onClick={(e) => this.add_article()} />
                                        </div>
                                    </div>

                                    <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="250px"
                                        onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                        responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }}
                                        editable={true}>
                                        <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                        <Column field="idArticle.libArticle" header="Produit" style={{ width: '40%' }} />
                                        <Column field="qteAnnonceLarv" header="Qauntité" style={{ width: '14%' }} editor={this.qteAnnonceLarvEditor} />
                                        <Column field="numLotLarv" header="N° lot" style={{ width: '14%' }} editor={this.numLotLarvEditor} />
                                        <Column field="datePerempLarv" header="Peremption" style={{ width: '10%' }} editor={this.datePerempLarvEditor} />
                                        <Column field="obsLarv" header="Observation" style={{ width: '17%' }} editor={this.obsLarvEditor} />
                                    </DataTable>
                                </Panel>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_arv_dlg()} /></div>
                                        <div className="p-col"><Button disabled={this.state.disable_val_cmde} label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_arv()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Modification transfert" visible={this.state.visible_dlg_arv} style={{ width: '500px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_arv: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label >Date reception *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Calendar value={this.state.dateReceptionArv} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateReceptionArv: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label >Dépot source *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={ldepot} disabled={true} value={this.state.idDepotSrce} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idDepotSrce: e.value })} autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Dépot destination *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={ldepot} value={this.state.idDepotDest} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idDepotDest: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label >N° PV *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.numPvArv} onChange={(e) => this.setState({ numPvArv: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label >Observation </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.obsArv} onChange={(e) => this.setState({ obsArv: e.target.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_arv()} /></div>
                                        <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_arv()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <Dialog header="Modification détail transfert" visible={this.state.visible_dlg_larv} style={{ width: '550px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_larv: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Produit *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown disabled={this.state.disable_prod} options={lprod} value={this.state.idArticle} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idArticle: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Qauntité *</label>
                                    </div>

                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pint" value={this.state.qteAnnonceLarv} onChange={(e) => this.setState({ qteAnnonceLarv: e.target.value, qteRecuLarv: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Observation </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.obsLarv} onChange={(e) => this.setState({ obsLarv: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Numéro Lot </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.numLotLarv} onChange={(e) => this.setState({ numLotLarv: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Date péremption </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Calendar value={this.state.datePerempLarv} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ datePerempLarv: e.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_larv: false }) }} /></div>
                                        <div className="p-col"><Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_larv()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-1">
                                            <InputText value={this.state.rnum_arv} placeholder="N° PV" onChange={(e) => this.setState({ rnum_arv: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={ldepot_rech} placeholder="Dépot source" value={this.state.ridDepotSrce} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridDepotSrce: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={ldepot_rech} placeholder="Dépot destination" value={this.state.ridDepotDest} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridDepotDest: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_etat} placeholder="Etat" value={this.state.retatArv} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ retatArv: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-1">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' style={{width: 120}} label='Rechercher' onClick={(e) => this.get_liste_arv()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <ScrollPanel style={{ width: '100%', height: '590px' }}>
                                                    <DataTable value={this.state.liste_arv} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                                        onRowClick={e => this.setState({ selectedArv: e.data })} onRowSelect={e =>
                                                            this.setState({ selectedArv: e.data }, () => {
                                                                this.get_liste_larv(this.state.selectedArv.idArv);
                                                                this.get_liste_article(this.state.selectedArv.idDepotDest.idDepot);
                                                                if (this.state.selectedArv.etatArv == 'En attente') {
                                                                    this.setState({ disable_add_prod: false });
                                                                } else {
                                                                    this.setState({ disable_add_prod: true });
                                                                }
                                                            })
                                                        }
                                                        header={header_arv} style={{ width: '180%' }}
                                                        responsive={true} selection={this.state.selectedArv} >
                                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%', overflow: 'visible' }} />
                                                        <Column field="dateArv" header="Date" style={{ width: '10%' }} />
                                                        <Column field="etatArv" header="Statut" style={{ width: '10%' }} />
                                                        <Column field="dateReceptionArv" header="Date recep." style={{ width: '12%' }} />
                                                        <Column field="idDepotSrce.nomDepot" header="Dépot source" style={{ width: '15%' }} />
                                                        <Column field="idDepotDest.nomDepot" header="Dépot destination" style={{ width: '15%' }} />
                                                        <Column field="numPvArv" header="N° PV" style={{ width: '10%' }} />
                                                        <Column field="obsArv" header="Observation" style={{ width: '20%' }} />
                                                    </DataTable>
                                                </ScrollPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <DataTable value={this.state.liste_larv} selectionMode="single" scrollable={true} scrollHeight="460px"
                                                    onRowClick={e => this.setState({ selectedLarv: e.data })} onRowSelect={e => this.setState({ selectedLarv: e.data })}
                                                    responsive={true} selection={this.state.selectedLarv} header={header_larv} >
                                                    <Column header="#" body={this.actionTemplate_larv.bind(this)} style={{ width: '15%' }} />
                                                    <Column field="idArticle.libArticle" header="Produit" style={{ width: '44%' }} />
                                                    <Column field="qteAnnonceLarv" header="Quantité" style={{ width: '13%' }} />
                                                    <Column field="numLotLarv" header="N° lot" style={{ width: '14%' }} />
                                                    <Column field="datePerempLarv" header="Peremption" style={{ width: '14%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}