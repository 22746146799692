import React, { Component } from 'react';
import { Dhosservice } from '../service/Dhosservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Menu } from 'primereact/menu';


export class Form_edition_cmde extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rnum_cmde: '',
            statutCmde: null,
            etatCmde: null,
            idSite: null,
            liste_cmde: [],
            liste_site: [],

            liste_region: [
                { label: 'Region', value: 'Tous' },
                { label: 'Boke', value: 'Boké' },
                { label: 'Conakry', value: 'Conakry' },
                { label: 'Faranah', value: 'Faranah' },
                { label: 'Kankan', value: 'Kankan' },
                { label: 'Kindia', value: 'Kindia' },
                { label: 'Labe', value: 'Labé' },
                { label: 'Mamou', value: 'Mamou' },
                { label: 'Nzérkoré', value: 'Nzérkoré' }
            ],
            liste_all_site: [],
            liste_prefecture: [],
            liste_crd: [],
            region: { label: 'Region', value: 'Tous' },
            pref: null,
            crd: null,

            liste_trace: [],
            msg: '',
            liste_statut: [
                { label: 'Tout statut', value: 'Tous' },
                { label: 'Livré', value: 'Livré' },
                { label: 'Non livré', value: 'Non livré' }
            ],
            liste_etat: [
                { label: 'Tout etat', value: 'Tous' },
                { label: 'Validé', value: 'Validé' },
                { label: 'En attente', value: 'En attente' },
                { label: 'Annulé', value: 'Annulé' }
            ],
            items: [
                { label: 'Format PDF', icon: 'pi pi-fw pi-print', command: () => { this.print_data('PDF') } },
                { label: 'Format XLS', icon: 'pi pi-fw pi-print', command: () => { this.print_data('XLS') } },
                { label: 'Format CSV', icon: 'pi pi-fw pi-print', command: () => { this.dt.exportCSV() } }
            ],
            liste_all_pref: JSON.parse(localStorage.getItem('liste_pref')),
            liste_all_crd: JSON.parse(localStorage.getItem('liste_crd')),
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.conf_print = this.conf_print.bind(this);
        this.print_data = this.print_data.bind(this);
        this.myservice = new Dhosservice();
        this.state.pd1.setDate(this.state.pd1.getDate() - 120);
    }




    componentDidMount() {
        this.get_liste_cmde();
        this.get_liste_site();
    }

    get_liste_site() {
        this.myservice.get_liste_sites().then(data => {
            this.setState({ liste_all_site: data }, () => {
                if (this.state.liste_all_site != null && this.state.liste_all_site != undefined) {
                    if (this.state.liste_all_site.length > 0) {
                        let lsite_rech = [{ value: 'Tous', label: 'Sites' }];
                        let lsite = this.state.liste_all_site.map(x => {
                            return { value: x.idSite, label: x.nomSite + '/' + x.regionSite }
                        });
                        lsite_rech = lsite_rech.concat(lsite);
                        this.setState({ liste_site: lsite_rech });
                    }
                }
            });
        });
    }

    print_data(format) {
        let rstatut_cmde = 'Tous';
        let retat_cmde = 'Tous';
        
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');

        let rsite = 'Tous';
        let rregion = 'Tous';
        let rpref = 'Tous';
        let rcrd = 'Tous';

        if (this.state.region != undefined || this.state.region != null) {
            rregion = this.state.region.value;
            if (rregion == undefined) {
                rregion = this.state.region;
            }
        }
        if (this.state.pref != undefined || this.state.pref != null) {
            rpref = this.state.pref;
        }
        if (this.state.crd != undefined || this.state.crd != null) {
            rcrd = this.state.crd;
        }

        if (this.state.idSite != undefined || this.state.idSite != null) {
            rsite = this.state.idSite.value;
        }
        if (this.state.etatCmde != undefined || this.state.etatCmde != null) {
            retat_cmde = this.state.etatCmde.value;
        }
        if (this.state.statutCmde != undefined || this.state.statutCmde != null) {
            rstatut_cmde = this.state.statutCmde.value;
        }
        let rnumcmde = this.state.rnum_cmde;
        if (rnumcmde == '') {
            rnumcmde = 'Tous';
        }
        this.myservice.edition_liste_cmde(spd1, spd2, this.state.rnum_cmde,rregion,rpref,rcrd, rsite, retat_cmde, rstatut_cmde,format);

    }
    get_liste_cmde() {
        let rstatut_cmde = 'Tous';
        let retat_cmde = 'Tous';
        let rsite = 'Tous';
        let spd1 = moment(this.state.pd1).format('YYYY-MM-DD');
        let spd2 = moment(this.state.pd2).format('YYYY-MM-DD');

        let rregion = 'Tous';
        let rpref = 'Tous';
        let rcrd = 'Tous';

        if (this.state.region != undefined || this.state.region != null) {
            rregion = this.state.region.value;
            if (rregion == undefined) {
                rregion = this.state.region;
            }
        }
        if (this.state.pref != undefined || this.state.pref != null) {
            rpref = this.state.pref;
        }
        if (this.state.crd != undefined || this.state.crd != null) {
            rcrd = this.state.crd;
        }

        if (this.state.idSite != undefined || this.state.idSite != null) {
            rsite = this.state.idSite.value;
        }
        if (this.state.etatCmde != undefined || this.state.etatCmde != null) {
            retat_cmde = this.state.etatCmde.value;
        }
        if (this.state.statutCmde != undefined || this.state.statutCmde != null) {
            rstatut_cmde = this.state.statutCmde.value;
        }
        this.myservice.get_edition_liste_cmde(spd1, spd2, this.state.rnum_cmde,rregion,rpref,rcrd, rsite, retat_cmde, rstatut_cmde).then(data => this.setState({ liste_cmde: data }));
    }


    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        let liste_rech = [{ value: 'Tous', label: 'Préfecture' }];
        liste_rech = liste_rech.concat(liste);
        this.setState({ liste_prefecture: liste_rech });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        let liste_rech = [{ value: 'Tous', label: 'CR' }];
        liste_rech = liste_rech.concat(liste);
        this.setState({ liste_crd: liste_rech });
    }

    select_site(pref, crd) {
        let liste = [];
        if (this.state.liste_all_site != null && this.state.liste_all_site != undefined) {
            if (this.state.liste_all_site.length > 0) {

                let liste_filtre = this.state.liste_all_site.filter(function (el) {
                    return el.prefectureSite == pref && el.crdSite == crd;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.idSite, label: x.nomSite + '/' + x.regionSite }
                        });
                    }
                }
            }
        }
        let lsite_rech = [{ value: 'Tous', label: 'Sites' }];
        lsite_rech = lsite_rech.concat(liste);
        this.setState({ liste_site: lsite_rech });
    }



    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    conf_print(data) {
        this.myservice.generer_edition_bc(data.id_cmde);
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_print(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-print" /></div>
        </div>
    }


    render() {

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="card card-w-title" style={{ height: 600 }}>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown placeholder="Region" autoWidth={false} value={this.state.region} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_region} onChange={(e) => {
                                        this.setState({ region: e.value }, () => {
                                            this.select_pref(e.value);
                                            this.setState({ liste_crd: [] });
                                            this.setState({ liste_site: [] });
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown placeholder="Préfecture" autoWidth={false} value={this.state.pref} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_prefecture} onChange={(e) => {
                                        this.setState({ pref: e.value }, () => {
                                            this.select_crd(e.value);
                                            this.setState({ liste_site: [] });
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown placeholder="CR" autoWidth={false} value={this.state.crd} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_crd} onChange={(e) => {
                                        this.setState({ crd: e.value }, () => {
                                            this.select_site(this.state.pref, e.value);
                                            this.get_liste_cmde();
                                        });
                                    }
                                    } />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown options={this.state.liste_site} placeholder="Site" value={this.state.idSite} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idSite: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown options={this.state.liste_statut} placeholder="Statut" value={this.state.statutCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ statutCmde: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown options={this.state.liste_etat} placeholder="Etat" value={this.state.etatCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ etatCmde: e.value })} autoWidth={false} />
                                </div>
                            </div>
                        </div>

                        <center>
                            <div className="p-grid" style={{ width: 300 }}>
                                <div className="p-col"><Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_cmde()} /></div>
                                <div className="p-col">
                                    <Menu model={this.state.items} popup={true} ref={el => this.menu = el} />
                                    <Button label="Edition.." icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} />
                                </div>
                            </div>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
  
                        <DataTable value={this.state.liste_cmde} selectionMode="single" paginatorPosition="top" paginator={true} rows={100}
                            responsive={true} selection={this.state.selectedCmde} csvSeparator=";" ref={(el) => { this.dt = el; }} >
                            <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '4%' }} />
                            <Column field="ordre" header="#" style={{ width: '4%' }} />
                            <Column field="id_cmde" header="Num BC" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="date_cmde" header="Date" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="etat_cmde" header="Etat" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="statut_cmde" header="Statut" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="date_livr_cmde" header="Date livr." style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nom_site" header="Site" style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="prefecture_site" header="Préfecture" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="region_site" header="Région" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        </DataTable>

                    </div>
                </div>
            </div>

        );
    }
}